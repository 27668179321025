<template>
  <div class="base-table">
    <div class="flex align-center justify-between">
      <div>
        <slot name="table-top-btn"></slot>
        <el-button v-if="btnConfig.add.show && $filter.perValidate(btnConfig.add.permission)" round icon="Plus" size="small" type="primary" @click="handleAdd()">{{btnConfig.add.label}}</el-button>
        <el-button v-if="btnConfig.deleteAll.show && $filter.perValidate(btnConfig.deleteAll.permission)" round icon="Delete" size="small" type="danger" @click="handleEdit()">{{btnConfig.deleteAll.label}}</el-button>
        <el-button v-if="btnConfig.export.show && $filter.perValidate(btnConfig.export.permission)" round size="small" type="warning" @click="handleEdit()">
          <svg-icon class="mr-4" icon-class="exportExcel"></svg-icon>{{btnConfig.export.label}}
        </el-button>
      </div>
      <div class="flex align-center"></div>
    </div>
    <el-table :data="tableData" class="mt-20" highlight-current-row :header-cell-style="{backgroundColor: '#999999',color:'#FFFFFF'}">
      <el-table-column type="selection" width="55" />
      <template v-for="(item,index) in columns" :key="index">
        <el-table-column  :prop="item.prop" :label="item.label" :width="item.width">
          <template #default="scope" >
            <span class="text-label" >{{getValue(scope.row,item)}}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" v-if="!btnConfig.hidden"  :width="btnConfig.width ? btnConfig.width : 300" fixed="right">
        <template #default="scope">
          <slot name="table-inner-btn" :data="scope"></slot>
          <el-button v-if="btnConfig.info.show && $filter.perValidate(btnConfig.info.permission)" icon="View" size="small" round type="info" @click="handleInfo(scope.$index, scope.row)">{{btnConfig.info.label}}</el-button>
          <el-button v-if="btnConfig.update.show && $filter.perValidate(btnConfig.update.permission)" icon="Edit" size="small" round type="primary" @click="handleEdit(scope.$index, scope.row)">{{btnConfig.update.label}}</el-button>
          <el-button v-if="btnConfig.delete.show && $filter.perValidate(btnConfig.delete.permission)" icon="Delete" size="small" round type="danger" @click="handleDelete(scope.$index, scope.row)">{{btnConfig.delete.label}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="page" size="small"
                   :currentPage="currentPage"
                   :page-size="pageSize"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total"
                   @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
let moment = require('moment');
moment.locale('zh-cn')
export default {
  props:{
    api:{
      default: '',
      type: String,
    },
    btnConfig: {
      default: {
        add:{
          show: true,
          label: '新增',
          permission: ''
        },
        deleteAll:{
          show: true,
          label: '批量删除',
          permission: ''
        },
        export:{
          show: true,
          label: '导出',
          permission: ''
        },
        info:{
          show: true,
          label: '详情',
          permission: ''
        },
        update:{
          show: true,
          label: '修改',
          permission: ''
        },
        delete:{
          show: true,
          label: '删除',
          permission: ''
        }
      },
      type: Object,
    },
    columns:{
      default: [],
      type: Array,
    },
  },
  data(){
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      searchData: {},
    }
  },
  created() {
    this.setData()
    this.getTableData()
  },
  methods:{
    getValue(row,item){
      if (!row[item.prop]) {
        return ""
      }
      if(item.dict) {
        return this.$filter.getDictLabel(item.dict,row[item.prop])
      } else if(item.apis){
        return this.getLabels(row[item.prop],item)
      } else if(item.api){
        return this.getLabel(row[item.prop],item)
      } else if(item.format){
        return this.getTimeText(row[item.prop],item)
      } else if(item.unit){
        return row[item.prop] + this.$filter.getDictLabel('count_unit',row[item.unit])
      } else if(item.suf){
        return row[item.prop] + item.suf
      } else if(item.prf){
        return item.prf + row[item.prop]
      } else {
        return row[item.prop]
      }
    },
    getTimeText(value,item){
      return moment(value).format(item.format)
    },
    getLabel(value,item){
      let arr = item.selectData ? item.selectData.filter(obj => obj[item.props.value] === value) : []
      if(arr.length > 0) {
        return  arr[0][item.props.label]
      } else {
        return value
      }
    },
    getLabels(value,item){
      if(value) {
        let arr = value.split(',')
        let labelArr = []
        arr.forEach(obj=>{
          labelArr.push(this.getLabel(obj,item))
        })
        return labelArr.join(',')
      } else {
        return ''
      }
    },
    setData(){
      this.columns.forEach(item=>{
        if (item.api) {
          this.$axios.post(item.api).then(res=>{
            if (item.pObj) {
              item.selectData = res.data.unshift(item.pObj)
            } else {
              item.selectData = res.data
            }
          })
        }
        if (item.apis) {
          this.$axios.post(item.apis).then(res=>{
            if (item.pObj) {
              item.selectData = res.data.unshift(item.pObj)
            } else {
              item.selectData = res.data
            }
          })
        }
      })
    },
    getTableData(searchData){
      let param = {
        pageSize: this.pageSize,
        pageCurrent: this.currentPage
      }
      if (searchData) {
        this.searchData = Object.assign({},searchData)
      }
      Object.assign(param,this.searchData)
      this.$axios.post(this.api + '/page',param).then(res=>{
        if (res.code === 200) {
          this.tableData = res.data.records
          this.total = parseInt(res.data.total)
        }
      })
    },
    clear(){
      this.searchData = {}
      this.handleCurrentChange(1)
    },
    handleAdd(){
      this.$emit('add')
    },
    handleEdit(index,row){
      this.$emit('update',row)
    },
    handleInfo(index,row){
      this.$emit('showInfo',row)
    },
    handleDelete(index,row){
      this.$emit('handleDelete',row)
    },
    handleSizeChange(size){
      this.pageSize = size
      this.currentPage = 1
      this.getTableData()
    },
    handleCurrentChange(page){
      this.currentPage = page
      this.getTableData()
    },
  }
}
</script>

<style lang="less" scoped>
.page {
  margin-top: 10px;
  display: flex;
  justify-content: right;
}
.base-table {

}
.text-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
