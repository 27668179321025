<template>
  <div>
    <svg width="512" height="747" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="linear-hover" x1="100%" y2="100%">
          <stop offset="0%" style="stop-color: #7285FD"></stop>
          <stop offset="100%" style="stop-color: #0E30C7"></stop>
        </linearGradient>
        <linearGradient id="linear" x1="100%" y2="100%">
          <stop offset="0%" style="stop-color: #7285FD"></stop>
          <stop offset="100%" style="stop-color: #90B2FC"></stop>
        </linearGradient>
      </defs>
      <g id="Layer_1">
        <g class="vks">
          <path d="m189.645,366.85972c-0.77,-3.87 -5.16,-4.77 -8.9,-3.87c-2.32,-2.84 -11.48,-0.52 -13.93,-1.42s-9.29,-6.71 -9.68,-8.26s-0.39,-3 -3.1,-2.32c-4.26,1 -6.58,0.26 -6.45,-2.84s-1.68,-4.77 -4,-2.84c-1.68,-1.42 -6.71,0.65 -9.81,-4.26c-1.48,-2.31 -5.07,-4.37 -8.48,-5.94a6.73,6.73 0 0 1 -2.37,0.78c-4.63,0.79 -11.63,-0.38 -17.79,-2.32c0,0 -2.45,-3.74 -11.23,-2.71c-6.44,0.76 -13.82,1.34 -17.71,0.44c-6.25,3.92 -14.82,9.05 -20.48,11.43c-9.81,4.13 -19.1,4.13 -20.65,17a95.41,95.41 0 0 0 -0.77,9.87c0.57,0 2.82,0 11.73,0.09c8.08,-3.43 20.79,-0.34 20.79,-0.34s20.49,0.17 22.79,0.17c2.27,0.82 6.63,1.09 11.75,2.15a61.39,61.39 0 0 1 15.15,5.25c4.86,2.47 6.94,7.84 7.62,8.74s1.72,0.55 5.2,3.46c2.28,1.89 10.32,4.45 15.64,6a1,1 0 0 1 1,-0.48c2.13,0 12.67,0.29 15.19,-1.25c3.19,0.48 11.23,-0.87 13.65,0.29a18.36,18.36 0 0 0 7.16,1.64c1.26,-0.1 3.09,-3.58 4.26,-4.16c0.65,-0.33 1.77,-2 2.62,-3.36l0,0a1.72,1.72 0 0 1 -0.37,-0.16c-1.29,-0.77 -0.25,-4.51 0.91,-4.77s2.32,-0.77 2.32,-5.68c-2.83,-4.65 -0.25,-7.75 -2.06,-10.33z" class="cls-1"/>
        </g>
        <g class="vks">
          <path d="m147.18169,410.86505c1.55,-3.09 -0.68,-10.45 -1.36,-12c-0.5,-1.14 -1.41,-2.85 -1,-3.68l0,0c-5.31,-1.54 -13.36,-4.1 -15.63,-6c-3.49,-2.91 -4.53,-2.57 -5.2,-3.46s-2.76,-6.27 -7.63,-8.74a61.32,61.32 0 0 0 -15.11,-5.24c-5.13,-1.06 -9.48,-1.33 -11.76,-2.15c-2.3,0 -22.79,-0.17 -22.79,-0.17s-12.7,-3.1 -20.78,0.34l-11.74,-0.09a28,28 0 0 0 4.39,16.47c1.8,3 2.44,9.25 2.54,15.79c0.52,-0.82 1,-1.26 1.33,-1.09c1.55,0.78 2.07,-1.29 15,6.46s14.45,3.35 18.58,4.38c5.42,3.1 8.26,1.81 11.87,1.29s7,3.36 8.52,4.91s5.93,-1.55 8.64,0.51s-0.64,1.81 5.29,3.36s7.49,1.55 10.58,8.51s11.59,9.88 13.14,9.57s2.83,-3.61 4.9,-3.87a4.48,4.48 0 0 0 0.92,0.52a3.23,3.23 0 0 1 -0.52,-0.27a98.64,98.64 0 0 0 4.28,-11.31a4.76,4.76 0 0 1 2.54,-3.29a3.8,3.8 0 0 0 1.36,-4.65c-0.9,-1.64 -1.87,-3 -0.36,-6.1z" class="cls-1"/>
        </g>
        <g class="vks">
          <path d="m237.00999,405.69017c-1.55,-2.45 -6.45,-4.13 -8.38,-5.93s-4.13,-5.81 -8.67,-8.52s-5.16,-7.35 -7.1,-7.74a14.79,14.79 0 0 0 -2.58,-0.17a26.22,26.22 0 0 1 -1.84,-4l0,0a27.87,27.87 0 0 0 1.87,4c-2.92,9.15 -19.11,5.61 -22,4.55c-0.85,1.37 -2,3 -2.62,3.35c-1.16,0.58 -3,4.07 -4.26,4.16a18.4,18.4 0 0 1 -7.16,-1.64c-2.42,-1.16 -10.45,0.19 -13.64,-0.29c-2.52,1.55 -13.07,1.26 -15.2,1.26s-0.67,2.61 0,4.16s2.91,8.9 1.36,12s-0.58,4.45 0.29,6.09a3.8,3.8 0 0 1 -1.36,4.65a4.81,4.81 0 0 0 -2.51,3.29a99.39,99.39 0 0 1 -4.25,11.36c2.77,1.64 6.18,0 8.89,0s0.78,5.68 3.87,8.26s3.87,-1 4.65,2.58s-2.58,3.87 -0.52,5.68c3.87,5.42 2.11,10.84 1.55,14.71s3.1,1.8 3.61,5.68c-1,4.12 0.52,6.45 3.62,10.32c2.93,3.66 1.92,6.86 0.28,6.31a13.61,13.61 0 0 0 1.72,3.52c0.9,0.77 2.19,0.58 3.54,2.13a6.69,6.69 0 0 0 3.42,2.39c0.58,0 4.2,-3.62 11.81,-0.59s13.81,4.65 15.94,2.26c3.22,-0.26 4,-3.93 3.93,-5.93s-1.16,-9.55 -0.71,-10.39s0.58,-1.74 2.13,-2.19a5.21,5.21 0 0 0 2,-1.36l0,0a3.18,3.18 0 0 1 0.79,-1.76c0.68,-0.38 5.57,-3.67 6,-4.88s3.87,-2.08 3.82,-2.37a2.44,2.44 0 0 1 1.94,-2.76c-0.63,-1.6 1.3,-3.78 3.24,-4.65s1.74,-3.48 2.41,-4.5s1,-4.69 0.69,-5.27s-1.12,-2 -0.39,-2.81c1.5,-0.32 1.65,-1.75 1.77,-2.32a50.6,50.6 0 0 0 0.1,-6.82c1.35,-3.1 -1,-10.45 -1.16,-12.78s-0.2,-5.61 2.12,-6s3.94,-4.07 5.44,-4.65a36.44,36.44 0 0 0 6.58,-4.26a45.88,45.88 0 0 1 5,-2.92l0,0c-4.76,-0.51 -8.58,-2.92 -10.03,-5.21z" class="cls-1"/>
        </g>
        <g class="vks">
          <path d="m209.0466,324.26896l-0.7,0c0,-1.16 2.71,-12.78 4.45,-14.33s3.87,-7 7.74,-12.77c2.36,-3.53 7.65,-10.51 11.44,-15.43a38,38 0 0 0 -4.6,-5.15c-3.83,-3.61 -4.12,-1.7 -4.13,-1.16c-0.44,0.34 -1.61,1.31 -4.38,3.74c-7.23,8.26 -9,0.26 -12.39,-1.55s-8.43,-3.63 -8.43,-4.66s5.68,-3.62 5.68,-12.91c-1.55,-9.54 -12.39,-6.19 -15.74,-7.22s-6.2,-3.87 -8.26,-7.23s1,-6.45 4.64,-8s-1.85,-4.38 -3.66,-2.58a11.36,11.36 0 0 1 -7.22,3.1c-2.33,0 -7.75,1 -5.17,5.93s-0.25,3.87 -2.06,7.23s-4.9,5.68 -9.81,5.94s-1,-2.84 2.58,-5.42s0.78,-6.2 -2.58,-5.94s-7.74,5.16 -11.09,5.94a12.84,12.84 0 0 0 -9.31,9.54c-3.36,2.33 0,8.26 2.84,7.49s3.35,1 2.58,3.61s-8.26,7.48 -11.62,6.19s-4.62,-7.19 -3.29,-11.35s-2.11,-5.42 -3.14,-2.06s1.29,1.8 -3.37,9.54s-4.78,7.49 -2.84,19.1s7,20.13 8.51,29.42c1.23,7.35 1.23,10.58 -1.12,12c3.41,1.55 7,3.63 8.48,6c3.1,4.9 8.13,2.83 9.81,4.25c2.32,-1.93 4.12,-0.25 4,2.84s2.19,3.87 6.45,2.84c2.71,-0.64 2.71,0.77 3.09,2.32s7.23,7.36 9.68,8.26s11.61,-1.42 13.94,1.42c3.74,-0.9 8.13,0 8.9,3.87c1.81,2.58 -0.77,5.68 2.06,10.32c0,4.91 -1.16,5.42 -2.32,5.68s-2.19,4 -0.9,4.78s19.26,5.23 22.34,-4.42c-4.26,-7.49 -3.48,-14.45 -4.9,-19.87s-2.71,-22.07 0.13,-27c1.58,-2.74 2.89,-8.13 3.69,-12.3z" class="cls-1"/>
        </g>
        <g class="vks">
          <path d="m312.455,333.86356c0,-2.33 0.77,-7.74 -4.65,-10.45c-4,-2.81 -8.9,-9.68 -9.39,-10.79s0.15,-8.42 0.54,-9.63s-1.94,-2 -3.78,-3.39s2,-5.23 2.32,-8.27a11.05,11.05 0 0 1 3.15,0c0.63,0 5.18,-3 5.42,-4c0.12,-0.5 0.25,-3.12 0.34,-5.55c-5.36,-2.88 -5.39,-8.55 -6.92,-9.19a4.31,4.31 0 0 1 -2.2,-3.87c0.26,-0.78 2.71,-0.65 3.75,0.13s3.74,1.8 4.38,0.51s1.81,-4.39 -2.58,-6.06c7,-7.23 -1.42,-10.84 0,-12.91s6.45,-3.09 8,-3.74s7.1,-3.1 7.23,-8.39a20.85,20.85 0 0 1 -0.38,-9.62c0.12,-0.14 0.26,-0.29 0.38,-0.44c-10.58,13.14 -50.07,32.24 -54.45,32.98s-14.45,7 -16,7.49s-5.42,4.13 -8.52,9.8s-4.64,5.42 -5.93,4.91a4.34,4.34 0 0 1 -1.6,-1.56c-3.79,4.92 -9.08,11.89 -11.44,15.43c-3.87,5.8 -6,11.22 -7.74,12.77s-4.45,13.16 -4.45,14.35c3.48,0 10.26,1.94 14.9,1.75s8.91,1.16 10.45,6.58s8.91,-3.1 8.91,-3.1s6.39,-3.29 7.55,1.55c2.32,3.35 11.61,4.06 15.09,4.26s4.65,0 4.65,1s-2.52,6 2.51,4.65s12.59,5.42 12.39,7.16s1.74,1.16 1.74,1.16s6.39,-7.55 7.75,-7.74s3.06,-1.78 4.06,1.12s3.68,8.51 8.13,6.67s6.39,-13.25 6.39,-15.57z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m285.10548,348.625l0,0c-0.53,0 -1.15,-0.22 -1,-1.25c0.2,-1.74 -7.35,-8.51 -12.38,-7.16s-2.56,-3.59 -2.56,-4.59s-1.16,-0.78 -4.64,-1s-12.78,-0.91 -15.1,-4.26c-1.16,-4.83 -7.53,-1.54 -7.53,-1.54s-7.35,8.52 -8.9,3.1s-5.83,-6.77 -10.47,-6.58c-4.36,0.18 -10.57,-1.51 -14.21,-1.72c-0.84,4.2 -2.14,9.56 -3.73,12.3c-2.84,4.9 -1.55,21.55 -0.13,27s0.65,12.33 4.88,19.8a14.79,14.79 0 0 1 2.6,0.17c1.94,0.38 2.59,5 7.1,7.74s6.71,6.71 8.65,8.52s6.84,3.48 8.38,5.93s5.81,5 11,5.29c4,-2.32 11.1,-7.1 12.78,-7.74s4.25,-0.65 6.19,-0.65a12.08,12.08 0 0 0 3.35,2.33a16.31,16.31 0 0 0 4.39,0a5.21,5.21 0 0 0 -1.93,-5.16c0,-2.61 -2.46,-4.13 -4.26,-4.78s-2.45,-3.74 -3,-5.29s3,-5.42 1.68,-11.1c0,0 6.84,-11.35 6.71,-12.77c1.67,-2.19 4,-3.35 4.64,-4.39s-0.39,-1.42 0,-3.22s5.42,-3.56 7.49,-8.98z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m350.32707,304.98544c-0.27,-3.36 0.38,-6.78 1.65,-8.34c2.32,-2.84 2.84,-9.29 3.87,-16.77s10.58,-4.91 13.94,-5.42s8.77,-8.78 7.48,-16s1,-10.33 2.32,-13.16c0.52,-1.14 1,-2.91 1.56,-4.39l0,0c-4.7,-2.39 -8.73,-5.07 -7.49,-11.87c1.55,-8.52 -1.29,-14.71 -9.29,-16.52c0.26,-8.51 -3.1,-9 -3.1,-16.51s-3.06,-9.81 -3.61,-19.1c1.8,-8 -1,-16.52 -5.16,-24.52s-11.36,-4.64 -13.66,-13.93c0.75,-8.82 -6.47,-9.55 -7,-16.82a10.25,10.25 0 0 0 -3.92,-7c-3.82,0.89 -4.73,4.27 -8.72,6.79c-4.91,3.09 -3.1,9.54 -3.62,12.38s-7.74,10.07 -10.05,11.62s-9,6.7 -7.23,14.19c-3.09,6.45 -0.26,7.48 -0.26,7.48a26.45,26.45 0 0 1 8,5.16c3.36,3.36 7.23,6.2 11.62,9.81s8.77,2.32 13.16,9s0.26,11.1 2.32,17.29s-0.77,9.81 -4.13,11.87s-11.35,8 -11.35,8l-0.38,0.45a20.85,20.85 0 0 0 0.38,9.62c-0.13,5.29 -5.68,7.74 -7.23,8.39s-6.58,1.67 -8,3.74s7,5.67 0,12.9c4.39,1.68 3.23,4.77 2.58,6.07s-3.35,0.25 -4.38,-0.52s-3.49,-0.9 -3.75,-0.13a4.3,4.3 0 0 0 2.2,3.89c1.55,0.65 1.55,6.45 7.09,9.29s9.29,6.07 8,12.9c-0.25,5.42 1.68,6.46 1.17,10.33c3.22,2.84 9.93,2.32 13.54,1.8s4.78,-4 9.55,-2.45c3.08,1.01 8.23,0.77 11.9,0.48z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m477.29273,188.6088c0,-6.2 -2.06,-5.42 -4.64,-5.94s-4.65,-1.55 -8,2.84c-3.36,10.32 -6.45,8.26 -9,9s-3.62,4.9 -5.68,9.55s-2.58,9.81 -5.42,10.06s-3.1,-3.87 -4.65,1s-3.61,3.87 -5.42,1s-1.54,-2.58 -4.12,-6.71c-5.17,-4.13 -3.62,-18.32 -13.68,-24.26s-9,-12.13 -6.2,-17a10.27,10.27 0 0 0 -1.57,-12.54c-2.84,-2.84 -0.52,-6.45 -3.36,-11.35s-6.47,-17.81 -8,-20.65s-10.84,-2.58 -9.55,-9.54s-3.61,-16.26 -6.92,-22.71s-8.3,-23.23 -8.56,-24.75s4.64,-11.13 2.56,-12.16s-1,1.55 -11.85,-3.09s-13.68,0.77 -18.84,7s-2.58,16 -4.4,17s-4.91,9.03 -3.08,10.32s4.17,6.19 2.58,13.68s-1.55,15.25 -8.26,14.71a10,10 0 0 0 -3.15,0.18a10.25,10.25 0 0 1 3.92,7c0.52,7.23 7.74,8 7,16.78c2.32,9.29 9.55,5.93 13.68,13.93s7,16.52 5.16,24.52c0.55,9.29 3.61,11.61 3.61,19.09s3.36,8 3.1,16.52c8,1.81 10.84,8 9.29,16.52c-1.24,6.8 2.79,9.48 7.49,11.86c0.9,-2.21 2.17,-3.81 4.64,-1.8c5.42,4.9 7.74,4.39 10.84,4.39s9.29,6.71 16,14.71s7.74,1.8 7.48,3.35c6.45,3.36 2.84,1.81 10.58,8s7.23,3.61 11.61,2.58s3.88,-2.32 6.71,-0.51c8.26,6.71 4.65,0.77 4.65,0.77s1,1.55 0.77,-3.35c2.33,-5.17 4.65,-7.23 1,-11.88s-2.83,-10.02 -2.83,-13.12s9.55,-8.51 11.87,-9s6.71,-5.68 8,-11.35s-1.55,-8.26 0.77,-10.84s7,-9.81 6.45,-10.58s-2.58,-7 -2.58,-13.23z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m338.185,334.34066c3.4,-1.48 5.87,-6.32 6.71,-7.23s10.54,-7.11 15.34,-9.64c-0.62,-2.59 -4.24,-3.52 -6.63,-5.9c-1.63,-1.64 -2.47,-4.37 -2.69,-7.15c-3.66,0.3 -8.82,0.53 -11.89,-0.47c-4.78,-1.55 -5.94,1.94 -9.55,2.45s-10.32,1 -13.55,-1.8c0.52,-3.87 -1.42,-4.91 -1.16,-10.33c1.29,-6.83 -2.45,-10.06 -8,-12.9l-0.18,-0.1l0,0c-0.1,2.43 -0.22,5.06 -0.35,5.55c-0.24,1 -4.79,4 -5.41,4a12,12 0 0 0 -3.15,0c-0.34,3 -4.16,6.87 -2.32,8.27s4.16,2.18 3.77,3.39s-1,8.52 -0.53,9.63s5.37,8 9.39,10.79c5.42,2.71 4.64,8.13 4.64,10.45s-1.93,13.74 -6.39,15.58s-7.15,-3.75 -8.11,-6.66s-2.71,-1.36 -4.07,-1.17s-7.74,7.75 -7.74,7.75a2.75,2.75 0 0 1 -0.71,0.08l0,0c-2.06,5.38 -7.1,7.13 -7.48,8.94s0.64,2.19 0,3.22s-3,2.2 -4.65,4.39c0.13,1.44 -6.71,12.79 -6.71,12.79c1.29,5.67 -2.19,9.54 -1.68,11.09s1.16,4.65 3,5.29s4.26,2.17 4.26,4.78a5.21,5.21 0 0 1 1.93,5.16a16.24,16.24 0 0 1 -4.38,0a12.27,12.27 0 0 1 -3.39,-2.32a19.6,19.6 0 0 0 -6.19,0.64c-1.65,0.64 -8.55,5.25 -12.55,7.62a7.85,7.85 0 0 0 4.94,3.87c5.87,1.42 14.9,3.68 17.87,11.23c2.58,4.26 3,4.77 3.22,5.81s1.29,14.64 2.45,16.32s5,5.16 6.13,7.81a11.19,11.19 0 0 0 4.39,4.7c0.71,0.38 16.9,-0.39 19.23,-0.91s10,-2.58 10.84,-2.77a20.24,20.24 0 0 1 5.29,-0.2c1.16,0.26 1.87,3.94 3,4.33s7.8,-12.45 4.39,-20.33c-5.94,-6.32 -6.39,-15.22 -7.94,-16.9c0,-2.19 -9.74,-13.81 -11.36,-16.19s-4.45,-6.13 -4.06,-8.58s1.68,-8.39 4.45,-9.16c2.78,0.32 9.61,-3.68 11,-4.2s6.45,-0.58 10.32,-3.61s5.74,-8.07 6.65,-10c-0.45,-2.45 2.45,-7.74 2.84,-8.32s0,-10.91 0,-10.91a2.65,2.65 0 0 0 -2.13,-3.25c-2.84,-0.77 -5.36,-5.48 -5,-7.16s2.93,-2.84 3.9,-7.77z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m301.85012,460.12662c-5.6,0.41 -14.53,0.75 -15,0.47a11.19,11.19 0 0 1 -4.44,-4.75c-1.16,-2.65 -5,-6.13 -6.13,-7.81s-2.25,-15.29 -2.45,-16.32s-0.64,-1.55 -3.22,-5.81c-3,-7.55 -12,-9.81 -17.87,-11.23a7.85,7.85 0 0 1 -4.94,-3.83l-0.22,0.13c-0.34,0 -0.67,0 -1,-0.08a45.61,45.61 0 0 0 -4.94,2.95a36.44,36.44 0 0 1 -6.53,4.23c-1.55,0.58 -3.1,4.26 -5.42,4.64s-2.32,3.68 -2.13,6s2.52,9.68 1.16,12.78a51.32,51.32 0 0 1 -0.09,6.82c-0.15,0.58 -0.3,2 -1.8,2.37c-0.72,0.82 0,2.23 0.39,2.81s0,4.26 -0.69,5.27s-0.47,3.63 -2.4,4.5s-3.88,3 -3.25,4.65a2.44,2.44 0 0 0 -1.93,2.76c0,0.29 -3.39,1.17 -3.84,2.37s-5.38,4.5 -6,4.88s-2,4.41 0.4,5.47s2.42,-0.29 4.55,-0.24s6.1,5.42 6.82,6.34s4.84,3.68 8.32,3.19s7.41,0.29 8.13,1.36s0.2,3.43 3,4.55s5.13,4.35 5,7.21s1.84,5.08 4.06,6.62s14.31,9.83 15.72,11.57s3.14,1.4 3,-0.24a5.73,5.73 0 0 1 2.08,-4.07c0,-0.72 0.05,-2.61 1.5,-2.37s2.61,0.34 4.11,0.68s2.71,-1.21 3,-2a2,2 0 0 1 2.28,-1c0.7,0.14 2.63,-1.39 4.16,-2.33l0,0c1.55,-5.22 -2.91,-15.48 -1.74,-21.29s0,-13 0,-13.93a10.47,10.47 0 0 1 2.9,-4.84a36.35,36.35 0 0 1 11.23,-4.45c0.17,-2.4 2.38,-5.69 4.22,-8.03z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m249.25367,512.41998c-2.22,-1.55 -4.16,-3.78 -4.06,-6.63s-2.27,-6.1 -5,-7.21s-2.23,-3.48 -3,-4.55s-4.65,-1.84 -8.11,-1.36s-7.6,-2.28 -8.33,-3.2s-4.69,-6.29 -6.82,-6.34s-2.18,1.31 -4.55,0.25c-1.43,-0.64 -1.55,-2.36 -1.28,-3.71a5.18,5.18 0 0 1 -1.94,1.36c-1.55,0.45 -1.68,1.36 -2.13,2.19s0.64,8.39 0.71,10.39s-0.71,5.68 -3.94,5.94c-2.13,2.38 -8.32,0.77 -15.93,-2.26s-11.23,0.58 -11.81,0.58a6.69,6.69 0 0 1 -3.42,-2.39c-1.35,-1.55 -2.64,-1.35 -3.55,-2.13a14,14 0 0 1 -1.72,-3.55l-0.28,-0.13c-1.8,-1 -2.06,-3.62 -2.06,-3.62s-0.78,2.33 -2.58,1.29s0,0 -4.13,-2.58s-6.19,1 -5.94,3.62c4.13,3.87 0.52,10.83 -0.77,10.83s-2.32,-6.18 -3.61,-1.79s0.25,2.58 3.35,6.71s0.78,8.51 1,11.87s-6.19,3.1 -3.61,9.81c3.39,11.86 8.55,15.22 13.45,22.7s7.48,3.36 11.61,3.36s2.58,3.87 2.58,3.87s-5.42,2.84 -4.38,8c3.87,9.29 5.93,3.1 9,5.42s5.42,0.77 11.35,0.77c2.76,0 3.95,1 4,2.4a14.23,14.23 0 0 0 4.85,-1.36c2.13,-1.46 3.39,-1.07 4.46,-1.36s3.88,-4.26 5.08,-4.94s9,-2.33 10.06,-3.29s3.78,-5.81 5.33,-6.78s5.8,-1.84 8,0a12.71,12.71 0 0 0 3.1,-0.49l0,0s0,-0.06 0,-0.09c1.07,-1 1.75,-5.71 1.65,-7.84s0.77,-5.71 1.84,-6.77s4.45,-6.1 4,-8.42s0.58,-8.61 0.58,-10.55s0.38,-2.71 1.06,-3.29c0.51,-0.43 4.22,-3.21 6.09,-4.62l0,0l-0.2,-0.11z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m241.89728,636.62367a39,39 0 0 0 -1.55,-4.84a35.39,35.39 0 0 0 -3.77,-6c-0.68,-0.68 -2.61,-5 -1.84,-5.81s1.35,-1 1.16,-3.09s-4.45,-1.75 -5,-0.49s-3.78,3.78 -5.64,2.62c-2.23,-0.1 -1.46,-1.65 -1.36,-2.62s4.84,-6.29 7.16,-7.64a18.27,18.27 0 0 1 2,-6.29c1.36,-2.33 1.07,-6.78 1.55,-9.39s4.94,-7.16 3.29,-12s-1,-20.52 -1,-20.52s-2.2,-4.24 -2.25,-6.59a12.39,12.39 0 0 1 -3.1,0.5c-2.23,-1.84 -6.49,-1 -8,0s-4.25,5.8 -5.32,6.77s-8.94,2.61 -10.11,3.29s-4,4.65 -5,4.94s-2.32,-0.1 -4.45,1.35a14.2,14.2 0 0 1 -4.85,1.37c0.09,1.59 -1.29,3.68 -3.5,5.34c-4.13,3.1 0.51,8.26 -0.52,9.81s-5.68,5.16 -2.09,6.19s3.87,2.58 0.77,2.84s-0.51,2.02 -5.16,7.02s-7,6.71 -3.61,9.55s5.41,9.24 4.13,17.45s0.28,11.66 4.9,14s13.68,4.63 12.13,8.5s1,10.06 2.32,10.32c7.23,-0.26 13.94,5.42 13.94,5.42s11.87,-15.49 13.16,-16.52s1.55,-5.93 5.42,-7.22s4.13,-6.97 6.19,-8.26z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m280.28067,598.21667c-2.73,-1.16 -6,-5.8 -6.82,-9.54s0,-11 1.91,-15.49s0.67,-13.93 0.16,-18.32a51.16,51.16 0 0 1 0.77,-13c0.39,-1.42 4.26,-12.52 4.65,-13.81s3.35,-3.61 4,-8.39a26.86,26.86 0 0 0 1.41,-5.28a4.51,4.51 0 0 1 -0.7,-2l0,0c-1.53,0.94 -3.46,2.47 -4.16,2.33a2,2 0 0 0 -2.28,1c-0.24,0.78 -1.45,2.33 -3,2s-2.61,-0.5 -4.06,-0.74s-1.5,1.65 -1.5,2.37a5.73,5.73 0 0 0 -2.08,4.07c0.09,1.64 -1.7,2 -3,0.24s-12.92,-9.62 -15.55,-11.44c-1.87,1.4 -5.58,4.18 -6.08,4.62c-0.68,0.58 -1.05,1.38 -1.05,3.29s-1.06,8.22 -0.58,10.54s-2.9,7.36 -4,8.42s-1.93,4.65 -1.83,6.78s-0.59,6.87 -1.59,7.84c0,2.32 2.32,6.67 2.32,6.67s-0.67,15.68 1,20.52s-2.81,9.39 -3.29,12s-0.19,7.06 -1.55,9.39a18.27,18.27 0 0 0 -2,6.29c-2.43,1.35 -7.17,6.64 -7.27,7.64s-0.87,2.52 1.35,2.62c1.84,1.16 5,-1.36 5.62,-2.62s4.83,-1.64 5,0.49s-0.39,2.32 -1.16,3.09s1.16,5.13 1.84,5.81a36.69,36.69 0 0 1 3.77,6a39,39 0 0 1 1.55,4.84l0,0c4.64,2.06 21.93,9.8 27.61,8.51s13.16,-0.25 14.45,5.68c5,1.61 5.68,1.48 6.67,-0.11a34.19,34.19 0 0 1 -1.63,-6.86c-0.26,-2.83 0.38,-2.06 1.8,-6.06s-1.42,-9.42 -2.45,-11s-1.68,-2.32 -0.39,-3.87s3.49,-3 3.74,-4s0.52,-7.23 -0.64,-10.19s-8.23,-9.13 -10.96,-10.33z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m372.95479,599.42501c-7.36,0.19 -23.81,2.9 -28.84,1.55c-1.16,-2.32 -7.16,-9.87 -8.9,-12a12.17,12.17 0 0 1 -2.91,-7.94c0.2,-2.71 0.78,-16.83 1,-20.71c0,-0.69 0,-1.36 0,-2a8,8 0 0 1 -2.81,-0.18a12.65,12.65 0 0 1 -5.1,-3.48a54.25,54.25 0 0 1 -3.92,-6.12c-0.41,-1.09 -0.61,-6 -0.85,-8.86s1.31,-3.43 2.49,-4.16a1.29,1.29 0 0 0 0.46,-2c-0.14,-0.22 -0.5,-0.65 -1.21,-1.72a1.86,1.86 0 0 0 -2.42,-0.48c-0.58,0.22 -1.81,1.38 -2.61,1.57a51.55,51.55 0 0 1 -7.57,0.25c-1.14,-0.17 -1.69,0 -3.39,0.67s-1.59,1.52 -0.99,2.1s4.18,3.61 3.79,3.78a8.69,8.69 0 0 0 -2.61,2.73c-0.51,1.09 -3.46,7.14 -3.46,7.14a15.65,15.65 0 0 1 -2,-1.38c-0.61,-0.56 -5.64,-5.08 -8.78,-5.69c-1.77,-0.7 -1.77,-1.38 -1.5,-2.66a2.12,2.12 0 0 1 1.54,-1.57l6.56,0c0.05,-0.92 -2.54,-4.21 -5.06,-5.25s-1.64,-2.69 -1,-3.31s2.81,-4.41 0.12,-4.41c-1.71,-1.74 -2.48,-5.38 -1.71,-8.71c-0.95,-2.06 -3.85,0.33 -5.29,-1.67a26.39,26.39 0 0 1 -1.42,5.29c-0.64,4.77 -3.61,7.09 -4,8.38s-4.26,12.39 -4.64,13.81a51.53,51.53 0 0 0 -0.78,13c0.52,4.39 1.76,13.81 -0.15,18.32s-2.69,11.78 -1.91,15.52s4.09,8.38 6.82,9.55s9.82,7.35 11,10.32s0.91,9.16 0.65,10.19s-2.45,2.45 -3.74,4s-0.65,2.32 0.38,3.87s3.87,7 2.46,11s-2.07,3.23 -1.81,6.07a35.69,35.69 0 0 0 1.63,6.85a28,28 0 0 1 1.94,-3c3.18,-4.13 16.17,-3.87 22.62,-2.58s16.26,-1 18.07,-0.78c-1.94,-5 -1.55,-25.55 -1.74,-27.47s0.58,-4.66 2.9,-4.46s5,0.77 6.19,0.77s8.32,0 11,1.16s1.94,3.68 9.1,1.94s6.78,-4.84 7.36,-6.2s6.58,-0.77 4.83,-6.19a6.5,6.5 0 0 1 0.47,-4.91l-0.26,0.06z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m472.7838,450.56116c-3.35,-2.06 -8.26,-7.74 -11.35,-12.13s3.87,-12.39 -0.78,-14.71c-8.51,-4.13 -9.29,-6.45 -10.06,-11.09s-4.39,-7.75 -11.36,-2.84s-18.58,-4.91 -21.93,-6.2s-4.9,-8.26 -6.71,-6.45s-9,12.65 -12.65,12.39s-6.19,-2.58 -11.35,2.84s-5.16,3.87 -9,2.06s-5.94,-4.64 -4.13,-11.87s8.51,-7 6.71,-10.06s-3.1,-3.1 -5.16,-3.1s-7.75,2.32 -9.81,-4.9s5.68,-8.52 1,-15.49s-4.13,-9.8 -2.32,-18.84s-0.26,-3.87 -5.16,-8.51s-5.68,-10.33 -0.26,-18.07c1.84,-2.62 2.31,-4.38 2,-5.71c-4.8,2.54 -14.6,8.84 -15.34,9.65s-3.33,5.74 -6.76,7.22c-1,5 -3.62,6.07 -3.94,7.75s2.19,6.38 5,7.16a2.65,2.65 0 0 1 2.13,3.29s0.39,10.32 0,10.9s-3.19,5.87 -2.77,8.32c-0.9,1.94 -2.77,7 -6.64,10s-9,3.1 -10.32,3.62s-8.2,4.51 -11,4.19c-2.75,0.77 -4.04,6.71 -4.46,9.16s2.45,6.19 4.06,8.58s11.36,14 11.36,16.2c1.54,1.67 2,10.58 7.93,16.9c3.3,7.59 -2.77,19.8 -4.24,20.31a7.82,7.82 0 0 0 3.18,2.75c6.07,3.1 10.33,13.42 10.84,15.23s3.23,2.71 2.84,12.25c2.84,0.91 6.19,-1.29 9,-3.22s7,0.26 8,1.68s12.52,2.32 19.36,-1.94c3.61,-1.81 6.84,1.16 8.13,1.94s2.45,0.29 5.93,1.75s7.1,8.05 8.26,9.86c2.84,1.16 7.74,3.35 10.45,3.48s9.29,-0.51 13.55,-3.74s8.39,-4.64 11.36,-4.13c2.83,-0.38 6.45,-0.38 7.61,0.52a13.89,13.89 0 0 0 5.68,1.8c0.86,0 2,2.24 2.92,4.87a8.23,8.23 0 0 1 4.43,0c7,1.8 10.32,4.64 13.16,-4.39s0.78,-14.71 0.26,-18.58s0.52,-11.1 -0.52,-18.32s6.23,-10.31 2.87,-12.38z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m449.86451,499.04666a14,14 0 0 1 -5.68,-1.81c-1.16,-0.9 -4.77,-0.9 -7.61,-0.52c-3,-0.51 -7.1,0.91 -11.36,4.13s-10.83,3.87 -13.54,3.75s-7.62,-2.33 -10.41,-3.49c-1.16,-1.8 -4.77,-8.39 -8.25,-9.85s-4.65,-1 -5.94,-1.76s-4.56,-3.74 -8.18,-1.94c-6.84,4.26 -18.32,3.36 -19.35,1.94s-5.16,-3.61 -8,-1.68c-2.55,1.74 -5.52,3.68 -8.16,3.4a15.14,15.14 0 0 0 -0.55,4.09c0.19,3.68 -0.39,18.83 -1.36,23.68s-4.84,6.16 -6,7.32s-3.67,6.2 -4.06,12s0,12.39 1,13.36s1.32,4.41 1.13,8.32s-0.78,18 -1,20.71a12.21,12.21 0 0 0 2.9,7.94c1.74,2.12 7.74,9.67 8.91,12c5,1.35 21.48,-1.36 28.83,-1.55c7,-1.74 16.07,-5.23 21.49,-6c2.71,-1.16 6.58,-6.39 10.45,-9.49s12.03,-6.61 13.19,-6.61s5.61,-0.58 9.49,2.33a21.54,21.54 0 0 0 7.25,3.67c0.42,0.26 1.75,1.06 5.65,3.28c7.22,4.13 2.84,-3.35 1,-5.93s-10.84,-25 -7,-31.23s7.23,-27.09 9.81,-34.06c2,-5.28 4.21,-10 8.22,-11.14c-0.85,-2.63 -2,-4.86 -2.87,-4.86z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m322.92268,535.92338c-1.18,0.72 -2.73,1.28 -2.49,4.16s0.43,7.76 0.85,8.85a55.64,55.64 0 0 0 3.91,6.12a12.78,12.78 0 0 0 5.11,3.49a8.42,8.42 0 0 0 2.8,0.18c0,-3 -0.41,-5.51 -1.2,-6.3c-1,-1 -1.36,-7.55 -1,-13.36s2.9,-10.84 4.06,-12s5,-2.52 6,-7.32s1.55,-20 1.36,-23.65a15.08,15.08 0 0 1 0.55,-4.08a3.64,3.64 0 0 1 -0.87,-0.18c0.38,-9.54 -2.33,-10.45 -2.84,-12.25s-4.75,-12.13 -10.81,-15.23a7.86,7.86 0 0 1 -3.15,-2.72a0.23,0.23 0 0 1 -0.14,0c-1.16,-0.42 -1.87,-4.05 -3.03,-4.31a20.26,20.26 0 0 0 -5.29,0.19c-0.84,0.2 -8.52,2.26 -10.84,2.78c-0.61,0.13 -2.19,0.29 -4.18,0.43c-1.84,2.35 -4.05,5.63 -4.28,8.08a36.63,36.63 0 0 0 -11.22,4.41a10.52,10.52 0 0 0 -2.9,4.88c0,1 1.16,8.13 0,13.94s3.29,16.06 1.74,21.29c0.77,5.22 4.84,1.16 6,3.68c-0.78,3.29 0,7 1.74,8.71c2.69,0 0.53,3.77 -0.12,4.4s-1.53,2.27 1,3.31s5.11,4.33 5.06,5.25l-6.57,0a2.15,2.15 0 0 0 -1.55,1.54c-0.26,1.28 -0.26,2 1.5,2.66c3.15,0.6 8.18,5.13 8.78,5.68a16.27,16.27 0 0 0 2,1.38s2.95,-6.05 3.46,-7.14a8.69,8.69 0 0 1 2.61,-2.73c0.39,-0.17 -3.17,-3.19 -3.8,-3.77s-0.67,-1.43 1,-2.08s2.25,-0.85 3.39,-0.68a51.66,51.66 0 0 0 7.56,-0.2c0.8,-0.2 2,-1.36 2.61,-1.58a1.87,1.87 0 0 1 2.42,0.49c0.7,1.06 1.07,1.5 1.21,1.72a1.29,1.29 0 0 1 -0.44,1.96z" class="cls-1"/>
        </g>
        <g class="vks">
          <path  d="m436.11439,627.21244c8.52,-10.58 4.65,-27.61 3.62,-32.26c-1,-4.41 -4.29,-11.38 -4.62,-12.06a21.54,21.54 0 0 1 -7.25,-3.68c-3.87,-2.9 -8.33,-2.32 -9.49,-2.32s-9.29,3.48 -13.16,6.58s-7.76,8.37 -10.45,9.48c-5.31,0.77 -14.31,4.17 -21.22,5.89a6.54,6.54 0 0 0 -0.46,5c1.74,5.42 -4.26,4.84 -4.84,6.19s-0.19,4.46 -7.35,6.2s-6.39,-0.78 -9.1,-1.94s-9.87,-1.16 -11,-1.16s-3.87,-0.58 -6.19,-0.77s-3.15,2.48 -2.94,4.41s-0.21,22.44 1.79,27.47c1.81,0.26 1.3,4.39 -3.09,10.07s-0.31,15.53 4.59,20.38s10.84,6.46 11.87,9s0.52,12.13 5.68,12.38c4.39,13.68 9.81,12.13 12.65,8.78s12.9,-0.26 19.09,0.77s24.26,1.81 24.78,-1.8s4.13,-7.75 6.19,-8.78s7.23,-4.13 4.9,-9.81c4.65,-5.67 13.42,-35.87 13.17,-40.25s-1.29,-8.48 2.83,-17.77z" class="cls-1"/>
        </g>
      </g>

    </svg>
  </div>
</template>

<script>
export default {
  data(){

  },
  methods:{
    select(i){
      console.log('当前村',i)
    }
  }
}
</script>

<style lang="less" scoped>
  .vks {
    fill: url(#linear);
    cursor: pointer;
    path {
      stroke: #FFFFFF;
      stroke-width: 4px;
    }
  }
  .vks:hover {
    fill: url(#linear-hover);
    box-shadow: 0px 2px 32px rgba(14, 48, 199, 1);
    path {
      stroke: #0E30C7;
    }
  }
</style>