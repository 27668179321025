<template>
  <div style="width: 300px">
    <el-upload ref="upload" v-model:file-list="fileList" :auto-upload="true" :before-upload="beforeAvatarUpload"
               :limit="Number(limitNum)"
               :on-remove="handleRemove"
               :show-file-list="showFileList"
               accept=".mp4"
               action="#"
               drag
    >
      <el-icon class="el-icon--upload">
        <upload-filled/>
      </el-icon>
      <div class="el-upload__text">
        将文件拖拽到此区域，或<em>点击上传</em>

      </div>
    </el-upload>
<!--    <div>{{fileList}}</div>-->
    <video   v-for=" item in fileList" :src="item.relUrl" width="300px" controls></video>
    <!-- 进度条 -->
    <el-progress v-if="progressFlag" :percentage="loadProgress"></el-progress>
<!--    <el-dialog v-model="dialogVisible">-->
<!--      <div>{{ dialogImageUrl }}</div>-->
<!--      <video v-if="dialogImageUrl.type == 'mp4'" controls style="width: 100%">-->
<!--        <source :src="dialogImageUrl.url" type="video/mp4">-->
<!--      </video>-->
<!--      <img v-else :src="dialogImageUrl.url" alt="Preview Image" style="width: 100%" w-full/>-->
<!--    </el-dialog>-->
  </div>
</template>


<script>
import {uuid} from "vue-uuid";
import ObsClient from "esdk-obs-browserjs/src/obs";
// import obsMultipartUpload from "./more";
import {ElMessage} from "element-plus";

let moment = require('moment');
import {randomNumStr} from "@/utils/random";


export default {

  props: {
    modelValue: String,

    limitNum: {
      type: String,
      default: '1',
    }
  },
  data() {
    return {
      fileInfo: {},
      fileList: [],
      dialogVisible: false,
      file: undefined,
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 关闭进度条
      Uploadid: '',
      ETag: '',
      showFileList: true,
      dialogImageUrl: {},
      obsObj: {
        access_key_id: "RL0OFCJZRMUEEUROCDFU",
        secret_access_key: "7JFnexa1AyeLXxAfhNxh3ARmvYELb6tBdy7n4MYb",
        server: "https://obs.cn-south-1.myhuaweicloud.com",
        Bucket: 'ssyn',
        // putUrl:"https://ssyn.obs.cn-south-1.myhuaweicloud.com/server/", //修改上传文件夹名称
        BucketFileName: 'server/'
      }
    };
  },
  watch:{
    modelValue:{
      immediate: true,
      handler(newVal,oldVal){
        if (newVal) {
          this.fileList = newVal.split(',').map(item=>{
            let obj = {
              url: this.$store.state.vuex_previewUrl + this.$store.getters.getData('vuex_tenantId') + '/' + item,
              fileId: item,
              name:item,
              delKey: this.obsObj.BucketFileName + this.$store.getters.getData('vuex_tenantId') + '/' + item,
              relUrl:this.$store.state.vuex_previewUrl + this.$store.getters.getData('vuex_tenantId') + '/' + item,
            }
            return obj
          })
        } else {
          this.fileList = []
        }
      }
    }
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl.url = file.relUrl;
      this.dialogImageUrl.type = file.type
      this.dialogVisible = true;
    },
    uploadVideoProcess(event, file, fileList) {
      this.progressFlag = true; // 显示进度条
    },
    handleDownload(file) {
      console.log(file);
    },
    handleRemove(file, fileList) {
      let obsClient = new ObsClient({
        access_key_id: this.obsObj.access_key_id,
        secret_access_key: this.obsObj.secret_access_key,
        server: this.obsObj.server
      });

      obsClient.deleteObject({
        Bucket: this.obsObj.Bucket,
        Key: file.delKey
      }, function (err, result) {
        if (err) {
          console.log('Error-->' + err);
        } else {
          console.log('Status-->' + result.CommonMsg.Status);
        }
      });
    },
    handleUploadError(err) {
      console.log(err);
      this.loading.close();
    },
    beforeAvatarUpload(file) {
      this.fileInfo = file
      let that = this;
      let fileExtension = "";

      if (file.name.lastIndexOf(".") > -1) {
        fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
      }
      let uuids = uuid.v1() + Date.now() + "." + fileExtension;

      let fileId = moment().format('YYYYMMDDHHmmss') + randomNumStr(5) + "." + fileExtension;

      // 创建ObsClient实例
      var _obs = {
        bucket: that.obsObj.Bucket,
        object_key: that.obsObj.BucketFileName + that.$store.getters.getData('vuex_tenantId') + '/' + fileId,
        endpoint: that.obsObj.server,
        access_key_id: that.obsObj.access_key_id,
        access_key_secret: that.obsObj.secret_access_key,

      }

      // 初始化分段上传任务（获取UploadId）
      function initiateMultipartUpload(client, _obs) {
        return new Promise((resolve, reject) => {
          client.initiateMultipartUpload({
            Bucket: _obs.bucket, // 桶名
            Key: _obs.object_key, // 文件名
            ContentType: 'text/plain',
            // Metadata: { 'property': 'property-value' }
          }, function (err, result) {
            if (err) {
              ElMessage({
                message: '获取 UploadId 失败',
                type: 'error',
                duration: 5 * 1000
              })
            } else {
              if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
                resolve(result.InterfaceResult.UploadId)

              }
            }
          });
        })
      }

// 上传段
      function obsUploadPart(client, _oss, file) {
        return new Promise(async (resolve, reject) => {

          let Parts = [];
          const UploadId = await initiateMultipartUpload(client, _oss);
          const PartSize = 50 * 1024 * 1024;
          const lastPartSize = file.size % PartSize;
          // 段数量
          const count = Math.ceil(file.size / PartSize);
          // 上传第n段
          const uploadPart = (n) => {
            client.uploadPart({
              Bucket: _oss.bucket,
              Key: _oss.object_key,
              PartNumber: n, // 设置分段号，范围是1~10000
              UploadId, // 设置Upload ID
              SourceFile: file, // 设置将要上传的大文件
              PartSize: count === n ? lastPartSize : PartSize, // 设置分段大小
              Offset: (n - 1) * PartSize, // 设置分段的起始偏移大小
              ProgressCallback: function (tfa, ta) {
                // 进度条
                let percentage = ((tfa + ta * (n - 1)) * 100.0 / (ta * count)).toFixed(2);
                that.progressFlag = true
                that.loadProgress = Number(percentage)
                that.showFileList = false
                if (that.loadProgress >= 100) {
                  setTimeout(() => {
                    that.progressFlag = false;
                    that.showFileList = true
                  }, 500) // 一秒后关闭进度条
                }
              },
            }, function (err, result) {
              if (err) {
                ElMessage({
                  message: '文件段上传失败',
                  type: 'error',
                  duration: 5 * 1000
                })
              } else {
                if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
                  Parts.push({
                    PartNumber: n,
                    ETag: result.InterfaceResult.ETag
                  })
                  if (count === n) { // 最后一段时得到 UploadId 和 所有的 ETag
                    resolve({
                      UploadId,
                      Parts
                    })
                  } else { // 非最后一段继续上传
                    uploadPart(n + 1);

                  }
                }
              }
            });
          }
          // 上传第1段
          uploadPart(1);
        })
      }

// 合并分段
      async function obsMultipartUpload(_oss, file) {
        return new Promise(async (resolve, reject) => {
          // 创建ObsClient实例
          const obsClient = new ObsClient({
            server: _oss.endpoint,
            access_key_id: _oss.access_key_id,
            secret_access_key: _oss.access_key_secret,
            // security_token: _oss.security_token
          });

          const {UploadId, Parts} = await obsUploadPart(obsClient, _oss, file);
          obsClient.completeMultipartUpload({
            Bucket: _oss.bucket, // 桶名
            Key: _oss.object_key, // 文件名
            UploadId, // 设置Upload ID
            Parts // 所有有效的分段（分段号、分段ETag值）
          }, function (err, result) {
            if (err) {
              ElMessage({
                message: '合并文件段失败',
                type: 'error',
                duration: 5 * 1000
              })
              reject(err);
            } else {
              resolve(result.CommonMsg.Status);
            }
          });
        })
      }

      obsMultipartUpload(_obs, file).then((res) => {
        console.log('上传成功' + res)
        console.log('让我看看')
        console.log(that.fileList)
        console.log(that.obsObj)
        let typeName = file.type.split('/')[1]
        let showUrl = ''
        if (typeName == 'mp4') {
          showUrl = 'https://typorazjf.oss-cn-chengdu.aliyuncs.com/images/image-20230913100910028.png'
        } else {
          showUrl = that.$store.state.vuex_previewUrl + that.$store.getters.getData('vuex_tenantId') + '/' + fileId
        }
        var randomNum = String(Math.floor((Math.random() + Math.floor(Math.random() * 9 + 1)) * Math.pow(10, 7 - 1)));
        that.fileList.push({
          name: file.name,
          url: showUrl,
          size: file.size,
          type: typeName,
          delKey: that.obsObj.BucketFileName + that.$store.getters.getData('vuex_tenantId') + '/' + fileId,
          id: Date.now() + randomNum,
          relUrl: that.$store.state.vuex_previewUrl + that.$store.getters.getData('vuex_tenantId') + '/' + fileId,
          fileId:fileId
        })
        let idStr = that.fileList.map(item=>{
          return item.fileId
        }).join(',')

        that.$emit("update:modelValue", idStr);

        that.loading.close();

      }).catch(err => {
        // console.log('上传失败' + err)
      })
      return true;
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-upload-dragger {
  height: 100%;
}

</style>

