export const randomNumStr = (len)=>{
    len = len || 6;
    let $chars = '123456789';
    let maxPos = $chars.length;
    let numStr = '';
    for (let i = 0; i < len; i++) {
        numStr += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return numStr;
}
