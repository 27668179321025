<template>
<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 1807 2050.5" style="enable-background:new 0 0 1807 2050.5;" xml:space="preserve">

    <defs>
          <linearGradient id="linear-hover" x1="100%" y2="100%">
            <stop offset="0%" style="stop-color: #7285FD"></stop>
            <stop offset="100%" style="stop-color: #0E30C7"></stop>
          </linearGradient>
      <linearGradient id="linear" x1="100%" y2="100%">
            <stop offset="0%" style="stop-color: #7285FD"></stop>
        <stop offset="100%" style="stop-color: #90B2FC"></stop>
          </linearGradient>
        </defs>
  <g class="vks">
          <path class="st0" d="M560.1,496.2c-7-10.1-3.6-51.4-3.5-61.9s20.5-50.6,21-63.2s-18.1-36.9-25.5-43.4s-9.3-16-10.2-29.9
	s3.5-41.5,5.8-63.5s-3.9-32.3-3.9-32.3c0.4,5-1.9,14.9-5.8,20.1s-6.1,13.8-8.4,12.7c0,0-40.3-19.1-50.1-24.8s-11.4-7.2-19.6-10.3
	s-27.4-16-31-20.1s-12.9-5.2-18.1-4.1s-15.5,0-17.5-3.6s5.2-16.5,0-18.6s-11.9-3.1-18.6,3.6c-4.4,8-5,8.8-7,8.1
	s-10.6-14.2-14.5-15.9s-13.7-13.2-18.3-12.5s-13.5-3.6-19,2.5s-20.8,27.4-21.5,30.1s-9.4,12.1-13.2,12.8s-17.7,6.5-25.8-0.6
	s-16-13.8-20-16.8S224,162,220.7,164s-17.5,10.4-18.4,20s-2.6,23.7-5.4,25.7s-0.3,30.2,1.3,33.5s2.6,29.3,0.8,32.1
	s-7.6,14.1-6.2,16.3s7.6,4.9,11,5.3s25,16.6,28.5,19.9s19,19.4,18.2,23.7s-0.8,15.5-5.7,20.4s6.7,18.3,9.6,21.4
	c2.8,3.1,12.7,20.1,12.1,27.1s-0.5,29.2-5.4,37.2s-7.5,20.1-8,24.8s7.2,10.3,7.2,14.2s-8,47.2-10.6,53.4s-1.6,13.2,0.5,16.2
	s8.5,16,7,20.4s-3.4,30.5-1.3,36.1s3.6,29.2,6.4,34.1s9.3,15.2,5.4,21.4s-28.4,21.2-32.8,25.3s-7,5.2-4.6,6.7s19.1,2.8,22.2,2.6
	s11.4-5.9,13.9-3.6s-7.5,21.7-10.8,24s-12.4,12.7-15.7,12.7s-14.4,0-15,4.6s0.5,21.4,5.7,21.9s91.6,10.6,97,8.8s15.5-8.5,20.4-5.7
	c0,0,15.5-9.5,21.7-13.7s30.5-40.5,37.7-52.4s19.9-18.8,30.2-19.6s15,10.6,19.9,13.4s25-16,32.3-23.2s25.5-49.8,27.1-57.5
	s-2.1-28.6,0-42.3S550.3,508.6,560.1,496.2z"/>
  </g>
  <g class="vks"><path class="st0" d="M1006.6,289.2c2.5-13.9,2.7-43-4.6-49.6s-26.3-12-27.7-13.6s8.3-18.6,13.2-23.6s5.8-7.9,10.3-7.6s7.9,1,5.6-8.7
	s-3.7-20.7-1.7-24.8s12.8-19.2,11.2-23.4s-13-19.5-16.8-26.9s-8.7-35.6-13.4-36.8s-10.2-3.3-13.5,4.8s-13.2,16.1-13.8,19.6
	s5.6,11.8,7,16.3s2.7,12,2.1,15.7s-1.2,4.6-7.5-2.5c-6.4-7.2-2.7-13.7-7.5-15.5s-11.8,1-17,2.5s-14.9,2.5-19.3-1.7
	s-17.4-4.7-23.6-4.4S843.1,91,838,90.8s-11.2,3.7-14.5,8.3s-16.7,18.6-19.2,25.7c-2.5,7.2-3.7,10.6,1.9,13.5s13.9,12.6,13.4,21.1
	s2.9,22.1-3.7,25.9s-16,10.6-16,13.5s1.3,14.1,3.1,16.4s-7,6.6-15.3,6s-17.4,1.6-17.8-2.3s5.6-26.5,0-33.9s-3.3-10.4-10.1-11.6
	s-18.2,4.8-25.3,2.9s-25.8-0.2-30-3.7s0-12.2-4.7-17s-7-7.9-11.2-4.6s-3.7,7.6-7.9,7.4s-6,2.7-7.5,5.2s-7.7,6.4-12,4.1
	s-1.9-17.2-0.2-19.4s8.7-10.3,10.3-11.2s-5.6-5.8-14.1-4.6s-16.1-5.4-18-11.8s-7.9-14.7-11.4-19s-8.5-12.2-13.2-12.2
	s-6.2-4.8-10.8-6.6s-10.7-5.6-13.6-1.3s-19.7,20.3-23.4,25.5s-12.6,8.7-15.5,13.4s-9.9,18.2-9.9,26.3s-6.2,24.8-3.9,30.6
	s6,19.4,6.4,24.4c0,0,6.2,10.4,3.9,32.3s-6.7,49.6-5.8,63.5s2.7,23.5,10.2,29.9s26.1,30.7,25.5,43.4s-20.9,52.6-21,63.2
	s-3.5,51.9,3.5,61.9c1.5,0.9,5.8,10.8,10.3,14.8s24,26.9,26.3,34.8s4.3,37.5,1.9,43.8s-4.7,28.8-2.5,31.2s9.3,6.2,16.3,5.4
	s21.6-7.2,26.1-8.9s9.9-4.3,19.5-3.9s33.7-2.3,44.1-5.8s31.2-7.2,35-12.6s49.9-61.4,53.8-64.3s49-50.1,54.6-52.1s50.6-1.5,57.9-6.2
	s40.6-48,42.5-55.4s14.1-3.5,29-17.4s27.5-22.8,30.8-28.5s15.1-28.6,15.5-36s1.1-15.8,1.3-18.2c-1.4-1.1-2.8-2.2-4.1-3.4
	C1013.6,308.6,1004.1,303.1,1006.6,289.2z"/></g>
  <g class="vks"><path class="st0" d="M1306,521.1c9.3,3.9,53.4,23.6,63.9,28.7s29.4,9.3,40.6,10.5s33.3,5.8,42.2,3.5s24-9.7,24.4-19s7-57.7,7.4-67.7
	s-4.7-56.5,3.1-65.4s28.3-24.8,43.4-31.4s29-19,29.8-25.9s3.9-21.3-13.5-46.5s-8.5-113-9.3-127.4s0.4-21.3,0.4-29.4l0,0
	c-1.2,0-2.6-0.9-4.3-3.9c-3.7-6.6-3.9-17-8.1-20.7c-4.2-3.7-7-3.3-10.8-6.2s-11-8.7-15.5-0.4s-13.6,22.1-15.3,24.6
	s-2.7,13.7-2.1,16.2s-12.6,1.2-19.6,0.6s-10.2,4.8-18,6.8s-17.4,2.3-18.8-1.2s5.8-16.4,8.5-20.1s5-10.3-1-13.9s-17-15.9-23.8-16.3
	s-19.3-2.7-25.9,3.3s-15.1,11.4-30.2,7.8c-15.1-3.7-54.4-4.3-59.2,5c-4.8,9.3-6.6,15.7-12.6,13.7s-10.4-5.4-12.8-3.5
	s-6.6,6-10.8,3.9s-12.4-7.2-15.5-4.6s-10.3,5.6-8.9,9.1s0.2,24.4-9.7,30s-21.3,13.6-27.5,12s-12.6-8.5-16.1-5.8s-10.8,10.3-8.5,15.7
	s7,8.9,6,11.2s-16.8,8.5-20.7,16.4s-16.3,27.5-18,37s-11.4,46.2-9.5,55.5s1,26.3,6,35.2s1.9,11.4,1.2,12.2
	c6.6,10.3,29,42.4,32.1,50.9s5.4,22.5,17.8,28.6s49.2,42.2,60,48.4S1296.7,517.2,1306,521.1z"/></g>
  <g class="vks"> <path class="st0" d="M1761.4,482.2c-2.1-2.3-7-4.5-6.8-7.7s3.9-11-0.2-16.8s-8.7-10.3-5.8-12.2s11-2.3,14.3,0.4s11.6,7.5,14.9,9.1
	s5.8,2.7,7.9,6.6s8.5,3.9,10.7,3.7s8.7,1.2,9.9-3.1s1-17-2.5-19.7s-9.3-5.2-13.3-4.6s-7-3.1-5.8-8.9s18.8-31.7,19-38.3
	s5.2-26.5,0-37s-10.5-22-17.7-33.8s-9.9-40.3-9.3-46.3s-2.3-80.7,2.1-92.1s5.8-24.8-7-28.8s-32.5-6.4-45.3,1.7s-21.9,14.5-29.8,11.6
	s-26.9-10.5-32.7-18.6s-14.3-26.5-15.3-32.1s-14.7-15.3-17.4-22.8s-7.9-39.1-11-43s-17.2-21.7-22.4-25.7s-22.3-16.8-24.6-19.4
	s-17.2-5.8-23.4-3.1s-22.8,38.9-22.1,44.7s-0.4,20.5,3.5,27.3s7.6,46.8,9.5,53c1.9,6.2,2.9,19.7,1.2,22.1c-1,1.3-2.2,2.9-3.7,2.9
	l0,0c0,8.1-1.2,15.1-0.4,29.4s-8.1,102.2,9.3,127.4s14.3,39.5,13.5,46.5s-14.7,19.4-29.8,25.9s-35.6,22.5-43.4,31.4
	s-2.7,55.4-3.1,65.4s-7,58.5-7.4,67.7c0.8,9.3,1.6,48.8,4.3,54.2s4.3,15.5,13.2,22.8s6.6,9.7,19.7,11.2s38.3-12.8,44.9-13.5
	s32.1-8.1,42.2-7.7s38.3,1.5,46.8,2.7s18.9-3.5,22.4-5.4c2-1.1,2.6-2.3,3.5-3.2c0-2.5,0.3-4.6,1.3-6.1c2.9-4.5,17.1-28.8,23.4-32.7
	s9.3-8.7,17.1-9.3s11-1.2,18,1.2s14.9,1.7,15.9-0.2s2.5-15.3-4.4-23.2s-13.6-10.5-14.7-16.1s0.4-10.1,4.4-13.4s19.6-11.6,22.4-16.1
	S1763.6,484.5,1761.4,482.2z"/></g>
  <g class="vks"> <path class="st0" d="M1306,521.1c-4.5-1.9-16.2-5.8-28.2-10.1c0.8,3.6,2.1,7.1,3.8,10.5c5,8.7,12.8,29.6,12.6,41.4
	s0.6,41.8-6.6,49.9s-13,19.9-12.8,21.7s8.5-1.7,18.2-2.9s22.3-2.7,29.2,0.6s29,8.9,40.3,12.2s32.5,7.7,53.4,6.4s49.2-5.8,59.6-8.5
	s25.6-7.6,29.3-11.4c-3.6-1.7-4.6-4.3-10.4-9.1c-8.9-7.4-10.4-17.4-13.2-22.8s-3.5-44.9-4.3-54.2c-0.4,9.3-15.5,16.7-24.4,19
	s-31-2.3-42.2-3.5s-30.2-5.4-40.6-10.5S1315.3,525,1306,521.1z"/></g>
  <g class="vks"> <path class="st0" d="M1316.7,870.4c4.1,4.6,14.9,17.8,22.4,25.7s28.4,33.9,33.3,35.6c2.9,1,5.6,2.6,7.9,4.6c0.9-2.9,1.3-5.9,1.2-8.9
	c-0.8-11.4-2.3-35.2-0.8-39.5s6.4-13.9,11.6-15.1s11.6-4.6,18.6-1.5s14.7,7,22.6,6s7.2-4.1,7.4-9.3s0-12.6,2.1-13.2
	s8.7,3.9,10.1,8.3s4.3,19.2,9.9,23s7.7,9.7,9.5,11.4s15.1-1.4,15.9-4.1s1.9-12.6,7.9-19.2s5.6-9.5,8.3-9.5s10.3,4.5,13.4,3.3
	s15.5-10.1,19-10.7s6.4-13.3,12.4-16.3s16.8-10.6,28.3-12s29.2-4.5,33.9-7.2s13.6-8.3,14.9-11.4s-2.9-11.4-0.8-15.9
	s5.8-10.1,8.7-11.2s11.8-0.6,15.7-3.1s8.3-5.8,9.3-11.8s3.7-15.7,0.4-20.1s-11.8-5.6-12.4-8.1s6.2-7,10.1-13s8.5-18,7.6-21.7
	s0.6-6.8-7-8.7s-24.4-6.4-25.9-13.4s-4.8-20.3,5.6-29.2s35.6-28.3,36.8-34.3c0.8-4-0.2-9.8-0.2-14.6l0,0c-0.9,0.8-1.5,2.1-3.5,3.2
	c-3.5,2-13.9,6.6-22.4,5.4s-36.8-2.3-46.8-2.7s-35.6,7-42.2,7.7s-31.8,15.1-44.9,13.5c-4.7-0.5-7.4-1.2-9.4-2.2
	c-3.8,3.8-18.9,8.7-29.3,11.4s-38.7,7.2-59.6,8.5s-42.2-3.1-53.4-6.4s-33.3-8.9-40.3-12.2s-19.6-1.7-29.2-0.6s-18,4.6-18.2,2.9
	c-2.5,2.9-19.9,19.3-24,26.5s-16.1,33.1-18.2,49.5s-4.1,49.9-4.8,56.3s-1.7,40.5,0,46.6s4.4,25,8.5,26.1s25,1,38.1,7.4
	S1312.6,865.8,1316.7,870.4z"/></g>
  <g class="vks">
      <path class="st0" d="M1139.7,951.7c6.7,2.1,33.3,11.9,40,16.5s43.6,24.5,49.6,27.6s19.6,16.5,25.3,29.7c5.7,13.2,3.9,29.9,20.4,43.1
	s37.7,32.8,41.8,48.8s24.5,84.6,27.1,88.8c1.1,1.7,2.3,3.3,3.7,4.7c-0.5-9.2-0.4-21.1,2.8-25.7c5-7.4,35-52.5,38.1-61.6
	s29.2-63.1,29.8-70.4s4.1-32.3-5-44.3s-32.7-43.3-37.2-49.7c-3.3-4.8,1.8-13.7,4.3-22.7c-2.3-2-5-3.6-7.9-4.6
	c-4.8-1.7-25.7-27.7-33.3-35.6s-18.4-21.1-22.4-25.7s-29-17-42.2-23.4s-34.1-6.2-38.1-7.4s-6.8-19.9-8.5-26.1s-0.8-40.3,0-46.6
	s2.7-39.9,4.8-56.3s14.1-42.4,18.2-49.5s21.5-23.6,24-26.5c-0.2-1.7,5.6-13.5,12.8-21.7s6.4-38.1,6.6-49.9s-7.6-32.7-12.6-41.4
	c-1.7-3.3-3-6.8-3.9-10.5c-12.6-4.4-25.4-9.2-31-12.4c-10.8-6.2-47.6-42.2-60-48.4s-14.7-20.1-17.8-28.6s-25.6-40.6-32.1-50.9
	c-0.8,0.8-9.3,2.5-14.9-6.4s-14.1-16.5-29.4-22.1s-37.6-7.2-44.1-9.5c-5.3-1.9-18.4-10-25.8-15.7c-0.2,2.5-1,10.9-1.3,18.2
	s-12.2,30.4-15.5,36s-15.8,14.5-30.8,28.5s-27.1,10.1-29,17.4s-35.2,50.7-42.5,55.4c10.4,2.8,22.5,0.7,33.9,5.9s36.6,28.6,40,34.8
	s10.1,63,5.9,73.5s-21.7,47-21.4,51.6s2.3,6.5,5.9,3.6s11.4-12.4,20.9-13.2s18.6-7.5,26.3-7.2s18.6,2.1,24.5,8s32.5,26.6,39,29.7
	s27.4,16.3,35.4,21.9s18.8,19.9,19.9,30.5s-4.4,59.1-9.3,65s-30.5,51.6-36.7,58.8s-53.9,78.2-51.3,94.7c11.6,4.9,32.5,4.1,40.8,6.2
	S1133,949.6,1139.7,951.7z"/></g>
  <g class="vks"><path class="st0" d="M1689.7,1542.2c0-4.4-5.8-18.8-10.4-21.5s-20.7-5-22.1-7.2s-9.3-6-14.3-10.8s-9.5-6.6-17.6-5.4
	s-28.1,2.3-36.8-8.9s-4.8-13.2-6.4-17.2s-11.2-11.2-13.7-17.6s-3.5-11.4-10.6-12.8s-20.3-2.7-27.5-7.9s-14.5-14.9-19.7-16.8
	s-13.3-3.9-21.1-0.4s-21.7,14.5-29.2,13s-9.5-8.7-15.9-7.7s-5.6,5.4-8.7,6.6s-16.8-3.9-23.8-0.8s-24.2,12.8-32.7,26.1
	s-10.4,21.7-10.8,26.3s-2.3,8.7-7.3,15.5s-10.7,16.6-14.7,17.8s-8.5,5-15.1-1.9s-9.7-10.1-14.7-11.8c1,14.1,4.8,41.2,6.4,48.6
	s5.8,29.4,15.5,40.3s12.8,17.8,20.1,22.4s17,5.4,39.1,8.1s27.1,1.6,40.6,8.5s42.6,25.2,58.8,30.6s22.1,8.5,32.1,10.4
	s21.7,5.4,27.5,10.1s39.5,24,48.4,29c6.8,3.8,13.1,8.8,19.8,11.8c1.2-0.1,2.3-0.4,3.4-0.7c6.4-2.1,19.2-12.2,19.2-19.7
	s-3.9-21.5,0-28.8s13.7-31.2,17.2-35.6s13.7-22.4,12.2-30.2s1.2-17.2,10.1-26.9s13-14.5,12-17.4S1689.7,1546.7,1689.7,1542.2z"/></g>
  <g class="vks"><path class="st0" d="M1624.7,1718.5c-6.7-3-13-7.9-19.8-11.8c-8.9-5-42.6-24.4-48.4-29s-17.4-8.1-27.5-10.1s-15.9-5-32.1-10.4
	s-45.3-23.6-58.8-30.6s-18.6-5.8-40.6-8.5s-31.7-3.5-39.1-8.1s-10.4-11.6-20.1-22.4c-2.6-3.1-4.8-6.5-6.4-10.2
	c-6.9-3.4-13.4-7.2-18.1-11c-16.5-13.4-29.9-4.1-38.2,6.2s-41.8,42.3-44.4,57.8s0,31,6.2,35.6s25.3,27.9,33,33.6
	s20.1,20.1,17.6,31.5s-13.4,27.9-27.3,31s-23.8,4.1-44.9-5.7s-40.2-22.2-48-23.2s-22.2-4.1-31.5,5.2c-7.7,7.7-47.2,46.8-62,67.7
	c4.2,5.7,9.2,12.1,11.9,13.4c4.1,2.1,20.5,6,25.7,11.2s8.3,18.6,10.7,19.9s15.3,1.2,20.9,4.8s18.6,39.7,21.3,45.7s2.9,7.6,5,7.6
	s4.7-4.3,9.3-2.7s31.9,19.7,35.4,22.3s11.4-11.4,15.1-14.5s15.5-28.3,19.6-29.6s20.5-15.1,24-33.9s12.6-49.9,19.6-54.2
	s30-13.3,36.6-21.5s24.2-7.3,28.2-5.6s10.3,4.3,14.9,5s19.2,7.2,25.9,11.2s6.2,5.8,10.1,5.8s7.6-6,12.8-7.3s10.7-3.5,13.7-2.3
	s9.9,5.4,14.1,5.4s10.3-5.8,15.3-6.6s18.6-3.1,24.6-1s25.8,4.1,34.1,4.4s13.9,0.8,21.9,2.5s15.3,4.3,20.9,0s10.1-13,8.3-25.7
	s-4.5-25.6-1-31.3s11.4-10.3,23.6-10.5C1606.9,1718.4,1617.7,1719.5,1624.7,1718.5z"/></g>
  <g class="vks"> <path class="st0" d="M1185.7,1286.8c16.6,5.4,101.4,57.6,105.1,61.2c0-5.1,0.7-10.5,3.1-13.2c4.7-5,0.6-8.9,0.8-12.2
	s8.3-14.3,12.8-19.7s18.7-16.8,19.5-23.6s5-12.6,10.1-13.7s7-12,9.7-16.7s9.7-12.6,9.3-18.2s-6.8-4.7-7.4-8.3c-0.2-1.4-0.7-6-1-11.5
	l0,0c-1.4-1.4-2.7-3-3.7-4.7c-2.6-4.1-23-72.8-27.1-88.8s-25.3-35.6-41.8-48.8s-14.7-29.9-20.4-43.1s-19.3-26.6-25.3-29.7
	s-42.8-23-49.6-27.6s-33.3-14.5-40-16.5s-54.2-13.2-62.4-15.2s-29.2-1.3-40.8-6.2c-2.6,6.6-49.4,135.1-51.8,143.2s-3.5,9.7,0,13.9
	s29,51.7,32.1,61.8s33.3,47.3,48.4,55.8s48.4,38.3,56.9,44.5S1169,1281.3,1185.7,1286.8z"/></g>
  <g class="vks"><path class="st0" d="M1065.3,1205.1c-15.1-8.5-45.3-45.7-48.4-55.8s-28.6-57.6-32.1-61.8s-2.3-5.8,0-13.9s49.2-136.7,51.8-143.2
	c-2.6-16.5,45.2-87.5,51.3-94.7s31.7-52.9,36.7-58.8s10.3-54.5,9.3-65s-11.9-24.8-19.9-30.5s-28.9-18.8-35.4-21.9s-33-23.7-39-29.7
	s-16.8-7.8-24.5-8s-16.8,6.5-26.3,7.2s-17.3,10.3-20.9,13.2c-2.5,2-4.3,1.7-5.2-0.2c-2.6,2.3-5,4.8-7.3,7.5
	c-7.8,9.7-53,36-57.7,39.9s-20.9,20.1-23.2,40.3s0.4,36.8-13.2,56.1s-63.9,89-63.9,89s17.8,19.7,20.1,28.3s16.3,42.6,20.5,48.8
	s10.8,82.1,5.8,108s-31.3,121.9-31.7,128.5s-1.9,12,5.8,12.8s28.2,3.1,42.6,4.7s27.5,4.6,33.3,11.6s12,62.3,13.9,68.5
	s14.3,41.4,19.8,46.4s15.5,20.9,19.4,22.1s57.3-14.7,64.6-18.2s43.4-28.7,50.3-40.7c5.8-10,28.3-52.3,35.6-66.1
	C1085.8,1219.6,1073.1,1209.5,1065.3,1205.1z"/></g>
  <g class="vks"><path class="st0" d="M797.4,874.5c0,0,50.3-69.7,63.9-89s10.8-36,13.2-56.1s18.6-36.4,23.2-40.3s49.9-30.2,57.7-39.9
	c2.3-2.6,4.7-5.1,7.3-7.5l0,0c-0.5-1.1-0.7-2.2-0.7-3.4c-0.3-4.6,17.3-41,21.4-51.6s-2.6-67.4-5.9-73.5s-28.7-29.7-40-34.8
	s-23.5-3.1-33.9-5.9c-7.3,4.6-52.3,4.3-57.9,6.2s-50.7,49.2-54.6,52.1c0.6,6.6-9.5,58.5-9.9,66.2s-5,44.9-8.1,51.9
	s-68.1,84.4-70.5,91s-30.5,50.7-31.7,60.7s8.5,49.2,8.5,59.2s0.4,11.2,3.1,11.2s20.9-15.1,32.5-18.6s16.3-8.1,27.5-8.9
	s36.4,18.2,41.4,21.7S797.4,874.5,797.4,874.5z"/></g>
  <g class="vks"> <path class="st0" d="M1260.5,1762c13.9-3.1,24.8-19.6,27.3-31s-9.8-25.8-17.6-31.5s-26.8-28.9-33-33.6s-8.8-20.1-6.2-35.6
	s36.1-47.5,44.4-57.8s21.7-19.6,38.2-6.2c4.7,3.8,11.2,7.6,18.1,11c-5.2-11.1-7.9-24.6-9-30.1c-1.6-7.3-5.4-34.4-6.4-48.6
	c-5-1.7-4.8-7.7-9.9-13.9s-8.7-12.4-6.8-16.4s2.1-11.2,0.2-16.1s-4.4-12.8,0.8-16.4s14.9-20.1,12-24.2s-9.9,1-14.3-8.7
	s-10.6-16.4-16.8-14.7s-14.9,8.1-18.2,5.2s-6.6-13.2-2.3-15.1s13-1.2,16.4-2.9s13.7-13,13.7-16.6c0-1.8-0.4-6.2-0.4-10.8
	c-3.7-3.5-88.4-55.7-105.1-61.2s-55-31-63.5-37.2c-4.1-3-14-11.5-24.8-20.4c-7.3,13.8-29.8,56.1-35.6,66.1c-7,12-43,37.2-50.3,40.7
	c-3.1,1.5-14.6,5.2-27,8.9c4.8,5.1,9.1,10.1,11.1,13.2c5.4,8.5,29,60.4,32.1,66.2s-5.4,12-7,18.2s-17,28.3-13.2,41.8
	s8.5,34.8,14.7,44.5c6.2,9.7,33.7,43.4,36.4,48.4s35.6,45.7,41.8,53s30.6,37.6,33.3,43s33.8,60,33.8,60c7.8,1,26.8,13.4,48,23.2
	S1246.6,1765.1,1260.5,1762z"/></g>
  <g class="vks"><path class="st0" d="M913.9,1381.6c-8.1,4.2-51.9,31.3-65,36.8s-31,13.6-32.9,16.3s2.3,7.7,8.9,15.5s36.4,46.4,39.9,53.8
	s26.7,54.2,30.6,65.8s34.1,64.6,37.2,73.9s22.1,75.1,26.7,82.8c3,5.1,5.3,10.5,7.1,16.1c1.5,1,10.3,9.4,16.9,9.8s20.9-3.9,23.6-5.8
	s7.7-5.8,13.2-3.5s17.4,23.6,23.2,26.3s22.1,24,25.5,29c1.2,1.7,3.1,4.5,5.3,7.4c14.8-20.9,54.3-60,62-67.7
	c9.3-9.3,23.7-6.2,31.5-5.2c0,0-31.1-54.6-33.8-60s-27.1-35.6-33.3-43s-39.1-48-41.8-53s-30.2-38.7-36.4-48.4s-10.8-31-14.7-44.5
	s11.6-35.6,13.2-41.8s10.1-12.4,7-18.2s-26.7-57.6-32.2-66.1c-2-3.1-6.3-8.1-11.1-13.2c-16.8,5-35.4,10-37.7,9.3
	C944.1,1359.9,922,1377.3,913.9,1381.6z"/></g>
  <g class="vks"><path class="st0" d="M655.3,910.9c10.1-13.5,24-30.2,27.1-39.9c-2.7,0-3.1-1.2-3.1-11.2s-9.7-49.2-8.5-59.2s29.4-54.2,31.7-60.8
	s67.4-84,70.5-91s7.7-44.1,8.1-51.9s10.5-59.6,9.9-66.2c-3.9,2.9-49.9,58.8-53.8,64.3s-24.6,9.1-35,12.6s-34.5,6.2-44.1,5.8
	s-15.1,2.1-19.5,3.9s-19.2,8.1-26.1,8.9s-14.1-3.1-16.3-5.4s0.2-25,2.5-31.2s0.4-35.8-1.9-43.8s-21.9-30.7-26.4-34.8
	s-8.7-13.9-10.3-14.8c-9.8,12.4-41.3,59.4-43.4,73s1.5,34.6,0,42.3s-19.9,50.3-27.1,57.5s-27.4,26.1-32.3,23.2s-9.5-14.2-19.9-13.4
	s-23,7.7-30.2,19.6s-31.5,48.3-37.7,52.4S348,764.6,348,764.6c4.9,2.8,8.3,87.2,4.4,97.5s-7,76.4-4.6,83.6s17.8,43.9,23.2,48
	c15.7-0.4,18.8-9.7,26.2-11.2s44.9-12.8,55.7-12.8s31.8,9.7,40.6,12s59.6,23.6,73.9,32.5s50.3,37.9,56.1,39.9s8.9,1.9,6.2-9.3
	s-8.1-56.1-2.3-67.7S645.3,924.4,655.3,910.9z"/></g>
  <g class="vks"><path class="st0" d="M812,1196.7c-0.5-2.3-0.1-5.3,0.1-8.6c0.4-6.6,26.7-102.6,31.7-128.5s-1.5-101.8-5.8-108s-18.2-40.3-20.5-48.8
	s-20.1-28.3-20.1-28.3s-8.5-5.8-13.5-9.3s-30.2-22.5-41.4-21.7s-15.9,5.4-27.5,8.9S685.1,871,682.4,871c-3.1,9.7-17,26.3-27.1,39.9
	s-22.1,54.6-27.9,66.2s-0.4,56.5,2.3,67.7c1.6,6.5,1.2,9.2-0.5,10c2.2,2.7,4.7,5.2,7.5,7.3c4.9,3.4,13.2,13.7,14.5,17.8
	s18.1,67.3,21.7,72.5s30.5,20.6,38.5,26.1s63.5,39.2,72.2,41s11.1,2.8,13.7-1.8S812,1196.7,812,1196.7L812,1196.7z"/></g>
  <g class="vks">  <path class="st0" d="M960.8,1729.4c-0.5-1-1.1-2-1.6-2.9c-4.7-7.7-23.6-73.6-26.7-82.8s-33.3-62.3-37.2-73.9s-27.1-58.5-30.6-65.8
	s-33.3-46.1-39.9-53.8s-10.8-12.8-8.9-15.5s19.7-10.8,32.9-16.3s56.9-32.5,65-36.8s30.2-21.7,32.9-27.5c-3.9-1.2-13.9-17-19.4-22.1
	s-17.8-40.3-19.7-46.4s-8.1-61.6-13.9-68.5s-19-10.1-33.3-11.6s-34.8-3.9-42.6-4.7c-3.8-0.4-5.4-1.9-5.9-4.1l0,0
	c0,0-12.1,16.4-14.7,21s-4.9,3.6-13.7,1.8s-64.2-35.6-72.2-41s-34.8-20.9-38.5-26.1c1.3,6.7,2.6,24-2.6,33.3s-35.1,54.4-38.7,61.2
	s-11.3,18.3-14.2,25.6s-3.9,20.9,3.1,28.1s20.9,22.7,31.2,27.9s14.7,10.3,15.2,14.7s-7.5,37.7-5.7,44.6s4.7,10.6,11.6,15
	s25.3,20.6,30.2,24s9,29.7,12.1,36.1s13.7,32.8,18.3,41.3s16,36.1,25,42.1s20.7,11.9,26.6,17.6s10.9,15.2,13.2,23.7s10.8,25,23,32.3
	s20.7,13.4,30.5,15s18.3,2.3,25,7.5s8.5,15.2,19.9,26.1s22.5,23.7,30.5,30.7C933.9,1705,953.5,1723.5,960.8,1729.4z"/></g>
  <g class="vks"><path class="st0" d="M966.3,1742.6c-1.7-5.6-4.1-11-7.1-16.1c0.6,1,1.1,2,1.6,2.9c-7.4-6-27-24.5-33.8-30.4
	c-8-7-19.1-19.9-30.5-30.7s-13.2-20.9-19.9-26.1s-15.2-5.9-25-7.5s-18.3-7.7-30.5-15s-20.6-23.7-23-32.3s-7.2-18.1-13.2-23.7
	s-17.5-11.6-26.6-17.6s-20.4-33.6-25-42.1s-15.2-34.8-18.3-41.3s-7.2-32.8-12.1-36.1s-23.2-19.6-30.2-24c-4.7-3-7.5-5.6-9.4-9.2
	c-5.1,0.8-10.9,1.4-15.6,1.7c-11.3,0.5-77.2,4.4-80.5,4.4s-3.4,3.9,0,7s10.8,9.3,15.5,20.4s15.5,39.5,17,44.9s1.3,13.2,5.9,17
	s31,32,35.6,41.8s22.2,33.8,22.2,37.7s4.4,42.8,6.2,51.3c2.1,0,11.3,5.4,11.6,10.6s3.1,69.4,3.9,75.6s9.5,59.4,9,66.6
	s5.4,33.3,10.3,41s27.1,51.9,31,55.7s13.7,28.6,12.9,43.3s-5.4,52.7-4.9,60.1s-2.3,37.2-3.9,43.6s-3.7,26.8-2.7,29.4
	s5.9-1.2,7.1-3.2s5-10.6,9.8-11.2s8-2.7,12.8-2.8s10.8,6.2,13.3,9.8s3.3,2.8,5,6.2c1.5,3,3.8,3.8,4.2,6c-0.2-3-0.5-10.7-1.5-15
	c-1.3-5.9,7.7-9.6,11.9-9.6s15-1.3,19.1-0.2s10.6,3.6,11.3,6.2s2.8-3.4,8.8-3.4s15.7,4.1,16.8,7.8s2.6,6.7,5.4,5.9s5.9-1.6,5.9-3.9
	s-2.3-9.6-1.8-10.8s1.8-4.1-1.3-6.4s-10.6-4.1-12.4-3.6s1.5-6.7,4.1-10.6s14.7-41,15.7-51.4s-2.3-84.1-4.6-93.2s-9.3-47.2-9.5-55
	s-0.3-52.1,1.3-56.5s1-12.1,8.5-12.4s11.9,5.7,15.5,5.4s12.9,2.6,15.7,6.7s21.4,21.9,26.1,27.6s10.8,18.1,13.2,23.2
	s5.7,9.8,13.7,8.3s16.8-5.2,17.8-10.6s1-28.4,0-37.2S964.7,1741.6,966.3,1742.6z"/></g>
  <g class="vks"><path class="st0" d="M605.8,1063.8c-4.7,7.4-18.2,25.6-19.4,31.7s-12.8,50.7-17,60s-19.7,42.2-41.8,66.6s-28.7,44.9-27.1,56.9
	s2.3,58.8,3.9,73.5s3.9,20.1,11.2,25.6s49.2,23.6,49.2,23.6l0,0c0.1-1.2,1.2-2.1,2.4-2c0,0,0.1,0,0.1,0c3.3,0,69.2-3.9,80.5-4.4
	c4.7-0.2,10.5-0.9,15.6-1.7c-1-1.8-1.7-3.8-2.2-5.8c-1.8-7,6.2-40.3,5.7-44.6s-4.9-9.6-15.2-14.7s-24.3-20.7-31.2-27.9
	s-5.9-20.9-3.1-28.1s10.6-18.8,14.2-25.6s33.5-51.9,38.7-61.2s3.9-26.6,2.6-33.3c-3.6-5.2-20.4-68.4-21.7-72.5s-9.5-14.4-14.5-17.8
	c-2.8-2.1-5.3-4.6-7.5-7.3l0,0c-1.3,0.6-3.2,0.1-5.7-0.7C618.5,1054.9,610.4,1056.4,605.8,1063.8z"/></g>
  <g class="vks"><path class="st0" d="M507.8,1368.8L507.8,1368.8c-1.7-3.9-2.7-8.9-3.4-16.2c-1.5-14.7-2.3-61.5-3.9-73.5s5-32.5,27.1-56.9
	c22.1-24.4,37.5-57.3,41.8-66.6s15.9-53.8,17-60s14.7-24.4,19.4-31.7s12.8-8.9,17.8-9.7c-5.8-1.9-41.8-31-56.1-39.9
	s-65-30.2-73.9-32.5s-29.8-12-40.6-12s-48.4,11.3-55.7,12.8s-10.5,10.8-26.2,11.2c5.4,4.1-17.3,87-24.5,92.1s-15.2,38.2-20.6,40
	c-2.2,0.9-4.5,1.6-6.8,2.2c0.1,2.5,0.1,5.1-0.1,7.6c-1.3,12.4-6.7,29.4-16.5,40.8s-53.2,58.3-55.7,59.9s22.7,29.9,28.1,35.9
	s17.3,12.9,20.6,19.9s34.2,54.7,39.6,59.3s8.2,10.1,20.6,5.2s34.6-12.9,41.5-16.8s20.6-10.6,33.8-9.3s18.6-1.3,25.8,3.6
	s27.4,18.6,37.4,23S507.8,1368.8,507.8,1368.8z"/></g>
  <g class="vks"><path class="st0" d="M669.8,1619.8c-1.8-8.5-6.2-47.5-6.2-51.3s-17.5-27.9-22.2-37.7s-31-37.9-35.6-41.8s-4.4-11.6-5.9-17
	s-12.4-33.8-17-44.9s-12.1-17.3-15.5-20.4c-1.8-1.7-2.6-3.5-2.5-4.9l0,0c0,0-41.9-18.2-49.2-23.6c-3.4-2.3-6.1-5.5-7.8-9.3l0,0
	c0,0-3.7-7.3-13.8-11.7s-30.2-18.1-37.4-23s-12.6-2.3-25.8-3.6s-26.8,5.4-33.8,9.3c-5.2,2.9-19,8.1-30.8,12.6
	c-1.6,6.4-2.8,12.3-3,15.5c-0.5,9.8-0.5,46.7,0,54.2s15.5,49,13.9,60.4s-6.5,59.1-17.3,76.1s-31.2,72.8-33,77.4
	c2.8,6.4,0.5,65.8-5.2,78.2s-7.7,18.1-7.2,20.9s10.1,8.8,12.9,14.4s11.6,23.2,12.6,28.9s0.3,20.1,2.8,24.8s6.2,4.4,8.3,3.1
	s7.2-17.6,12.4-19.1s9.8-4.6,14.2-5.9s12.4-1.6,17.8,4.1s17.5,16.3,21.9,17s16.3-4.1,24-11.6s15-13.2,23.7-14.4s42.8-19.6,49.8-22.7
	s32.3-21.2,36.9-21.2s32-1.3,40-3.9s30.5-19.6,32.3-26.3s29.2-60.1,32.3-65S667.7,1619.8,669.8,1619.8z"/></g>
  <g class="vks"><path class="st0" d="M222.8,1456.4c-4.4-3.4-11.1-0.8-24.8-0.8s-39-9.6-45.7-12.4s-21.7-4.6-27.1-4.9s-20.9,10.1-23.2,14.4
	s-9.5,4.9-16.8,2.3c2.6,3.3-4.1,19.9-8.3,23S66.2,1490,51.7,1490s-34.3,5.4-41.8,19.9s-6.2,24.5,0,32.3s9,10.6,5.9,12.7
	s-9.6,4.9-9.3,9s2.6,20.1,5.9,25s1.5,22.4,5.2,26.6s3.6,22.7-2.1,27.6s-1.6,7-1.6,10.6s-1,13.4-9.3,14.2s-3.6,7.5-1.8,9.6
	s3.1,12.6,4.1,17s1,9.8,5.4,10.8s22.7,3.1,32.2,3.1s42.3-3.4,48-7s33.8-16.5,44.9-47.5s23.5-55,29.7-56c0,0,20.9-25.3,31.8-32.8
	s21.9-27.1,24.8-33.8s4.9-26.6,6.2-45.4S227.2,1459.8,222.8,1456.4z"/></g>
  <g class="vks"> <path class="st0" d="M363.2,1422.1c-0.5-7.5-0.5-44.4,0-54.2c0.2-3.2,1.4-9.1,3-15.5c-3.9,1.5-7.6,2.9-10.8,4.2
	c-12.4,4.9-15.2-0.5-20.6-5.2s-36.2-52.4-39.6-59.3s-15.2-13.9-20.6-19.9s-30.7-34.3-28.1-35.9s45.9-48.5,55.7-59.9
	s15.2-28.4,16.5-40.8c0.2-2.5,0.3-5.1,0.1-7.6c-4.6,1.2-9.5,0.4-13.5-2.2c-8.5-5.2-35.4-5.2-46.7,1.3s-35.9,15.2-43.9,33
	s-33.6,42.8-48,50.6s-32.8,27.1-33.8,33.6s-6.4,16.5,5.9,22.4s18.8,9.8,18.3,13.2s-4.6,11.6-17,11.6s-36.4-6.2-45.2,0
	s-23.8,8-20.7,24.5s5.7,19.9,8.5,22.2s12.4,7.7,8.8,27.4s-3.6,26.8-7.2,34.6s-1.8,51.6,0.8,55c7.2,2.6,14.4,2.1,16.8-2.3
	s17.8-14.7,23.2-14.4s20.4,2.1,27.1,4.9s32,12.4,45.7,12.4s20.4-2.6,24.8,0.8s8.5,10.6,7.2,29.4s-3.4,38.7-6.2,45.4
	s-13.9,26.3-24.8,33.8s-31.8,32.8-31.8,32.8c6.2-1,13.9-4.9,19.9-1.5s16.5,22.7,22.2,29.4s9.3,14.2,14.4,16.5s13.9,6.2,20.1,4.4
	s31.7-17.6,44.1-16.3s35.9-0.8,38.7,5.7c1.8-4.7,22.2-60.4,33-77.4s15.7-64.8,17.3-76.1S363.7,1429.6,363.2,1422.1z"/></g>
</svg>

</template>

<script>
export default {
  data() {

  },
  methods: {
    select(i) {

    }
  }
}
</script>

<style lang="less" scoped>
.vks {
  fill: url(#linear);
  cursor: pointer;
  path {
    stroke-width: 10px;
    stroke: #FFFFFF;

  }
}

.vks:hover {
  fill: url(#linear-hover);
  box-shadow: 0px 2px 32px rgba(14, 48, 199, 1);
  path {
    stroke: #0E30C7;
  }
}
</style>