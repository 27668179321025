import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from '@/store'
const routes = [
    {
        path: "/",
        name:'index',
        component: ()=>import('@/views/index/index'),
    },
    {
        path: "/ytLogin",
        name: "ytLogin",
        component: ()=>import('@/views/login/ytLogin'),
    },
    {
        path: "/nav",
        name: "nav",
        component: ()=>import('@/views/productSafe/layout/nav'),
    },
    {
        path: "/admin",
        name: "admin",
        component: ()=>import('@/views/productSafe/layout/index'),
        children: [
            {
                path: '/setting',
                name: 'setting',
                component: () => import('@/views/productSafe/modular/system/user/setting'),
            },
        ]
    },
    {
        path: "/townData",
        name: "townData",
        redirect: 'industry',
        component: ()=>import('@/views/townData/index/index'),
        meta:{
            keepAlive: false,
        },
        children: [
            {
                path: '/industry',
                name: 'industry',
                component: () => import('@/views/townData/industry/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/brand',
                name: 'brand',
                component: () => import('@/views/townData/brand/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/regulatory',
                name: 'regulatory',
                component: () => import('@/views/townData/regulatory/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/rules',
                name: 'rules',
                component: () => import('@/views/townData/rules/index'),
                meta:{
                    keepAlive: false,
                }
            },
        ]
    },
    {
        path:'/countyData',
        name:'countyData',
        redirect: 'dataRegulatory',
        component: ()=>import('@/views/countyData/index/index'),
        meta:{
            keepAlive: false,
        },
        children: [
            {
                path: '/dataRegulatory',
                name: 'dataRegulatory',
                component: () => import('@/views/countyData/regulatory/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataCredit',
                name: 'dataCredit',
                component: () => import('@/views/countyData/credit/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataHgz',
                name: 'dataHgz',
                component: () => import('@/views/countyData/hgz/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/production',
                name: 'production',
                component: () => import('@/views/countyData/production/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataCheck',
                name: 'dataCheck',
                component: () => import('@/views/countyData/check/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataSupervise',
                name: 'dataSupervise',
                component: () => import('@/views/countyData/supervision/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataTownNavbar',
                name: 'dataTownNavbar',
                component: () => import('@/views/countyData/townNavbar/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataMoney',
                name: 'dataMoney',
                component: () => import('@/views/countyData/money/index'),
                meta:{
                    keepAlive: false,
                }
            },
        ]
    },
    {
        path: "/spjkgl",
        name: "spjkgl",
        component: ()=>import('@/views/equipShow/spjkgl'),
    },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

let flag = false
router.beforeEach(async (to, from, next)=>{
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.name === 'admin') {
        let adminRoute = JSON.parse(JSON.stringify(store.getters.getData('vuex_routes')))
        if(adminRoute && adminRoute.length > 0) {
            adminRoute.forEach(item=>{
                router.addRoute('admin', {
                    path: item.path,
                    name: item.name,
                    component: ()=>import('@/views'+item.component)
                })
            })
        }
        flag = true
        next()
    } else if (!flag) {
        let adminRoute = JSON.parse(JSON.stringify(store.getters.getData('vuex_routes')))
        if(adminRoute && adminRoute.length > 0) {
            adminRoute.forEach(item=>{
                router.addRoute('admin', {
                    path: item.path,
                    name: item.name,
                    component: ()=>import('@/views'+item.component)
                })
            })
            flag = true
            next({
                ...to,
                replace: true
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export function resetRouter(router) {

}

export default router
