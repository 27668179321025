<template>
  <div>
    <el-dialog v-model="visible" :title="title" :append-to-body="true">
      <div class="padding-30">
        <el-form size="large" ref="a_form" :model="formData" :rules="rules" :label-width="labelWidth ? labelWidth : '100px'" label-position="right">
          <el-row :gutter="20">
            <template v-for="(item,index) in formConfig" >
              <el-col :span="item.span ? item.span : 24">
                <el-form-item :label="item.label" :prop="item.prop" >

                  <el-input v-if="item.type === 'text'" v-model="formData[item.prop]" maxlength="100" />

                  <el-input v-if="item.type === 'autoComplete'"  v-model="formData[item.prop]"  maxlength="100" />

                  <el-select filterable v-if="item.type === 'select'"  v-model="formData[item.prop]" style="width: 100%" placeholder="请选择" @change="(e)=>handleSelectChange(e,item)">
                    <el-option v-for="(option,idx) in item.selectData" :key="idx" :label="option[item.props.label]" :value="option[item.props.value]"></el-option>
                  </el-select>

                  <el-select filterable multiple v-if="item.type === 'selectMultiple'"  v-model="formData[item.prop]" style="width: 100%" placeholder="请选择" @change="(e)=>handleSelectChange(e,item)">
                    <el-option v-for="(option,idx) in item.selectData" :key="idx" :label="option[item.props.label]" :value="option[item.props.value]"></el-option>
                  </el-select>

                  <AtCheckBox v-if="item.type === 'checkBox'"  v-model="formData[item.prop]" :optionList="item.selectData" :props="item.props"></AtCheckBox>

                  <el-tree-select v-if="item.type === 'treeSelect'" :props="item.props" :render-after-expand="false" :node-key="item.id"
                                  @nodeClick="(node,e2,e3) => treeSelected(node,e2,e3, item.prop, index)" v-model="formData[item.prop]" placeholder="请选择"
                                  :check-strictly="true" :data="item.selectData" style="width: 100%" >
                  </el-tree-select>

                  <el-input-number v-if="item.type === 'number'" :min="0" v-model.number="formData[item.prop]" style="width: 100%;" />

                  <div v-if="item.type === 'numberUnit'" class="flex align-center">
                    <el-input-number  :min="0" v-model.number="formData[item.prop]" />
                    <div v-if="item.unit" style="font-family: Alibaba-PuHuiTi-Bold;color: #000000;margin-left: 20px">{{$filter.getDictLabel(item.dict,item.code)}}</div>
                    <div v-if="item.unitLabel" style="font-family: Alibaba-PuHuiTi-Bold;color: #000000;margin-left: 20px">{{item.unitLabel}}</div>
                  </div>

                  <el-input v-if="item.type === 'textarea'" v-model="formData[item.prop]" :rows="2" type="textarea"/>

<!--                  <HwOneImage v-if="item.type === 'img_single'" v-model="formData[item.prop]"></HwOneImage>-->

<!--                  <HwMoreImages v-if="item.type === 'imgs'" v-model="formData[item.prop]"></HwMoreImages>-->

                  <HwMoreFiles v-if="item.type === 'video'"  v-model="formData[item.prop]"></HwMoreFiles>


                  <el-upload v-if="item.type === 'img_single'" accept='image/png,image/jpeg' class="img-upload" :action="$store.state.vuex_uploadFileUrl" name="file" :data="{tenantId : $store.getters.getData('vuex_tenantId')}"
                             :show-file-list="false" :on-success="(e)=>handleAvatarSuccess(e,item)" :before-upload="beforeAvatarUpload">
                    <img v-if="formData[item.prop]" :src="$store.state.vuex_previewUrl + formData[item.prop]" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                  </el-upload>

                  <el-upload v-if="item.type === 'imgs' " accept='image/png,image/jpeg' class="img-upload" v-model:file-list="fileObject[item.prop]" :action="$store.state.vuex_uploadFileUrl"
                             name="file" :multiple="true" :data="{tenantId : $store.getters.getData('vuex_tenantId')}"
                             list-type="picture-card" :on-remove="(e)=>handleImgsSuccess(e,item)" :on-success="(e)=>handleImgsSuccess(e,item)" :before-upload="beforeAvatarUpload" :on-preview="handlePictureCardPreview">
                    <el-icon><Plus /></el-icon>
                  </el-upload>

<!--                  <el-upload v-if="item.type === 'video'" multiple accept='audio/mp4,video/mp4'  class="upload-demo" drag name="file" :data="{tenantId : $store.getters.getData('vuex_tenantId')}"-->
<!--                             :action="$store.state.vuex_uploadFileUrl" :on-success="(e)=>handleAvatarSuccess(e,item)"-->
<!--                  >-->
<!--                    <el-icon class="el-icon&#45;&#45;upload"><upload-filled /></el-icon>-->
<!--                    <div class="el-upload__text">-->
<!--                      拖拽视频文件 <em>点击上传</em>-->
<!--                    </div>-->
<!--                  </el-upload>-->

                  <table-select ref="xselect"  @change="(e)=>handleTableChange(e,item)" v-if="item.type === 'tableSelect'" :title="item.label" :single="item.single"
                                  :columns="item.columns" :api="item.tableApi" :listApi="item.listApi" :searchConfig="item.searchConfig"
                                :width="item.width" :props="item.props">
                  </table-select>

                  <el-date-picker v-if="item.type === 'datePicker'"  v-model="formData[item.prop]"
                                  style="width: 100%" type="date" placeholder="请选择日期" />
                  <editor v-if="item.type === 'editor'" @onchange="(e)=>editorChange(e,item)" ref="editor"></editor>
                </el-form-item>
              </el-col>
            </template>
          </el-row>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visible = false">取消</el-button>
          <el-button type="primary" @click="save">保存</el-button>
        </span>
      </template>
    </el-dialog>



  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus'
import Editor from '@/components/editor/index'
import TableSelect from '@/components/tableSelect/index'
import HwOneImage from '@/components/HwOneImage/index'
import HwMoreImages from '@/components/HwMoreImage/index'
import HwMoreFiles from '@/components/HwMoreFiles/index'
import AtCheckBox from '@/components/checkbox/index'
export default {
  components:{ TableSelect, Editor, HwOneImage, HwMoreFiles, HwMoreImages, AtCheckBox},
  props:{
    api:{
      default: '',
      type: String,
    },
    formConfig:{
      default: [],
      type: Array,
    },
    rules:{
      type: Object
    },
    defaultData:{
      type: Object
    },
    labelWidth:{
      type: String
    }
  },
  data() {
    return {
      visible: false,
      title:'',
      formData: {},
      imgVisible: false,
      dialogImageUrl: '',
      fileObject:{},
      hwUploadParam: {},
    }
  },
  computed:{

  },
  created() {

  },
  methods: {
    getPoint(e){
      this.formData.address = e.address
      this.formData.longitude = e.longitude
      this.formData.latitude = e.latitude
    },
    editorChange(html,item) {
      this.formData[item.prop] = html
    },
    handleSelectChange(e,val){
      if(!val.toProp) {
        return
      }
      this.formData[val.toProp] = ''
      this.formConfig.forEach(item=>{
        if(item.prop === val.toProp) {
          let param = {}
          param[val.targetParam] = this.formData[val.prop]
          item.params = Object.assign(param)
          this.$axios.post(item.api,item.params ? item.params : {}).then(res=>{
            if (item.type === 'treeSelect') {
              if (item.pObj) {
                item.selectData = []
                item.pObj[item.props.children] = res.data
                item.selectData.push(item.pObj)
              } else {
                item.selectData = res.data
              }
            } else {
              if (item.pObj) {
                item.selectData = res.data.unshift(item.pObj)
              } else {
                item.selectData = res.data
              }
            }
          })
        }
      })
    },
    handlePictureCardPreview(e){
      this.imgVisible = true
      this.dialogImageUrl = e.url
    },
    show(data){
      this.formData = Object.assign({})
      if (data && data.id) {
        this.title = '修改'
        this.visible = true
        this.setData()
        this.$nextTick(()=>{
          this.$refs.a_form.resetFields()
          this.formData = Object.assign({},data)
          this.setXCData()
        })
      } else {
        this.title = '新增'
        this.visible = true
        this.setData()
        this.$nextTick(()=>{
          this.$refs.a_form.resetFields()
          this.setXCData()
        })
      }
      // this.getHwUploadParam()
    },
    getHwUploadParam(){
      this.$axios.get('/file/file/getHuaweiSignature').then(res=>{
        this.hwUploadParam = res.data
      })
    },
    showForm(title){
      this.title = title
      this.visible = true
      this.setData()
      this.$nextTick(()=>{
        this.$refs.a_form.resetFields()
        this.setXCData()
      })
    },
    setXCData(){
      let tableSelectIndex = 0
      let editorIndex = 0
      this.formConfig.forEach(item=>{
        if(item.type === 'tableSelect') {
          this.$refs.xselect[tableSelectIndex].setDefaultData(this.formData[item.prop])
          tableSelectIndex ++
        }
        if(item.type === 'editor') {
          if(this.formData[item.prop]) {
            this.$refs.editor[editorIndex].initEditor(this.formData[item.prop])
          } else {
            this.$refs.editor[editorIndex].initEditor("")
          }
          editorIndex ++
        }
        if(item.type === 'imgs') {
          this.fileObject[item.prop] = []
          if(this.formData[item.prop]) {
            let arr = this.formData[item.prop].split(',')
            arr.forEach(k=>{
              let imgObj = {
                response:{
                  data:k
                },
                url:this.$store.state.vuex_previewUrl + k
              }
              this.fileObject[item.prop].push(imgObj)
            })
          }
        }
      })
    },
    setData(){
      this.formConfig.forEach(item=>{
        if (item.defaultValue) {
          this.formData[item.prop] = item.defaultValue
        }
        if (item.api) {
          if(item.formProp) {
            if(!this.formData[item.formProp]){
              item.selectData = []
              return
            }
            let param = {}
            param[item.targetParam] = this.formData[item.formProp]
            item.params = Object.assign(param)
          }
          this.$axios.post(item.api,item.params ? item.params : {}).then(res=>{
            if (item.type === 'treeSelect') {
              if (item.pObj) {
                item.selectData = []
                item.pObj[item.props.children] = res.data
                item.selectData.push(item.pObj)
              } else {
                item.selectData = res.data
              }
            } else {
              if (item.pObj) {
                item.selectData = res.data.unshift(item.pObj)
              } else {
                item.selectData = res.data
              }
            }
          })
        }
        if (item.dict) {
          if (item.pObj) {
            item.selectData = this.$filter.getDictData(item.dict).unshift(item.pObj)
          } else {
            item.selectData = this.$filter.getDictData(item.dict)
          }
        }
        if (item.type === 'imgs') {
          this.fileObject[item.prop] = []
        }
      })
    },
    save(){
      this.$refs.a_form.validate((valid, fields)=>{
        if (valid) {
          const loading = ElLoading.service({
            lock: true,
            text: '保存中...',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          this.formData = Object.assign(this.formData,this.defaultData)
          if (this.title === '新增') {
            this.$axios.post(this.api + '/add',this.formData).then(res=>{
              loading.close()
              if (res.code === 200) {
                this.visible = false
                this.$emit("refresh")
                ElMessage({
                  message: "新增成功",
                  type: 'success',
                })
              } else {
                ElMessage({
                  message: res.message,
                  type: 'error',
                })
              }
            })
          } else if (this.title === '修改'){
            this.$axios.post(this.api + '/update',this.formData).then(res=>{
              loading.close()
              if (res.code === 200) {
                this.visible = false
                this.$emit("refresh")
                ElMessage({
                  message: "修改成功",
                  type: 'success',
                })
              } else {
                ElMessage({
                  message: res.message,
                  type: 'error',
                })
              }
            })
          } else {
            this.$axios.post(this.api,this.formData).then(res=>{
              loading.close()
              if (res.code === 200) {
                this.visible = false
                this.$emit("refresh")
                ElMessage({
                  message: "操作成功",
                  type: 'success',
                })
              } else {
                ElMessage({
                  message: res.message,
                  type: 'error',
                })
              }
            })
          }
        }
      })
    },
    treeSelected(node,e2,e3,prop,index){
      this.formData[prop] = node.id
      if (this.formConfig[index].pids) {
        this.formData[this.formConfig[index].pids] = this.getPids(e2)
      }
    },
    getPids(node){
      if (node.parent){
        return (this.getPids(node.parent) ? this.getPids(node.parent) + ',' : '') + node.data.id
      } else {
        return ""
      }
    },
    handleImgsSuccess(e,item){
      this.formData[item.prop] = this.fileObject[item.prop].map(item=>{
        return item.response.data
      }).join(',')
    },
    handleAvatarSuccess(e,item){
      this.formData[item.prop] = e.data
    },
    handleTableChange(e,item){
      this.formData[item.prop] = e
    },
    beforeAvatarUpload(file){
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        ElMessage.error('请选择图片!')
        return false
      } else if (file.size / 1024 / 1024 > 10) {
        ElMessage.error('文件大小不要超过10MB!')
        return false
      }
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.img-upload .el-upload)  {
  border: 1px dashed #C4C4C4;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

:deep(.img-upload .el-upload:hover) {
  border-color: var(--el-color-primary);
}

:deep(.el-icon.avatar-uploader-icon) {
  font-size: 28px;
  color: #8c939d;
  width: 160px;
  height: 160px;
  text-align: center;
}
.avatar {
  width: 160px;
  height: 160px;
}
</style>
