<template>
  <div>
    <svg width="574" height="1000" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="linear-hover" x1="100%" y2="100%">
          <stop offset="0%" style="stop-color: #7285FD"></stop>
          <stop offset="100%" style="stop-color: #0E30C7"></stop>
        </linearGradient>
        <linearGradient id="linear" x1="100%" y2="100%">
          <stop offset="0%" style="stop-color: #7285FD"></stop>
          <stop offset="100%" style="stop-color: #90B2FC"></stop>
        </linearGradient>
      </defs>
      <g id="Layer_1">
        <g class="vks">
          <path d="m47.15442,57.92502c-2.65,6.71 0.51,6 2.71,7.48s-2.26,8.58 4.71,9.23s7.61,8.77 9,11.35s16.58,11.68 19.72,13.23s10.58,6.65 5.41,14.45s-0.64,14.45 -1.48,14.65s-12.39,11.87 -10.58,14.9s0.26,7 0.39,9.55s1.93,13.87 0,15.16s0.38,-0.39 -1.87,4.39c-4.84,3.93 -7.23,13.61 -5.87,17.48s4.9,3 5.67,5.48a4.07,4.07 0 0 1 0.17,1.56a105.36,105.36 0 0 1 11.02,-0.46c4.9,0.26 15.48,-0.13 18.84,3c7.87,1.29 11.61,7.1 14.71,8.26s16.38,6.19 18.58,8.38s7.22,11.88 8.77,14.2s5.68,12.26 7.36,14.84s6.06,5.16 8.51,5.67c1,-2.19 1.68,-5.54 3,-8.9s2.32,-6.8 4.39,-7l0,-6.55c0,-1.16 2.58,-4.51 4.26,-6.32s2.58,-9 2.19,-11.36s0,-11.22 0,-14.06s-1.68,-4.13 -3,-5.29s-4.51,-2.45 -5.93,-4.39c-0.65,-1.8 -4.65,-11 -5.55,-12.51s-5,-4.52 -5.81,-7.75s-5.93,-7.35 -8.39,-8.51s-11.48,-6.32 -13.16,-7.1s-12.64,-4.77 -12.64,-5.42s-4,-5.8 -6.71,-6.32a44.46,44.46 0 0 0 1.82,-4.83l-0.14,-0.07c-7.36,-6.84 -6.84,-18.45 -5.42,-20.52s4,-4.13 6.06,-8.26c4.81,-3.51 11.26,-27.38 11.26,-29.19a18.75,18.75 0 0 1 0.78,-6.1c0.65,-1.55 3.48,-6.58 3.48,-8s0.52,-8.52 0.52,-8.52c-6.71,0.39 -10.71,-2.06 -11.48,-2.84s-1.81,-4.51 -4.78,-5.42c-2.06,-2.71 -9.68,-3.35 -10.84,-7.87c-3.35,-11.06 -12.13,-6.8 -19.48,-5.35s-19.2,-6.09 -22.32,-11.25c-1.15,-1.91 -3.88,-3.06 -6.82,-3.75c-1.39,1.78 -4.82,3 -4.67,6.59c0.2,4.64 -0.38,9 -0.45,12.77c1.87,3.64 2.26,11.1 1.55,13.74s-4.84,0.84 -7.49,7.55z"
          />
        </g>
        <g class="vks">
          <path d="m275.27991,113.34314c-7,-7.31 -4.16,-22.07 3.73,-21s10.06,0 10.11,-1.4s0.39,-6.29 5.47,-6.29s5.42,-2.23 5,-3.92s-2.8,-4 -4.79,-6.24s-8.08,-0.49 -7.59,-4.31s-2.5,-11.42 -4.44,-13.81s-8.39,-3.48 -10.19,-6.06c-3.42,-7.49 -9.42,-4.58 -10.2,-4.45s-4.71,5.09 -7.09,9.09c-1.42,6.78 -0.07,10.78 -1.49,10.65s-11.09,-1.23 -7.8,1.55s8,9.22 2.26,14.64c-11.68,2.58 -7,3.23 -14.39,9.62s-7,-5.1 -7,-6.39s-2.46,-5.42 -3.36,-1.74s-2.84,3.16 -4.26,1.74s-1.16,-6.78 -0.06,-8.39s6.77,-1.81 6.9,-4.52s-2.84,-4.77 -6.84,-7.35s-5.8,-4.26 -5.42,-8s1.16,-2.77 0.84,-11.48s-8.71,-4.78 -9.68,-8.07s-2.85,-10.25 -7.71,-10.87s-6.58,5.74 -8,8.13s-4.84,7 -8.26,6.71s-5.52,-5.32 -7.74,-3.84c-0.77,3.8 -6.51,3.67 -12.45,-0.26s-12.26,-0.58 -15,-0.07c-2.55,0.49 -9.55,3.19 -10.55,1.87l0,0c-0.51,0.51 -0.6,1.19 -1,3s0,11.76 0,11.76s-0.52,7.09 -0.52,8.51s-2.84,6.46 -3.48,8a18.54,18.54 0 0 0 -0.78,6.07c0,1.8 -6.45,25.68 -11.22,29.16c-2.14,4.16 -4.72,6.22 -6.14,8.29s-1.93,13.67 5.42,20.51c6.71,3.75 21.55,7.87 32.26,8s22.32,-1.29 27,0s11.48,2.84 25.29,3.1c5,-1 9,0.52 10.06,1.42s13.81,8.52 17,9.68a64.55,64.55 0 0 1 9.29,4.9c1.29,0.77 5.55,0.52 6.84,-1.16s6.45,-5.16 9.94,-13.29s4.38,-11.87 5.93,-14.32s3.62,-11.23 16.91,-10.71a52.34,52.34 0 0 0 6.17,-0.09a88.43,88.43 0 0 1 -8.97,-8.37z"
          />
        </g>
        <g class="vks">
          <path d="m262.71876,158.74988c-0.92,1.12 -2.32,0.25 -2.08,-1.69s1,-4.5 -0.48,-6.82c-1.28,-2 -2.8,-3.13 -4.7,-3.17c0,0.09 -0.08,0.18 -0.13,0.28c-3.48,8.13 -8.64,11.61 -9.93,13.29s-5.55,1.94 -6.84,1.16a64.55,64.55 0 0 0 -9.29,-4.9c-3.23,-1.16 -16,-8.78 -17,-9.68s-5.06,-2.47 -10.1,-1.47c-13.8,-0.26 -20.64,-1.8 -25.29,-3.1s-16.25,0.13 -27,0s-25.31,-4.18 -32.08,-7.9a44.46,44.46 0 0 1 -1.82,4.83c2.71,0.51 6.71,5.68 6.71,6.32s11,4.65 12.64,5.42s10.71,5.94 13.17,7.1s7.61,5.33 8.38,8.51s4.91,6.2 5.81,7.74s4.9,10.71 5.55,12.52c1.42,1.94 4.64,3.23 5.93,4.39s3,2.45 3,5.29s-0.39,11.74 0,14.06s-0.54,9.55 -2.19,11.36s-4.26,5.16 -4.26,6.32l0,6.58a26.69,26.69 0 0 1 6.54,0c4.56,0.39 15.4,1.55 18.49,2.19s13.1,1.13 16.78,1.71s16.74,-0.48 20,-2.12s10.55,-2.33 12.58,-2.71s4.45,-1 9.1,-4.84s8.22,-12.2 8.32,-19.36s0.48,-3.68 0,-5.9c-1.87,-2.53 -4.16,-9.58 -3.68,-15.78s1.07,-14.51 0.78,-17.32s4.64,-9.09 6,-9.43s1.64,-1.26 1.2,-2.23s-3.19,-1.79 -4.11,-0.65z"
          />
        </g>
        <g class="vks">
          <path d="m154.2306,245.19746c-1.67,-2.58 -5.8,-12.52 -7.35,-14.84s-6.58,-12 -8.78,-14.19s-15.48,-7.23 -18.58,-8.39s-6.83,-6.97 -14.71,-8.26c-3.35,-3.09 -13.93,-2.71 -18.83,-3a105.54,105.54 0 0 0 -11.07,0.49l0,0a6.2,6.2 0 0 1 -2.35,3.87c-1.16,1 -3.94,1.67 -3.81,4.25c4.58,6.2 4.45,14.91 1,17.62s-12.07,3.35 -12.07,3.35s-4.09,14.26 -10.54,12.07s-8.78,0.32 -11.55,3.61a15.48,15.48 0 0 1 -10.78,4.9c-2.77,-0.16 -3.67,3.55 -4.77,4.58s-1,6.71 0.58,10.07c4.84,3.22 2,2.13 5.81,9.61s-0.84,7.29 1.87,13.1s5.87,4.84 6.19,4.45s1.87,-1.68 4,-9.55s8.13,-5 12.65,-2.39s10.77,14.39 12.32,17.97s1.42,6.78 2.19,7.68s1.16,-0.13 4,-1.1s3.16,5.3 5.1,6.26s1.68,-3.64 7.1,-7.55c5.61,-2.83 1,-5.09 2.71,-7.29c5.61,0.39 6.84,-7.8 8.19,-11s4.26,-2.26 4.13,-4.51s-1.61,-6 1.55,-7s4.38,-7.36 4.77,-8.45s0.52,-7.68 4.52,-7s4.32,1.29 7.29,1.87s4.51,3.16 6.45,9.29a18.38,18.38 0 0 0 5.81,9.1c1.16,1.29 5,0.38 7,0.38c0,0 2.39,0.71 3.74,-0.38s5.74,-2.52 6.77,-3s5.75,-3.16 5.88,-5s1.48,-3.87 3.61,-6.2s5.42,-0.38 6.84,0.33s4.45,6.9 6.06,7.35s4.26,0.26 4.52,-1.61c-1.36,-1 -5.81,-8 -7.81,-10.45a6.43,6.43 0 0 1 -1.16,-5.42c-2.42,-0.46 -6.81,-3.04 -8.49,-5.62z"
          />
        </g>
        <g class="vks">
          <path d="m260.89498,411.1786c1.5,-1.16 0.74,-12.78 5,-16.55a32.05,32.05 0 0 1 4.49,-3.4c-1.74,-3.29 -4.06,-9 -5.22,-20.5s1.26,-15.87 2.13,-17.13a86.79,86.79 0 0 0 5,-11c0.29,-1.36 3,-4.26 4.84,-5.9a88.3,88.3 0 0 1 7.26,-5.13c-0.39,-0.2 -0.78,-0.49 -1.16,-1.75s0.37,-21.13 0.65,-23.54s-1.65,-1.36 -2.42,-1.36s-9.1,4 -10.26,4.75s-9.68,5.22 -10.93,5.7s-4.07,3.78 -6.29,4.26s-7.17,4.74 -8.13,5.71s-7.36,3.87 -9.78,3.58s-18.47,-0.87 -18.87,-0.48s-0.68,9.19 -0.68,9.19l-3.09,0c-2.23,-1.16 -7.94,0 -11.42,-1.45a7.57,7.57 0 0 0 -4.18,-0.35c0.62,3.1 -1.44,6.73 -2,10.87c-1,6.58 5,11 4.39,11.55s-2.13,3.16 -0.32,4.83s3.49,1.61 5.37,1.61s1.48,3.48 2.83,4.38s-1.61,3.1 -0.38,4.65c2.13,4.45 -0.33,12.52 -0.07,15.55s-4,5.74 -5,4.19s-0.74,-1.77 1.33,-7.77s-2.59,-5.81 -4.65,-5.81s-4.84,6.52 -7.16,6.39s-5.29,-1.87 -6.32,-1.87s-2.58,5 -4.2,8.9s-5.22,5 -6.38,4.58s-4.71,-1.29 -7,0.39s-3.67,2.84 -4,7.1s-6.38,2.9 -8.32,3.35a40.26,40.26 0 0 0 -6.06,2.58c-5.55,0.26 -6.2,0.07 -9.55,1.62a65.51,65.51 0 0 0 -10.95,6.77c-1.67,1.48 0.84,2.84 -0.19,5.81a3.69,3.69 0 0 1 -0.6,1.07a21.36,21.36 0 0 1 2.54,1.18c2.71,1.55 7.22,5.94 8.13,8s1.33,9.72 1.93,11.94s1.68,11.09 -1.42,14.7s-1.29,5.66 -1.55,6.3s-1.69,2.05 -0.58,3.88a10.72,10.72 0 0 0 2.81,3.25c0.68,0.48 2.32,0.82 4.31,-0.29s4.45,-2.23 5.95,-1.41s1.69,1.74 1.4,2.81a8,8 0 0 1 -1.79,2.52s-0.77,0.19 0.68,2.08s5,5.85 8.51,6s4.94,3.1 6.54,7.12a39.43,39.43 0 0 1 4.3,2.66c0.39,0.05 6.63,-3.63 12,-3.24c1,-2.33 5.18,-4.31 6.2,-4s5.12,2 5.56,-1.79c0.92,-2.56 6.39,-7.45 7.74,-9s2.66,-2.32 2.42,-6.39c0.49,-2.95 5.28,-5.9 6.24,-6.05s4.26,-2 7.75,-2.56c0,0 6.33,-4.16 6.33,-4.94s-6.24,-4.16 -6.58,-4.16a19,19 0 0 1 -7.16,-1.16c-3.68,-1.35 -3.53,-9.73 -4,-10.45a17,17 0 0 0 -5.46,-5.42a15.92,15.92 0 0 0 0.82,-3.1c0.1,-0.63 6.58,-0.24 8.32,-0.09s10.26,0.72 12.24,0.58s6.44,2.85 10.12,3.09s5.13,0.2 5.8,0s4.45,-2.86 4.45,-3.25s0.49,-5.46 -4.06,-8c-0.92,-2.31 0.29,-9.43 2.37,-10.06s4,-3.49 11.47,-1.65c7.24,3.45 6.46,-3.33 7.96,-4.49z"
          />
        </g>
        <g class="vks">
          <path d="m296.20391,257.58105a10,10 0 0 1 0.56,-4.19a2.23,2.23 0 0 1 -0.37,-0.07a7.67,7.67 0 0 1 -2.22,-1.59a20.52,20.52 0 0 0 -3,-1.89c-0.39,-0.15 -3.15,-0.05 -3.2,-0.44s3,-3.43 4,-4.84c-0.34,-2.12 -4.06,-2.66 -6,-2.46s-2.59,-0.63 -2.59,-1.16a22.87,22.87 0 0 1 1.4,-3.2c0.19,-0.53 6.24,-2.71 7.74,-2.85s4.65,-1.26 5.57,-2.66s0.63,-4.31 -0.39,-6.78s-3.77,-2.85 -5.9,-2.51s-10.84,2.32 -10.84,2.32s-5.08,12.57 -8.58,15.09c-1.88,3.34 -7.16,5.09 -9.33,4.84s-7.3,-3.36 -7.67,-5.56c-0.29,-0.15 -3.9,0.18 -6,0.05s-7.48,1.8 -8.9,2.45s-7.23,1.81 -8.77,-1.16a4.39,4.39 0 0 1 -1.6,-3.71c-4.73,1.3 -15.17,2.06 -18.39,1.57c-3.68,-0.58 -13.68,-1.07 -16.78,-1.71s-13.93,-1.81 -18.49,-2.2a27.38,27.38 0 0 0 -6.54,0c-2.07,0.17 -3.1,3.62 -4.39,7s-1.93,6.71 -3,8.9a6.45,6.45 0 0 0 1.17,5.42c2,2.45 6.45,9.42 7.8,10.45c0,0 2.65,5.46 8.16,7.2s20.42,9.87 22.75,11.42s11.15,7.59 13.57,11.52c4,3.19 6.58,11.52 7.17,13.26s5,13 14.51,7.64s19.07,-16.79 20.42,-18.73s2,-8.52 3.48,-11.42s4.36,-10.84 7.26,-13.26c3.49,-1.64 8.23,0.1 11.62,-4c2.32,-4.64 9.58,-6.77 11.61,-9.48c1.55,-1.64 4.25,-1.36 4.25,-1.36a7.93,7.93 0 0 0 -0.09,-1.9z"
          />
        </g>
        <g class="vks">
          <path d="m298.92503,328.02545c4.13,2.45 11,7.22 12.22,9.42s5.45,10.51 8.23,12.58s8.51,6.45 9.51,9.56a86.65,86.65 0 0 1 11.46,0.31c9.58,0.68 10.25,-7.94 10.25,-9.1s-0.77,-12.87 -2.71,-17.22s-3.48,-8 -4.25,-12.78c-2.71,-4.06 -2.62,-8.9 -2.71,-11.9s1.74,-15 -0.49,-20.62s-11.8,-12.09 -15.19,-15s-8.9,-6.38 -9.78,-7.35s-6,-2.9 -8.71,-4.65c-2.5,-1.61 -9.17,-1.19 -10.16,-1.07c-0.05,0.19 -0.14,0 -0.14,0l0.14,0s-2.71,-0.28 -4.25,1.37c-2,2.7 -9.29,4.83 -11.62,9.48c-3.38,4.06 -8.13,2.32 -11.61,4c-2.9,2.42 -5.81,10.35 -7.26,13.25s-2.13,9.49 -3.48,11.42s-10.97,13.43 -20.42,18.75s-13.94,-5.9 -14.55,-7.65s-3.19,-10.06 -7.16,-13.25c-2.42,-4 -11.22,-10.07 -13.55,-11.62s-17.19,-9.68 -22.71,-11.42s-8.16,-7.19 -8.16,-7.19c-0.26,1.87 -2.9,2.06 -4.52,1.61s-4.64,-6.64 -6.06,-7.35s-4.71,-2.65 -6.84,-0.33s-3.48,4.33 -3.61,6.2s-4.84,4.58 -5.87,5s-5.42,1.87 -6.78,3s-3.74,0.39 -3.74,0.39c5.23,13.74 6.9,13.48 10.71,15.8s3,6.45 10.9,9.42s3.68,7 6.78,8.26c8.19,10 3.74,13.1 6.77,16.58c4.32,0.84 5.61,7.68 9,8.71s6.06,-7.87 14.39,-3.94c3.08,1.46 4.38,3.11 4.75,4.94a7.66,7.66 0 0 1 4.18,0.35c3.49,1.46 9.2,0.29 11.42,1.46l3.1,0s0.29,-8.81 0.68,-9.2s16.45,0.2 18.87,0.49s8.8,-2.62 9.77,-3.58s5.9,-5.23 8.13,-5.71s5,-3.78 6.29,-4.26s9.77,-4.94 10.94,-5.71s9.48,-4.74 10.25,-4.74s2.71,-1.07 2.42,1.35s-1,22.26 -0.58,23.52s0.78,1.55 1.16,1.74s5.29,-3.58 6.45,-3.77s4.01,-2 8.14,0.45z"
          />
        </g>
        <g class="vks">
          <path d="m158.86353,487.34003c-3.53,-0.19 -7.07,-4.16 -8.52,-6s-0.68,-2.08 -0.68,-2.08a7.91,7.91 0 0 0 1.79,-2.51c0.29,-1.07 0.1,-2 -1.4,-2.81s-4,0.29 -6,1.4s-3.63,0.78 -4.31,0.29a10.52,10.52 0 0 1 -2.71,-3.29c-1.12,-1.84 0.32,-3.24 0.58,-3.89s-1.55,-2.71 1.55,-6.32s2.06,-12.51 1.41,-14.71s-1,-9.8 -1.93,-11.87s-5.42,-6.45 -8.13,-8a19.27,19.27 0 0 0 -2.54,-1.21c-1.85,2.41 -6.6,4 -8.23,2.28c-1.87,-1.94 -8.65,-3.62 -13,0.51c1.61,6.84 -7,8.2 -3.94,12.33s0.91,5.61 -1.09,9s-3.42,3.1 -2.71,1.23s-1.75,-5 -3.23,-5s-3.23,1.55 -3.74,0.65s-0.45,-1.62 -1.49,-1.42s-5.22,1.48 -6.7,-1.16s-2.26,-1.87 -2.26,-1.87c-1.81,7.48 -3.87,3.61 -4.65,8.51s-2.84,9 -7,8.78s-5.93,-0.26 -5.93,4.9s-6.71,4.39 -3.61,7.48s5.16,8.52 2.06,12.91s-2.58,1.8 -4.9,7.48s3.87,8.26 1.8,10.06s-7,0.52 -5.16,7s3.36,6.71 7.74,7.49s-0.19,1.5 10.13,4.84s11.1,-2 12.64,-4.37s12.39,-5.93 11.87,-2.84s7,11.1 9.81,9.29c1.42,-0.9 3.1,-0.38 4.49,1.24c2.26,-0.56 4.27,-0.42 6.09,-2.78c3,-1.68 5.68,-1.81 9.81,-0.52s8.39,2.32 10.45,0.64s6.71,-1.93 8.65,-2.19s4.38,0.9 6.45,-0.64c4.13,-3.23 13.42,-2.58 14.71,-2.71s4,-1.29 4.51,-2.2s0.92,-2.58 5,-5.67c1.55,-4.52 0.13,-5.3 -1.54,-7.62a28,28 0 0 0 -5.29,-4.88c-0.39,0 -1.41,-1.26 -4.31,-2.67c-1.54,-4.01 -2.97,-6.92 -6.54,-7.08z"
          />
        </g>
        <g class="vks">
          <path d="m232.89,597.45763c-0.49,-2 -0.29,-4.26 -2.42,-6c1.16,-1.74 1.55,-5.61 -0.58,-6.87a5.78,5.78 0 0 1 -2.71,-4c-0.1,-0.58 -0.2,-10.06 -0.58,-16.45s-12.58,-16.64 -13.65,-17.61s-7.74,-5.52 -8.61,-6.46s-0.39,-1.06 -1.84,-1.25s-7.55,-2.81 -8.32,-4.26s-3.49,-3.1 -5.71,-3.2s-4.74,-1.35 -9.49,-3.29a30,30 0 0 1 -9.29,-6.38a31.65,31.65 0 0 1 -3.09,-3.68c-1.29,0.13 -10.58,-0.52 -14.71,2.71c-2.07,1.55 -4.52,0.39 -6.45,0.64s-6.59,0.52 -8.65,2.2s-6.35,0.64 -10.45,-0.65s-6.84,-1.16 -9.81,0.52c-1.82,2.36 -3.83,2.22 -6.09,2.78l0,0a13.73,13.73 0 0 1 2.73,7.8c0.5,7 -4.38,9.29 0,15.74s-0.26,4.13 0,8.78s5.93,3.87 8.77,9.8c1.55,7.74 3.58,4.13 4.39,9.81c-1.55,3.61 -0.78,7 1.58,9s1.26,2.58 0.11,4.65s7.34,2.06 4,4.38s-3.1,5.42 -3.88,15s2.59,6.71 5.42,6.71s5.94,12.65 10.58,13.94s3.62,-4.91 4.13,-7.23s2.84,-7.48 6.46,-7.48s10.58,-6.2 13.57,-3.62s3.2,3.87 6.55,2.58s8.52,0 11.88,1s2.58,-3.1 9.29,-4.65s2.83,1.29 5.42,3.87s5.41,-1 5.41,-2.58s-1,-3.87 5.94,-4.64c11.1,-0.78 9.55,5.67 11.1,8.25c1.44,2.42 8.76,-0.13 11,2.69l0,0c0.9,-2 3.74,-4.78 6.16,-5.36s3,-1.45 3,-2s2.51,-4 2.51,-4s-4.26,-2.42 -7.84,-2.42c-1.96,-1.32 -5.35,-6.74 -5.83,-8.77z"
          />
        </g>
        <g class="vks">
          <path d="m302.43319,548.66227s5.61,-5.23 6.77,-5.91s3.88,-3 6.39,-3.58a8.48,8.48 0 0 0 5.13,-4.35c0.39,-1.16 1.36,-1.74 3.1,-2.13s9.67,-5.52 10.64,-6.48s2.13,-4.65 0.78,-6.39s-4,-2.23 -5.42,-1.26s-1.68,3.29 -3.68,2.71a2.94,2.94 0 0 1 -2.23,-3.39c0.29,-0.87 -0.67,-2.22 -2.42,-3.48c-0.4,-0.29 -0.78,-0.54 -1.13,-0.75c-2.48,2.19 -9.07,7.86 -11.41,8.23s-11.36,0 -14.46,0s-7,-0.57 -11.61,-0.64c-9.29,0.77 -15.61,-4.78 -17,-5.94s-7.36,-4.13 -9.81,-6.45s-7.93,-9.35 -7.93,-9.35s-5.85,-0.15 -8.37,0s-5.52,-1.84 -5.28,-4.09a2,2 0 0 0 -2.41,-2.34c-0.39,0.14 -1.7,2.22 -1.94,2.27s-3,0.49 -2.32,-4.25s-1.79,-3.6 -3.58,-3.59c-1.84,-0.24 -3.83,1.21 -4.79,1.6a2.5,2.5 0 0 1 -3.58,-2c-0.44,-2.47 2.71,-4.36 3.82,-4.5s6.39,-1.26 6.87,-1.65s0.19,-2.08 -0.34,-2.18s-0.68,-0.14 -1.69,-2.22c-0.78,-3.92 2,-5.08 3.43,-7.16s5.08,-3.39 6,-6.82c-0.14,-3.63 -0.29,-4.8 -2.76,-5.47s-7.06,2.86 -7.06,2.86c0,0.78 -6.34,4.94 -6.34,4.94c-3.48,0.53 -6.77,2.42 -7.74,2.56s-5.76,3.1 -6.24,6.05c0.24,4.07 -1.07,4.89 -2.42,6.39s-6.82,6.39 -7.74,9c-0.44,3.78 -4.55,2.08 -5.57,1.79s-5.22,1.69 -6.19,4c-5.37,-0.39 -11.61,3.29 -12,3.24a27.84,27.84 0 0 1 5.24,4.86c1.68,2.32 3.1,3.09 1.55,7.61c-4.12,3.1 -4.55,4.77 -5.03,5.67s-3.23,2.07 -4.52,2.2a31.07,31.07 0 0 0 3.1,3.68a29.87,29.87 0 0 0 9.29,6.38c4.74,1.94 7.26,3.2 9.48,3.3s4.94,1.74 5.71,3.19s6.87,4.06 8.32,4.25s1,0.29 1.84,1.25s7.55,5.52 8.61,6.49s13.26,11.26 13.65,17.62s0.48,15.87 0.58,16.45a5.81,5.81 0 0 0 2.71,4c2.13,1.26 1.74,5.13 0.58,6.87c2.13,1.74 1.94,4 2.42,6s3.87,7.45 5.81,8.81c3.58,0 7.84,2.42 7.84,2.42s5.51,-10 6.87,-11.42s3.58,-3.29 4.45,-7.55c1.74,-2.81 5.8,-8.52 6.68,-9.87s3.09,-6.29 5.8,-7.07s5.13,-4.25 6.78,-9c0.67,-5 2.9,-13.16 9.38,-15.29s14.62,-0.8 15.39,-0.12z"
          />
        </g>
        <g class="vks">
          <path d="m360.66,650.37147c0.2,-2.45 2,-8.78 1,-12.07s0.58,-6.77 0.84,-7.67s2.71,-10 2.65,-11.75s0.35,-8.7 0.35,-8.7c-0.48,-0.46 -5.51,-0.84 -6.67,-1.39a29.34,29.34 0 0 1 -4.65,-2.81c-1,-0.84 -3.94,-5.42 -4,-6.84s-1.58,-2.35 -2.61,-2.71s-2.94,-2.09 -4.1,-3.29s-4.61,-10.77 -4.9,-11.45a7.56,7.56 0 0 1 0.32,-3.64c0.32,-0.81 0.49,-7.91 -0.58,-10.36c-0.42,-2.61 -3.64,-3.51 -4.77,-3.64s-5.91,-0.39 -8.55,0.35c-2,1.29 -5.1,1.19 -6.84,1.19s-5.23,1 -6.77,3.29c-2.13,-0.19 -5,-3.29 -5.23,-5s-3.65,-15.32 -3.65,-15.32c-0.77,-0.68 -8.9,-2 -15.38,0.1s-8.71,10.26 -9.39,15.29c-1.65,4.74 -4.07,8.23 -6.78,9s-4.93,5.71 -5.8,7.06s-4.91,7.07 -6.65,9.87c-0.87,4.26 -3.1,6.1 -4.45,7.55s-6.87,11.42 -6.87,11.42s-2.52,3.39 -2.52,4s-0.58,1.45 -3,2s-5.25,3.38 -6.16,5.37l0,0a2.4,2.4 0 0 1 0.39,0.66c1.55,3.88 2.06,3.36 -1.81,8.78s-0.51,8.52 -3.87,10.32a5.27,5.27 0 0 1 -0.71,0.32a5.38,5.38 0 0 0 1.42,1.21c1,0.29 4.06,4.25 5.61,6.09s6,1.84 6.58,0s4.16,-4.74 5.52,0.2s7.74,13.31 10.74,14.71s12.68,5.67 14.13,11.28s3.58,9.72 2.52,12.72s-6.39,5.61 -6.68,6.48s-1.45,4.26 -1.16,5.61s1.64,7.65 1.06,9.49c2.81,-0.78 7.55,5.9 7.55,6.77c2.13,0 7.45,0.48 9.58,1.26s6.49,0.39 8.61,-2.32s3.68,-6.2 6.88,-6.88s4.83,-1.35 6.87,-4.16s6.29,-5.22 8.13,-6.29s4.45,-4.45 3.09,-8.32c-0.48,-2.81 -0.68,-7.64 -0.29,-8.23s1.26,-1.93 2.32,-2.12s1.84,-2.23 2.62,-4.65s4.06,-5.81 5.42,-6.1s5.13,-3.87 7.84,-4.35s12.09,0.48 16.06,-0.39s4.42,-0.93 4.42,-0.93c1.35,-2.17 2.16,-8.56 2.32,-11.01z"
          />
        </g>
        <g class="vks">
          <path d="m269.12739,708.06824c0.58,-1.84 -0.78,-8.13 -1.07,-9.48s0.87,-4.74 1.16,-5.61s5.62,-3.49 6.68,-6.49s-1.06,-7.06 -2.52,-12.68s-11.12,-10 -14.12,-11.32s-9.43,-9.76 -10.75,-14.7s-4.93,-2 -5.51,-0.19s-5,1.84 -6.58,0s-4.65,-5.81 -5.59,-6.1a5.43,5.43 0 0 1 -1.45,-1.16c-2.55,0.96 -2.55,-1.87 -8.06,1.23c-1.29,7.5 -3.87,5.93 -9.29,7.22s-0.52,5.42 -4.13,6.71s-8,5.16 -10.84,5.94s-2.32,4.13 -4.39,10.06s-11.35,7.23 -18.84,9.03s-3.1,0.78 -4.65,9.29s2.33,10.84 3.37,14.25s10.06,-0.78 8.25,8.51s0,6.46 0,6.46l13.42,0.77c10.37,0.6 8.57,5.51 9.24,10c0,0 3.28,0.67 5.34,-1.13s5.36,-1.61 6.33,-1.69s3.54,-3.94 4.9,-4.59a6.12,6.12 0 0 1 4.26,0c1.29,0.39 5.55,1.3 8.84,-1.61a32.34,32.34 0 0 0 6.58,-7.8c1.61,-2.59 5.22,-5.42 7.87,-5.23s8.51,1.94 9.87,2.9s8.38,0.33 11.68,-2.59z"
          />
        </g>
        <g class="vks">
          <path d="m350.9967,790.59454c-0.09,-0.67 -8.51,-9.09 -12.29,-12.38s-11.32,-5.23 -13.35,-6.58a93.62,93.62 0 0 0 -10.84,-5.52c-2.23,-0.87 -2.61,0.87 -2.61,0.87s-3.29,5.81 -3.79,7.26s-1.44,1.26 -2.5,0.87a36.31,36.31 0 0 1 -7.12,-4.59c-3.58,-2.71 -6,-10.06 -7.26,-12.1s-6.38,-4.6 -8.12,-4.9s-9.2,-1.16 -9.88,-1.55s-3.19,-3.87 -4.25,-7s-0.1,-3.09 0,-3.87s3.87,-9.48 4.64,-12.68s3.58,-13.79 3.58,-13.79c0,-0.87 -4.74,-7.55 -7.55,-6.78c-3.29,2.91 -10.32,3.55 -11.66,2.58s-7.24,-2.71 -9.88,-2.91s-6.26,2.64 -7.88,5.23a32.11,32.11 0 0 1 -6.58,7.77c-3.29,2.9 -7.54,2 -8.83,1.61a6.12,6.12 0 0 0 -4.26,0c-1.36,0.65 -3.94,4.52 -4.91,4.59s-4.25,-0.2 -6.32,1.65s-5.34,1.15 -5.34,1.15a6.87,6.87 0 0 0 1.6,3.71c4.4,4.88 6.19,10.56 4.13,11.29s-6.19,6.45 -4.39,9s4.66,3.17 8.52,4.72s5.93,1 3.61,6.19c-4.64,1.81 -2.32,7 -3.35,8s-3.62,8 0.51,7.23c6.71,4.9 0,5.93 5.42,8.77c10.32,-2.32 14.45,11.62 11.87,18.09s1.81,8.26 4.65,8.51s4.64,0 3.87,2.58s2.06,5.17 3.35,5.17s3.36,-3.1 4.13,0s-0.77,4.12 -4.38,3.87s-3.62,5.16 -4.91,10.58s-2.32,8.25 -5.16,8.25a6.83,6.83 0 0 1 3,1.62c1.22,1.22 3.87,4 4.39,5.48s5.61,8.2 6.12,8.52s5.29,3.35 8.07,0.64c-0.32,-3 1,-3.55 2.06,-4.13s1.62,-1.67 4.65,-1.16s4,0.26 5.68,2.07s6.32,2.51 8.51,3s5.36,4.45 5.68,5.23s2.52,6 2.64,7.67s0.33,6.58 -0.32,8.26a6.34,6.34 0 0 0 1.94,6.26c2.26,-0.39 4.45,-2.65 5.09,-2.9s5.29,-1 5.49,2.58a3,3 0 0 0 4.45,1.35c0.65,-0.45 0.32,-2.77 2.19,-3.29s7.42,-3.42 8.65,-4.9a3.49,3.49 0 0 1 1,-0.81c-0.38,-1.78 0.13,-3.35 0,-4c-0.26,-1.29 5.42,-12.13 6.45,-13.94s-1,-6.45 4.64,-11.09s2.58,-13.16 2.84,-15.23s2.84,-2.06 6.45,-5.16s4.13,-5.94 4.13,-7.74c0,-1.19 1.68,-2.49 3.33,-3.24l0,0c0.06,-0.43 0.11,-0.88 0.16,-1.31c0.19,-1.69 -0.63,-2.13 -1.46,-4.21a5.23,5.23 0 0 1 1.41,-5.42c1.16,-1.11 5.37,-8.52 7,-9.82s3.29,-8.21 3.29,-8.21c-0.21,-0.08 0.05,-0.33 0,-1.01z"
          />
        </g>
        <g class="vks">
          <path d="m337.3858,910.47926c-1.55,1.54 -2.84,2.32 -6.45,2.32s-3.87,-3.61 -1.29,-6.2c1.6,-1.59 2.4,-1.9 2.79,-1.91a49.87,49.87 0 0 1 -11.05,-5.57c-5.16,-3.61 1.55,-5.93 0,-6.45s-4.9,-0.77 -4.39,-6.71c-2.78,-1.45 -3.79,-3.18 -4.12,-4.75a3.71,3.71 0 0 0 -1,0.81c-1.23,1.49 -6.78,4.39 -8.65,4.91s-1.55,2.84 -2.19,3.29a3,3 0 0 1 -4.4,-1.36c-0.2,-3.55 -4.84,-2.84 -5.49,-2.58s-2.84,2.52 -5.09,2.91c-1.87,-1.62 -2.58,-4.59 -1.94,-6.26s0.45,-6.58 0.32,-8.26s-2.32,-6.9 -2.64,-7.68s-3.49,-4.71 -5.68,-5.22s-6.84,-1.23 -8.52,-3s-2.64,-1.54 -5.67,-2.06s-3.55,0.58 -4.65,1.16s-2.39,1.1 -2.06,4.13c-2.78,2.71 -7.55,-0.32 -8.07,-0.65s-5.61,-7 -6.13,-8.51s-3.18,-4.3 -4.38,-5.53a6.88,6.88 0 0 0 -3,-1.61c-2.84,0 -5.42,-2.58 -9.29,-1.29c-3.36,4.13 0.26,5.42 -9.55,6.45s-4.89,-2.81 -11.09,-2.81c-2.32,3.07 -3.87,4.1 -13.16,7.2s-4.13,9.55 -7,12.9s-2.8,8.26 1.84,11.62s11.1,3.87 11.1,3.87s5.42,-3.87 11.35,-4.13s11.36,-0.52 10.07,4.64s3.61,8 5.67,10.33s3.88,-1 3.88,2.06c1,8.52 3.35,5.68 7.59,12.13s3,10.32 0.92,12.64s0,11.88 0,11.88s7.74,1.8 4.65,9c-2.58,11.35 8.25,12.64 11.87,14.71s6.19,5.42 8.51,0.51c5.42,-2.06 5.42,-5.16 6.2,-11.87s10.32,-6.68 13.44,-6.68s11.09,1.55 11.09,1.55c9.55,9.81 21.68,-3.87 23.49,-3.87s7.22,-2.32 7.22,-2.32s0,-3.87 8.52,-7.74s11.1,1 9.55,-2.84s0,-2.33 0.77,-4.13s-2.34,-10.58 -3.89,-9.03z"
          />
        </g>
        <g class="vks">
          <path d="m422.80349,882.87414c1.55,-2.58 5.94,-5.41 4.91,-2.83s-3.07,2.32 3.61,7.45s3.61,-0.52 1,-4.65s1.55,-11.87 -0.26,-13.16s1.81,1.29 -3.09,0.26s-9.26,-3.84 -12.33,-6.16s-7.74,0.77 -8.26,-1s4.13,-3.88 1.81,-4.65s-3.1,2.58 -4.46,-1.29s0.84,-4.65 0.07,-10.84s5.16,-1 5.16,-1s-1.81,-3.87 -3.61,-8.26s6.45,-11.1 7.74,-17s4.39,-3.61 4.39,-3.61s-1.81,-1.81 -0.26,-3.62s0.77,-4.64 -0.52,-8.26s-0.28,-4.76 2.05,-6.02s1.38,-4.52 0.41,-7.06c-1.67,-0.13 -6.39,0.48 -7.62,0.45a6,6 0 0 0 -4.54,1.29a3.87,3.87 0 0 0 -2.55,3.51l0,11.2c-0.45,0 -4.68,-1.29 -5.1,-1.94s-4.06,-2.9 -5.84,-3.93a21.23,21.23 0 0 1 -4.55,-3.52c-0.6,-0.74 -4.62,-6.35 -4.53,-8.32l0,0c-1,-0.2 -0.51,-0.39 -2.25,-0.07s-11.31,-0.06 -13.83,-0.35s-10.65,1.28 -12.46,1.52a60.75,60.75 0 0 1 -6.25,0.58s-1.74,6.9 -3.34,8.19s-5.8,8.71 -7,9.82a5.23,5.23 0 0 0 -1.4,5.42c0.82,2.08 1.65,2.52 1.45,4.21l-0.15,1.31a6.31,6.31 0 0 1 2.35,-0.63c1.8,0 3.35,6.45 4.38,8.25s-2.06,6.2 1.55,9c5.42,7.22 0.26,8.77 3,10.58c4.84,3.09 4.45,9.55 2.38,10.58s-4.13,5.42 0,7.74c6.19,-1.29 12.9,4.13 19.87,9.29s6.2,1 8.26,1s4.13,4.13 8,4.65s4.13,-2.84 5.68,-2.07s3.87,0.78 3.35,2.58s-2.84,3.1 1.81,5.42s0.52,-1.54 4.64,3.36s4.39,0.26 4.39,0.26s-1.81,-5.94 1.29,-4.91s9.1,-0.15 10.65,-2.77z"
          />
        </g>
        <g class="vks">
          <path d="m269.21979,741.76001c-0.1,0.77 -1.07,0.77 0,3.87s3.58,6.58 4.25,7s8.13,1.26 9.87,1.55s6.88,2.9 8.13,4.93s3.68,9.39 7.26,12.1a36.31,36.31 0 0 0 7.07,4.55c1.06,0.38 2,0.58 2.5,-0.87s3.79,-7.26 3.79,-7.26s0.38,-1.74 2.61,-0.87a95.15,95.15 0 0 1 10.84,5.51c2,1.36 9.58,3.29 13.35,6.58s12.2,11.71 12.29,12.39s-0.17,0.88 0,1a61.12,61.12 0 0 0 6.26,-0.58c1.8,-0.25 9.94,-1.75 12.45,-1.49s12.07,0.64 13.81,0.32a3.09,3.09 0 0 1 2.25,0.06l0,0c0,-0.37 0.16,-0.62 0.5,-0.68c2.23,-0.39 2.39,-0.94 2.39,-2.42s0.39,-3.13 0.58,-6.84s2.29,-6.94 3.32,-7.55s3.23,-3.48 4.75,-4.74s3.32,-8.13 2,-9.23a1.87,1.87 0 0 1 0,-2.9c0.42,-0.26 0.79,-2.1 0.71,-5.23c0.29,-2.8 -0.29,-16.06 -0.58,-18.19a52.37,52.37 0 0 0 -2.06,-7c-1.68,-0.19 -4.33,-3.1 -5.17,-4.9s-3.74,-5.49 -5.35,-5.94s-3.68,-2.39 -4.52,-2.77s-1.61,0 -4.84,0c-2.77,-0.71 -5.8,-4.52 -5.8,-4.52c-11.55,2.39 -14.78,-4.06 -15,-4.45s0.39,-6.58 0.39,-6.58c2.45,-1.23 7.93,-9.94 8.93,-11.49s-2.8,-12 -4.67,-14.83a16.09,16.09 0 0 1 -2.54,-8.53s-0.45,0.06 -4.42,0.94s-13.35,-0.1 -16.06,0.38s-6.49,4.07 -7.79,4.36s-4.65,3.67 -5.42,6.09s-1.58,4.46 -2.67,4.65s-1.91,1.58 -2.32,2.13s-0.19,5.42 0.29,8.22c1.36,3.88 -1.26,7.26 -3.09,8.33s-6.1,3.48 -8.13,6.29s-3.66,3.48 -6.87,4.16s-4.79,4.16 -6.88,6.87s-6.48,3.1 -8.61,2.32s-7.45,-1.26 -9.58,-1.26c0,0 -2.81,10.65 -3.58,13.84s-4.55,11.91 -4.64,12.68z"
          />
        </g>
        <g class="vks">
          <path d="m400.77227,749.91976c0.08,3.13 -0.29,5 -0.71,5.23a1.87,1.87 0 0 0 0,2.9c1.36,1.1 -0.45,8 -2,9.23s-3.64,4.13 -4.68,4.74s-3.13,3.8 -3.32,7.55s-0.58,5.35 -0.58,6.84s-0.16,2 -2.39,2.42s3.33,8.13 4,9a20.82,20.82 0 0 0 4.54,3.51c1.78,1 5.42,3.29 5.84,3.94s4.65,1.93 5.1,1.93l0,-11.19a3.88,3.88 0 0 1 2.55,-3.52a6.11,6.11 0 0 1 4.55,-1.29c1.22,0 5.94,-0.57 7.61,-0.44c0,-0.15 -0.11,-0.29 -0.16,-0.43c-1,-2.54 1.22,-2.82 4.5,0.63l5.86,0.16l0.22,-0.27c1.29,-1.55 3.87,-8 3.87,-8c0.26,-6.45 4.9,-8.26 9.55,-9.81s6.71,-5.67 4.64,-9.55a8.84,8.84 0 0 1 0,-7.74s7,-8.77 -0.51,-12.13s2.06,-13.93 2.58,-17c0.4,-2.4 2.82,-9.76 4.6,-12.59c-0.1,-6.54 -0.74,-12.8 -2.54,-15.8a30.27,30.27 0 0 1 -3.48,-7.91l0,0c-1.13,-0.67 -7.32,-1.73 -8.35,-1.51s-2.77,1.68 -3.87,2.32s-5.55,7.46 -6.62,9.27s-6.33,9.22 -7.68,9.87s-4.84,0.84 -5.55,1.61s-2.39,3.87 -4.45,4.45s-7.23,4.65 -9,6.13a11.06,11.06 0 0 1 -6.77,2.26a52.37,52.37 0 0 1 2.06,7c0.3,2.09 0.88,15.39 0.59,18.19z"
          />
        </g>
        <g class="vks">
          <path d="m449.26409,672.14695a15.93,15.93 0 0 1 3.35,-8.66c-1.79,-0.66 -3.48,-1.47 -5,-2c-2.78,-1 -4,-3.55 -5.42,-5.49s-3.51,-8 -3.9,-9.72a4.89,4.89 0 0 0 -2.13,-3.42c-1.1,-0.64 -2.84,-1.48 -3.68,-3s-1.67,-3 -3.29,-3.42s-5.8,-0.38 -8.9,-0.32s-8.13,-0.64 -9.48,-2.64s-2.43,-7.85 -2.33,-11.07s2.07,-7.49 3.36,-8.78a8.78,8.78 0 0 0 2.06,-5.35a22.88,22.88 0 0 1 2.2,-6.52a31.41,31.41 0 0 0 1.55,-5.29a52.23,52.23 0 0 0 -3.27,-10.25c-0.38,-2.46 -2.19,-3.94 -5,-4.71a5.88,5.88 0 0 1 -3.7,-3.51c-0.26,-0.39 -0.49,-0.45 -1.65,-1.48c-1.84,-0.23 -1.71,-0.62 -4.35,-2a5.25,5.25 0 0 1 -3.07,-4.26c-2.67,-0.14 -2.23,-0.79 -2.64,-3.46s-3.42,-1.71 -4.29,-1.2s-1.52,3.32 -2,4.78a3.41,3.41 0 0 1 -3.3,2.35c-1.52,0 -6.55,0.13 -6.55,0.13a21,21 0 0 1 -2.19,6.77a68.12,68.12 0 0 1 -6.19,6.37c-1,0.68 -0.48,2.32 -0.48,2.61s4.35,9.68 4.9,10.84a3,3 0 0 1 0,2.87c-0.39,0.58 -4.19,4.36 -5,4.55s-2.13,1.81 -3.1,3c0,0 -0.42,7 -0.35,8.71s-2.42,10.8 -2.68,11.7s-1.8,4.39 -0.84,7.72s-0.84,9.61 -1,12.06s-1,8.84 -2.32,11a16.16,16.16 0 0 0 2.51,8.52c1.88,2.83 5.68,13.29 4.68,14.83s-6.51,10.19 -8.96,11.42c0,0 -0.58,6.19 -0.39,6.58s3.42,6.84 15,4.45c0,0 3,3.81 5.8,4.52c3.23,0 4,-0.39 4.84,0s2.91,2.32 4.52,2.77s4.51,4.13 5.35,5.94s3.45,4.71 5.13,4.91a11.07,11.07 0 0 0 6.78,-2.26c1.74,-1.48 6.9,-5.55 9,-6.13s3.74,-3.68 4.45,-4.45s4.19,-1 5.55,-1.61s6.58,-8.07 7.67,-9.87s5.55,-8.58 6.65,-9.23s2.84,-2.06 3.87,-2.32s7.22,0.8 8.38,1.43l0,0c-1.28,-4.8 -1.15,-10.11 -0.15,-18.41z"
          />
        </g>
        <g class="vks">
          <path d="m413.62092,608.46997a8.78,8.78 0 0 1 -2.06,5.35c-1.3,1.29 -3.23,5.55 -3.37,8.78s1,9.09 2.32,11.09s6.39,2.71 9.49,2.65s7.29,-0.13 8.9,0.32s2.46,1.94 3.29,3.42s2.58,2.32 3.68,3a4.91,4.91 0 0 1 2.13,3.42c0.38,1.67 2.51,7.8 3.93,9.74s2.65,4.52 5.42,5.48c1.49,0.52 3.18,1.33 5,2c3.84,-4.66 10.44,-5.54 17.27,-8.44c5.66,-2.39 14.23,-7.52 20.48,-11.43l0,0c-1.41,-0.32 -2.37,-0.83 -2.66,-1.61c-1.06,-2.9 -3.09,-9.87 -2.85,-14s1.55,-10.89 5,-12.53s15.48,-9.63 16.74,-12.1s2.13,-5.27 3.73,-12c4.13,-14.17 -9.87,-13.98 -10.96,-15.33s-8.08,-4.55 -6.67,-5.51s0.87,-5.52 1.16,-7a1.36,1.36 0 0 0 -0.05,-0.67l0,0s-4.76,0.18 -9.08,-0.12a31.63,31.63 0 0 1 -10.58,-2.38c-1.69,-0.91 -2.52,0.38 -3.1,0.68s-0.45,1.61 -0.77,2.84c-0.58,0.77 -1.1,2.58 -0.71,3.29s0.71,1 0,1.29s0,0 -2.78,0.84s-4.71,-0.13 -5.55,-1.42a3,3 0 0 0 -3,-1.29a10,10 0 0 0 -4.13,4.19a102,102 0 0 0 -4,16.07c-0.25,3 -2.13,4.77 -4.32,4.9s-7,4.52 -8.9,5.68s-6.36,1.61 -8.29,1.61s-11,-2.65 -11,-2.65a30.71,30.71 0 0 1 -1.55,5.29a22.87,22.87 0 0 0 -2.16,6.55z"
          />
        </g>
        <g class="vks">
          <path d="m408.79252,581.83c2.77,0.77 4.58,2.25 5,4.71a52.84,52.84 0 0 1 3.29,10.25s9,2.65 11,2.65s6.38,-0.52 8.25,-1.68s6.71,-5.55 8.91,-5.68s4.06,-1.87 4.32,-4.9a100.8,100.8 0 0 1 4,-16.06a10.08,10.08 0 0 1 4.13,-4.2a3,3 0 0 1 3,1.29c0.84,1.29 2.77,2.26 5.55,1.42s2.06,-0.58 2.77,-0.84s0.39,-0.58 0,-1.29s0.13,-2.51 0.71,-3.29c0.33,-1.22 0.2,-2.58 0.78,-2.84s1.42,-1.54 3.09,-0.64a31.84,31.84 0 0 0 10.58,2.39c4.33,0.25 9.08,0.14 9.08,0.14l0,0c0,-0.2 1,0.06 8.38,-2.24c10.45,-3.24 15.87,-12.39 17.66,-14.52s2.76,-6.92 2.32,-9.09s1.74,-4.5 2.27,-6.2s-5.12,-3.82 -6.77,-3.82s-2.52,1 -3.58,2.18s-0.24,7.55 -2.81,15.05s-10.64,4.16 -14.3,2.61s-10.21,-14.67 -12.73,-20.81s-12.77,-9.48 -14.22,-9.29s-8.71,4 -14,2.52s-21.39,-3 -23.26,-3.09l-0.61,0l0,0a25.36,25.36 0 0 0 0.29,6c0.61,2.55 4.84,2.68 5.29,6.26s-3.42,5.58 -4.58,6.61s-0.81,2.61 -0.13,2.68s4.32,0.09 5.32,0.09s3.68,2.74 4,4.13s-0.68,3.58 -1.74,4.91s-3.58,6.94 -4.97,7.84s-4.16,5.65 -4.74,6.26s-3.49,4.61 -5.26,5.71s-8.84,0.94 -9,1.16s-5.36,6 -6.07,6.52s-3.93,3.03 -4.93,3.61a5.91,5.91 0 0 0 3.71,3.49z"
          />
        </g>
        <g class="vks">
          <path d="m438.34312,538.23c-1,0 -4.65,0 -5.33,-0.1s-1,-1.64 0.13,-2.67s5,-3 4.58,-6.62s-4.68,-3.71 -5.29,-6.26a25.29,25.29 0 0 1 -0.28,-6c-3.88,-0.34 -21.84,-3.15 -27.9,-18.83a30.25,30.25 0 0 0 -22.21,-19.36c-1.11,-0.39 -13.69,1.11 -16.79,-0.58l0.22,0.1a4.26,4.26 0 0 1 -2.46,1l-9.8,0c-2.33,0 -9.55,1 -11.36,1s-7.35,2.51 -8.26,5.22c-2.32,1.94 -3.09,4.13 -1.67,8.65s0.64,6.19 -1.29,7.87a99.31,99.31 0 0 0 -9.68,11.61l-0.33,0.29c0.35,0.21 0.73,0.45 1.13,0.74c1.75,1.26 2.71,2.62 2.42,3.49a2.93,2.93 0 0 0 2.23,3.38c2,0.59 2.23,-1.74 3.68,-2.7s4.06,-0.49 5.42,1.25s0.19,5.42 -0.81,6.39s-8.9,6.1 -10.64,6.48s-2.71,1 -3.1,2.13a8.49,8.49 0 0 1 -5.13,4.36c-2.51,0.58 -5.22,2.9 -6.39,3.58s-6.74,5.9 -6.74,5.9s3.48,13.55 3.68,15.26s3.09,4.84 5.22,5c1.55,-2.32 5,-3.29 6.78,-3.29s4.8,0.1 6.84,-1.19c2.64,-0.74 7.42,-0.48 8.54,-0.35s4.36,1 4.78,3.64c1.06,2.45 0.9,9.55 0.58,10.36a7.56,7.56 0 0 0 -0.32,3.64c0.29,0.68 3.74,10.26 4.9,11.45s3.06,2.94 4.1,3.29s2.58,1.29 2.61,2.71s3,6 4,6.84a29.7,29.7 0 0 0 4.61,2.9c1.16,0.55 6.2,0.93 6.68,1.38c1,-1.16 2.26,-2.77 3.1,-3s4.64,-4 5,-4.55a3,3 0 0 0 0,-2.87c-0.55,-1.16 -4.9,-10.55 -4.9,-10.84s-0.55,-1.94 0.48,-2.61a66.73,66.73 0 0 0 6.19,-6.36a20.74,20.74 0 0 0 2.2,-6.77s5,-0.13 6.55,-0.13a3.41,3.41 0 0 0 3.29,-2.36c0.48,-1.45 1.12,-4.25 2,-4.77s3.87,-1.48 4.29,1.19s0,3.33 2.64,3.45a5.25,5.25 0 0 0 3.1,4.24c2.64,1.42 2.51,1.81 4.35,2c1.16,1 1.39,1.1 1.65,1.49c1,-0.58 4.19,-3.07 4.9,-3.61s5.87,-6.29 6.06,-6.52s7.26,-0.07 9,-1.16s4.71,-5.1 5.29,-5.71s3.38,-5.36 4.74,-6.26s3.84,-6.52 4.9,-7.84s2,-3.51 1.74,-4.9s-2.92,-4.07 -3.92,-4.07z"
          />
        </g>
        <g class="vks">
          <path d="m365.04002,478.28333l-0.23,-0.11c-3.09,-1.69 -5.17,-23.66 -4.69,-27s-3.43,-11.27 -3.48,-17s-4.12,-3.87 -6.44,-4.11s-5.95,-7.41 -5,-12s1.81,-12.71 -3.51,-13.92a8,8 0 0 1 -4.54,-2.42a22.09,22.09 0 0 0 -3.19,6.45a19.9,19.9 0 0 0 -0.58,7.78c0.16,2 -2.36,2 -3.1,1.48a5.62,5.62 0 0 0 -4.77,0.2c-1.87,0.9 -1.42,2.67 -0.55,3.61s2.94,2.55 2.94,2.84s-1.65,3.77 -4.07,3.87s-8.81,-3.1 -10.16,-3.58a33.32,33.32 0 0 0 -8.81,-0.65c-1.45,0.29 -12.38,3 -13.84,4s-3,4 -3.29,5.22s-0.19,3.2 -1,4s-2.42,4.74 -3.58,7.07s-5.23,8.22 -6,8.22a15.83,15.83 0 0 0 -10.19,2.49c-5.71,0.88 -10.26,14.23 -10.84,16.75c-0.22,1 -0.47,1.38 -0.73,1.39l0,0c0.43,5 -1.3,11.67 -3.24,13.66s-4.19,13.2 -4.19,13.2s5.45,7 7.9,9.35s8.39,5.29 9.81,6.45s7.74,6.71 17,5.94c4.65,0.07 8.52,0.64 11.61,0.64s12,0.39 14.46,0s9.54,-6.58 11.74,-8.51a99.45,99.45 0 0 1 9.68,-11.62c1.93,-1.67 2.71,-3.35 1.29,-7.87s-0.65,-6.71 1.67,-8.64c0.91,-2.71 6.46,-5.16 8.26,-5.16s9,-1 11.36,-1l9.8,0a4.3,4.3 0 0 0 2.5,-1.02z"
          />
        </g>
        <g class="vks">
          <path d="m255.3667,471.99647l0,0c-0.42,0 -0.88,-1 -1.3,-2.65c-0.77,-6.78 3.49,-13 4.74,-14.81s6.88,-12.4 8.42,-14.8s7.17,-14.33 10.94,-22.6c4.16,-6.48 1,-11 -0.1,-12.77s-6.58,-9.92 -8.32,-13.23l0,0a32.05,32.05 0 0 0 -4.5,3.4c-4.3,3.77 -3.5,15.39 -5.03,16.6s-0.73,7.94 -7.94,4.5c-7.45,-1.84 -9.38,1 -11.47,1.65s-3.29,7.75 -2.37,10.06c4.55,2.57 4.07,7.65 4.07,8s-3.78,3 -4.45,3.24s-2.13,0.3 -5.81,0s-8.13,-3.24 -10.11,-3.09s-10.5,-0.36 -12.24,-0.55s-8.23,-0.54 -8.33,0.09a14.37,14.37 0 0 1 -0.82,3.1c2.71,1.4 5,4.69 5.47,5.42s0.29,9.1 4,10.45a19,19 0 0 0 7.13,1.13c0.34,0 6.58,3.39 6.58,4.16c0,0 4.64,-3.48 7.11,-2.8s2.61,1.84 2.76,5.46c-0.92,3.44 -4.6,4.75 -6,6.83s-4.21,3.24 -3.44,7.16c1,2.08 1.16,2.13 1.7,2.22s0.82,1.79 0.34,2.18s-5.76,1.5 -6.88,1.65s-4.25,2 -3.82,4.5a2.51,2.51 0 0 0 3.58,2c1,-0.39 2.95,-1.84 4.79,-1.6c1.79,0 4.31,-1.16 3.58,3.58s2.08,4.31 2.33,4.26s1.54,-2.13 1.93,-2.27a2,2 0 0 1 2.42,2.3c-0.24,2.25 2.76,4.23 5.27,4.09s8.38,0 8.38,0s2.22,-11.23 4.16,-13.21s3.66,-8.62 3.23,-13.65z"
          />
        </g>
        <g class="vks">
          <path d="m271.61613,343.03741a85.5,85.5 0 0 1 -5,11c-0.87,1.26 -3.29,5.61 -2.12,17.13s3.41,17.24 5.18,20.55s7.23,11.42 8.32,13.16s4.26,6.29 0.1,12.77c-3.77,8.32 -9.39,20.23 -10.94,22.65s-7.16,13 -8.41,14.8s-5.52,8 -4.75,14.81c0.68,2.61 1.46,3.77 2,1.26s5.16,-15.87 10.91,-16.76a15.89,15.89 0 0 1 10.26,-2.62c0.78,0 4.84,-5.9 6,-8.22s2.81,-6.29 3.58,-7.07s0.68,-2.71 1,-4s1.84,-4.25 3.29,-5.22s12.39,-3.68 13.84,-4a33.32,33.32 0 0 1 8.81,0.68c1.35,0.48 7.74,3.68 10.16,3.58s4.06,-3.58 4.06,-3.87s-2.13,-1.82 -3,-2.76s-1.33,-2.71 0.55,-3.61a5.62,5.62 0 0 1 4.77,-0.2c0.74,0.52 3.26,0.55 3.1,-1.48a19.65,19.65 0 0 1 0.58,-7.77a21.79,21.79 0 0 1 3.19,-6.44c-1.49,-1.71 -2,-4.18 -2.29,-7.5c-0.53,-5.51 -0.15,-5.56 -7.35,-6.34s-6.39,-5 -6,-6.38s2.8,-6.82 4.79,-8.47s0.87,-7.69 0.09,-9.24s0.49,-2.47 1.36,-3.15a0.63,0.63 0 0 1 0.64,-0.06c-1,-3.11 -6.74,-7.5 -9.51,-9.57s-7,-10.38 -8.23,-12.58s-8.09,-7 -12.22,-9.42s-7,-0.64 -8.17,-0.45s-6.06,4 -6.45,3.78a86.89,86.89 0 0 0 -7.25,5.13c-1.89,1.62 -4.6,4.52 -4.89,5.88z"
          />
        </g>
        <g class="vks">
          <path d="m296.64124,260.30703s0.09,0.18 0.14,0l-0.14,0z"/>
          <path d="m306.94124,261.39703c2.7,1.72 7.84,3.65 8.7,4.62s6.4,4.45 9.79,7.38s13,9.38 15.19,15s0.39,17.61 0.48,20.61s0,7.84 2.71,11.9c0.78,4.75 2.33,8.42 4.26,12.78s2.71,16.06 2.71,17.22s-0.68,9.78 -10.26,9.1a88.54,88.54 0 0 0 -11.45,-0.31c0.85,0.3 2,1.69 3.9,1.62c2.37,-0.1 5.37,1.79 8.08,3.34s6.73,1 7.79,-0.25s6.44,-4.21 10.5,-5.08s9.73,-5.85 11.47,-6.67s2.86,-4.5 6.39,-5.47c2.71,0.77 3.58,1.45 3.53,0.48s3,-5 5.76,-6.63s4.89,-4 4.89,-4.93s0.09,-1.55 -3.58,-8.86c-5,-6.29 -3.68,-20.18 -2.28,-23.18s8.32,-10.54 10.36,-10.83s3.77,-4.26 3.09,-5.18s-3,-6.92 -2,-9.44s2.81,-7.59 2.76,-10.93s-1.45,-5.28 0.82,-7.5s2.23,-3.44 0.92,-4.41s-4,-1.06 -3.82,-1.3a29.3,29.3 0 0 0 1,-2.86c1,-2.85 -7.45,-11.22 -7.31,-12.29s4.16,-2.22 6.63,-3.77s0.14,-8.13 0.58,-9.34a18.19,18.19 0 0 0 0.77,-7.21c-0.38,-2.47 -2.71,-4.79 -1.83,-6.14s1.4,-5.18 1.74,-7.21s-1.07,-10.12 -3,-9.78s-6,2.47 -9,0.29s-8.59,-6.36 -12.26,-4.57s-8.75,2.86 -9.67,2.37s-0.92,-5.9 -3.54,-3.58s-6.87,0.39 -2.32,-4.59c3.49,-8.57 -2.47,-2.62 -4,-1.07s-4.18,1.07 -4.8,-0.14s0.24,-4.36 -1.75,-4.84c-1.57,-0.35 -3.43,-0.58 -3.93,-1.35l0,0c-1.94,1.07 -3,0.19 -5.07,1.06s-3.19,5.37 -5.51,6.05c1.55,2.08 0,21.53 -0.87,22.5s-3.87,7.11 -4.87,8.27s-3.19,6 -3.24,8.13a9.51,9.51 0 0 1 -1.76,5.47c-1,1.06 -4.7,1.45 -7.75,2.27s-5.03,5.47 -5.25,9.25s-2.71,7.89 -3.63,9.29s-3.55,2.71 -4.37,2.95a25.25,25.25 0 0 1 -5.34,0.54a10.24,10.24 0 0 0 -0.66,4.22a8.21,8.21 0 0 1 0.1,1.92c1.03,-0.1 7.7,-0.55 10.2,1.08z"
          />
        </g>
        <g class="vks">
          <path d="m307.51909,250.23773c0.92,-1.4 3.38,-5.47 3.63,-9.29s2.22,-8.47 5.27,-9.29s6.78,-1.21 7.74,-2.27a9.36,9.36 0 0 0 1.74,-5.47c0,-2.08 2.23,-7 3.25,-8.13s4,-7.31 4.88,-8.27s2.42,-20.42 0.87,-22.5c2.33,-0.68 3.49,-5.18 5.52,-6s3.12,0 5.07,-1.06l0,0a0.92,0.92 0 0 1 0,-0.88c0.63,-1.55 0.49,-7.3 -0.34,-9.14s-9.14,-7.94 -16,-11.18s-7.55,-3.15 -8.61,-3.82s-0.88,-5.23 -3.44,-5c-3.63,2 -5.47,-0.38 -4.74,-3.19s-0.34,-4.65 -1.65,-4.21s0,0 -4.26,2.32s-6.82,-2 -6.48,-4.4s-1.69,-8.81 -7,-11.08a37.59,37.59 0 0 1 -8.39,-5.14a52.47,52.47 0 0 1 -6.18,0.09c-13.27,-0.52 -15.35,8.25 -16.9,10.71s-2.43,6.11 -5.81,14c1.9,0.05 3.43,1.21 4.7,3.18c1.5,2.32 0.72,4.88 0.48,6.82s1.16,2.81 2.08,1.69s3.68,-0.29 4.12,0.68s0.14,1.89 -1.21,2.23s-6.29,6.62 -6,9.43s-0.29,11.17 -0.73,17.35s1.76,13.24 3.63,15.82c0.48,2.22 0.1,-1.26 0,5.9s-3.63,15.44 -8.32,19.32s-7.07,4.45 -9.1,4.83s-9.29,1.07 -12.58,2.71a8.58,8.58 0 0 1 -1.63,0.58a4.41,4.41 0 0 0 1.6,3.71c1.55,3 7.35,1.81 8.77,1.17s6.84,-2.58 8.9,-2.46s5.68,-0.19 6,0c0.39,2.17 5.47,5.32 7.65,5.56s7.45,-1.5 9.34,-4.84c3.53,-2.51 8.61,-15.1 8.61,-15.1s8.71,-2 10.84,-2.32s4.89,0 5.9,2.52s1.31,5.37 0.39,6.77s-4.1,2.48 -5.6,2.65s-7.54,2.33 -7.74,2.86a23.28,23.28 0 0 0 -1.4,3.19c0,0.53 0.72,1.36 2.61,1.16s5.61,0.34 6,2.47c-0.92,1.4 -4,4.45 -4,4.84s2.8,0.29 3.19,0.44a19.8,19.8 0 0 1 3,1.88a7.52,7.52 0 0 0 2.22,1.6c0.88,0.29 4.84,-0.24 5.71,-0.48s3.48,-1.53 4.4,-2.96z"
          />
        </g>
      </g>

    </svg>
  </div>
</template>

<script>
export default {
  data(){

  },
  methods:{
    select(i){
      console.log('当前村',i)
    }
  }
}
</script>

<style lang="less" scoped>
.vks {
  fill: url(#linear);
  cursor: pointer;
  path {
    stroke-width: 4px;
    stroke: #FFFFFF;
  }
}

.vks:hover {
  fill: url(#linear-hover);
  box-shadow: 0px 2px 32px rgba(14, 48, 199, 1);
  path {
    stroke: #0E30C7;
  }
}
</style>