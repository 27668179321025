<template>
  <div class="app">
    <router-view></router-view>
    <lock-screen></lock-screen>
  </div>
</template>

<script>
import LockScreen from '@/components/lockScreen/index'
export default {
  components:{ LockScreen },
  data(){
    return {

    }
  },
  mounted() {
    this.setTenantInfo()
  },
  methods:{
    setTenantInfo(){
      let host = "yt.scssyn.com"
      this.$axios.post('/system/tenant/data/getInfoByHost',{domainName:host}).then(res=>{
        if (res.code === 200 && res.data.state === 1) {
          let tenantInfo = res.data
          this.$store.commit('setData',{ prop: 'vuex_tenantId', data: tenantInfo.id })
          this.$store.commit('setData',{ prop: 'vuex_tenantInfo', data: tenantInfo })
          this.setTitle()
          this.changeFavicon()
        }
      })
    },
    setTitle(){
       document.title = this.$store.getters.getData('vuex_tenantInfo').sysName
    },
    changeFavicon(){
      let favicon = document.querySelector('link[rel="icon"]')
      if (favicon !== null) {
        if (this.$store.getters.getData('vuex_tenantInfo').logo) {
          favicon.href = this.$store.state.vuex_previewUrl + this.$store.getters.getData('vuex_tenantInfo').logo
        } else {
          favicon.href = './favicon.png'
        }
      } else {
        favicon = document.createElement('link')
        favicon.rel = 'icon'
        if (this.$store.getters.getData('vuex_tenantInfo').logo) {
          favicon.href = this.$store.state.vuex_previewUrl + this.$store.getters.getData('vuex_tenantInfo').logo
        } else {
          favicon.href = './favicon.png'
        }
        document.head.appendChild(favicon)
      }
    },

  }
}
</script>
<style lang="less">
  @import "assets/css/common.css";
  @import "assets/css/jiaobiao.css";

  .scrollbar {
    margin: 0 auto;
  }
  .app::-webkit-scrollbar {
    /*滚动条整体样式*/
    width : 0px;  /*高宽分别对应横竖滚动条的尺寸*/
  }
  .app::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    background   : #aaa;
  }
  .app::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 8px;
    background   : #FFFFFF;
  }
</style>
