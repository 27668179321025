<template>
  <div>
    <el-upload v-model:file-list="fileList" accept='image/png,image/jpeg,image/jpg,image/gif' class="img-upload" multiple
               :on-preview="handlePreview" :on-remove="handleRemove"
               list-type="picture-card" :before-upload="beforeUpload">
      <el-icon class="avatar-uploader-icon"><Plus /></el-icon>
    </el-upload>
    <el-dialog v-model="imgVisible" :append-to-body="true">
      <img w-full :src="dialogImageUrl" style="width: 100%" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<script>
import ObsClient from "esdk-obs-browserjs/src/obs";
import { ElMessage, ElLoading  } from 'element-plus'
let moment = require('moment');
import { randomNumStr } from "@/utils/random";
export default {
  props: {
    modelValue: String,
  },
  data() {
    return {
      fileList: [],
      obsObj:{
        access_key_id: "RL0OFCJZRMUEEUROCDFU",
        secret_access_key: "7JFnexa1AyeLXxAfhNxh3ARmvYELb6tBdy7n4MYb",
        server: "https://obs.cn-south-1.myhuaweicloud.com",
        Bucket: 'ssyn',
        BucketFilePrefix: 'server/' + this.$store.getters.getData('vuex_tenantId') + '/'
      },
      obsClient: null,
      imgVisible: false,
      dialogImageUrl: '',

    };
  },
  mounted(){
    this.obsClient = new ObsClient({
      access_key_id: this.obsObj.access_key_id,
      secret_access_key: this.obsObj.secret_access_key,
      server: this.obsObj.server
    });
  },
  watch:{
    modelValue:{
      immediate: true,
      handler(newVal,oldVal){
        if (newVal) {
          this.fileList = newVal.split(',').map(item=>{
            let obj = {
              url: this.$store.state.vuex_previewUrl + this.$store.getters.getData('vuex_tenantId') + '/' + item,
              fileId: item,
            }
            return obj
          })
        } else {
          this.fileList = []
        }
      }
    }
  },
  methods: {
    handlePreview(e){
      this.dialogImageUrl  = this.$store.state.vuex_previewUrl + this.$store.getters.getData('vuex_tenantId') + '/' + e.fileId
      this.imgVisible = true
    },
    handleRemove(e) {
      this.obsClient.deleteObject({
        Bucket: this.obsObj.Bucket,
        Key: 'server/'+ this.$store.getters.getData('vuex_tenantId') + '/' + e.fileId
      }, function (err, result) {});
    },
    beforeUpload(file) {
      let that = this;
      let fileExtension = "";
      // 校检文件类型
      if (file.name.lastIndexOf(".") > -1) {
        fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
      }
      // 校检文件大小
      if (file.size / 1024 / 1024  > 20) {
        ElMessage({
          message: '上传图片大小不能超过20MB!',
          type: 'warning',
        })
        return false;
      }
      let loading = ElLoading.service({
        lock: true,
        text: "上传中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let fileId = moment().format('YYYYMMDDHHmmss') + randomNumStr(5);
      this.obsClient.putObject(
          {
            Bucket: that.obsObj.Bucket,
            Key: that.obsObj.BucketFilePrefix + fileId, // 文件名   server是目录
            SourceFile: file, //文件本体
          },
          function (err, result) {
            loading.close()
            that.fileList.push({
              url: that.$store.state.vuex_previewUrl + that.$store.getters.getData('vuex_tenantId') + '/' + fileId,
              size: file.size,
              fileId: fileId,
            })
            let idStr = that.fileList.map(item=>{
              return item.fileId
            }).join(',')
            that.$emit("update:modelValue", idStr);
          }
      );
      return false;
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-icon.avatar-uploader-icon) {
  font-size: 28px;
  color: #8c939d;
  width: 160px;
  height: 160px;
  text-align: center;
}
</style>
