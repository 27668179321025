<template>
  <div style="width: 100%">
    <div style="width: 100%;" id="editor" ref="myEditor"></div>
    <slot></slot>
  </div>
</template>
<script>
import WangEditor from 'wangeditor'
export default {
  name: 'ComponentWangeditor',
  data () {
    return {
      edit: '',
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    uploadConfig: {
      type: Object,
      default: () => {
        return {
          method: 'base64', // 支持custom(objurl)和http(服务器)和base64
          url: ""
        }
      }
    }
  },
  computed: {
    customConfig () {
      return {
        pasteFilterStyle: false, // 关闭掉粘贴样式的过滤
        pasteIgnoreImg: false, // 粘贴时不忽略图片
        ...this.config
      }
    }
  },
  watch:{

  },
  components: {

  },
  methods: {
    readBlobAsDataURL (blob, callback) {
      var a = new FileReader()
      a.onload = function (e) { callback(e.target.result) }
      a.readAsDataURL(blob)
    },
    initEditor (value) {
      var self = this
      this.editor = new WangEditor(this.$refs.myEditor)
      // 配置 onchange 事件
      this.editor.customConfig = this.customConfig
      this.editor.customConfig.uploadImgMaxLength = 20
      this.editor.customConfig.emotions = [
        {
          content: [
            {alt: '[坏笑]', src: 'http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/50/pcmoren_huaixiao_org.png'},
            {alt: '[舔屏]', src: 'http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/40/pcmoren_tian_org.png'},
            {alt: '[污]', src: 'http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3c/pcmoren_wu_org.png'}
          ],
          title: "默认",
          type: "image"
        }
      ]
      this.editor.change = function () { // 编辑区域内容变化时
        self.$emit('input', this.txt.html())
        self.$emit('onchange', this.txt.html(), this.txt)
        // editor.txt.html('.....') //设置编辑器内容
        // editor.txt.clear() //清空编辑器内容
        // editor.txt.append('<p>追加的内容</p>')//继续追加内容。
        // editor.txt.text()  // 读取 text
        // editor.txt.getJSON()  // 获取 JSON 格式的内容
      }
      this.editor.customConfig.customUploadImg = function (files, insert) {
        if (self.uploadConfig.method === 'custom') {
          files.forEach(file => {
            var fileUrl = URL.createObjectURL(file)
            insert(fileUrl)
          })
        }
        if (self.uploadConfig.method === 'base64') {
          files.forEach(file => {
            self.readBlobAsDataURL(file, function (dataurl) {
              insert(dataurl)
            })
          })
        }
        if (self.uploadConfig.method === 'http') {
          if (self.uploadConfig.callback) {
            self.uploadConfig.callback(files, insert)
          } else {
            var formData = new FormData()
            files.forEach(file => {
              formData.append('file', file)
            })
            self.axios.post(self.uploadConfig.url, formData).then(({ data }) => {
              insert(process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + data)
            })
          }
        }
      }
      this.editor.create() // 生成编辑器
      this.state = true
      this.editor.txt.text(value) // 生成编辑器
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    this.initEditor()
  },
}
</script>

<style >
.w-e-toolbar{
  flex-wrap:wrap;
}

</style>
