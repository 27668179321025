<template>
  <div class="lock-screen" v-if="clockState">
    <img src="https://yanting.obs.cn-north-4.myhuaweicloud.com/pagebackimg.png" class="lock-screen-png">
    <div class="p-content">
      <div class="flex flex-direction align-center">
        <div class="date">{{date}}</div>
        <clock ></clock>
        <div class="p-box flex align-center">
          <input class="text-input" @keyup.enter="confirm" type="password" placeholder="请输入解锁密码" v-model="password" />
          <el-button  circle  icon="Right" @click="confirm"></el-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {ElMessage} from 'element-plus'
import Clock from '@/components/clock/index'
import moment from 'moment'
import bus from '@/store/eventbus'
export default {
  components: { Clock },
  data(){
    return {
      password: '',
      clockState: false,
    }
  },
  computed:{
    date(){
      return moment().format('yyyy-MM-DD dddd')
    }
  },
  created(){
    if (localStorage.getItem('lockScreenState') === '1') {
      this.clockState  = true
    }
    bus.on('lockscreen',(res)=>{
      this.password = ''
      localStorage.setItem('lockScreenState','1')
      localStorage.setItem('lockScreenPassword',res)
      this.clockState  = true
    })
  },
  methods: {
    confirm(){
      if ( !this.password) {
        ElMessage({
          message: '请输入解锁密码！',
          type: 'info',
          duration: 1000
        })
        return
      }
      if (this.password != localStorage.getItem('lockScreenPassword')) {
        ElMessage({
          message: '解锁密码错误，请重新输入！',
          type: 'error',
          duration: 1000
        })
        return
      }
      localStorage.setItem('lockScreenState','0')
      this.clockState = false
    }
  }
}
</script>

<style lang="less" scoped>
  .p-box {
    width: 360px;
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 40px;
    margin-top: 100px;
  }
  .text-input {
    background:none;
    outline:none;
    border:none;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 600;
    font-family: 'Alibaba-PuHuiTi-Bold';
    width: 290px;
    padding-left: 30px;
    &:focus {
      border: none;
    }
    &::placeholder {
      color: #999999;
      letter-spacing: 0;
      font-weight: 400;
      font-size: 14px;
      font-family: 'Alibaba-PuHuiTi-Regular';
    }
  }
  .date {
    font-size: 40px;
    font-family: 'Alibaba-PuHuiTi-Bold';
    margin-top: 100px;
    margin-bottom: 40px;
  }
  .lock-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100 !important;
    left: 0;
    top: 0;
  }
  .p-content {
    width: 100vw;
    height: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .lock-screen-png {
    opacity: 1;
    width: 100vw;
    height: 100vh;
  }
</style>
