<template>
  <el-upload  accept='image/png,image/jpeg,image/jpg,image/gif' class="img-upload"
             :show-file-list="false" :before-upload="beforeUpload">
    <img v-if="modelValue" :src="url" class="avatar" />
    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
  </el-upload>
</template>

<script>
import ObsClient from "esdk-obs-browserjs/src/obs";
import { ElMessage, ElLoading  } from 'element-plus'
let moment = require('moment');
import { randomNumStr } from "@/utils/random";
export default {
  props: {
    modelValue: String,
  },
  data() {
    return {
      url:'',
      obsObj:{
        access_key_id: "RL0OFCJZRMUEEUROCDFU",
        secret_access_key: "7JFnexa1AyeLXxAfhNxh3ARmvYELb6tBdy7n4MYb",
        server: "https://obs.cn-south-1.myhuaweicloud.com",
        Bucket: 'ssyn',
        BucketFilePrefix: 'server/' + this.$store.getters.getData('vuex_tenantId') + '/'
      },
    };
  },
  mounted(){

  },
  watch:{
    modelValue:{
      immediate: true,
      handler(newVal,oldVal){
        this.url = this.$store.state.vuex_previewUrl + this.$store.getters.getData('vuex_tenantId') + '/' + newVal
      }
    }
  },
  methods: {
    handleRemove(key) {
      obsClient.deleteObject({
        Bucket: this.obsObj.Bucket,
        Key: key
      }, function (err, result) {

      });
    },
    beforeUpload(file) {
      let that = this;
      let fileExtension = "";
      // 校检文件类型
      if (file.name.lastIndexOf(".") > -1) {
        fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
      }
      // 校检文件大小
      if (file.size / 1024 / 1024  > 20) {
        ElMessage({
          message: '上传图片大小不能超过20MB!',
          type: 'warning',
        })
        return false;
      }
      let loading = ElLoading.service({
        lock: true,
        text: "上传中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 创建ObsClient实例
      let obsClient = new ObsClient({
        access_key_id: this.obsObj.access_key_id,
        secret_access_key: this.obsObj.secret_access_key,
        server: this.obsObj.server
      });
      let fileId = moment().format('YYYYMMDDHHmmss') + randomNumStr(5);
      obsClient.putObject(
          {
            Bucket: that.obsObj.Bucket,
            Key: that.obsObj.BucketFilePrefix + fileId, // 文件名   server是目录
            SourceFile: file, //文件本体
          },
          function (err, result) {
            loading.close()
            that.url =  that.$store.state.vuex_previewUrl + that.$store.getters.getData('vuex_tenantId') + '/' + fileId
            that.$emit("update:modelValue", fileId);
          }
      );
      return false;
    }
  }
}
</script>

<style lang="less" scoped>
.avatar {
  width: 160px;
  height: 160px;
}
:deep(.img-upload .el-upload)  {
  border: 1px dashed #C4C4C4;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

:deep(.img-upload .el-upload:hover) {
  border-color: var(--el-color-primary);
}

:deep(.el-icon.avatar-uploader-icon) {
  font-size: 28px;
  color: #8c939d;
  width: 160px;
  height: 160px;
  text-align: center;
}
</style>
