<template>
  <el-checkbox-group v-model="checkedArr">
    <el-checkbox v-for="(item,index) in optionList" :label="item[props.label]" :key="index"  />
  </el-checkbox-group>
</template>

<script>
export default {
  props:{
    modelValue: String,
    optionList: {
      type: Array,
      default: []
    },
    props: {
      default: {
        label: 'label',
        value: 'code'
      }
    },
  },
  data() {
    return {
      checkedArr: [],
    }
  },
  watch:{
    modelValue:{
      immediate: true,
      handler(newVal,oldVal){
        if (newVal) {
          let arr = newVal.split(',')
          this.checkedArr = []
          arr.forEach(item=>{
            this.optionList.forEach(k=>{
              if (item == k[this.props.value]) {
                this.checkedArr.push(k[this.props.label])
              }
            })
          })
        } else {
          this.checkedArr = []
        }
        console.log(newVal,'==+++=',this.checkedArr)
      }
    },
    checkedArr(){
      let arr = []
      this.checkedArr.forEach(item=>{
        this.optionList.forEach(k=>{
          if (item == k[this.props.label]) {
            arr.push(k[this.props.value])
          }
        })
      })
      let str = arr.join(',')
      this.$emit("update:modelValue", str);
    }
  },
}
</script>

<style scoped>

</style>
