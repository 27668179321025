<template>
  <div class="table-select">
    <div @click="showSTable" class="value-box">
      <div v-if="single && selectedList.length > 0" style="color: #606266">{{selectedList[0][props.label]}}</div>
      <div v-else>
        <el-tag closable v-for="(item,index) in selectedList" size="default" class="mr-10" @close="handleClose(index)">{{item[props.label]}}</el-tag>
      </div>
      <span style="color: #a8abb2" v-if="selectedList.length === 0">请选择</span>
    </div>
    <el-dialog v-model="visible" :title="title" :width="width">
      <base-search :searchConfig="searchConfig" :formData="searchData" @search="search" @clear="clear"></base-search>

      <el-table ref="select_table" :data="tableList" @row-click="cellClick" :highlight-current-row="true">
        <el-table-column type="selection" width="55" v-if="!single" />
        <el-table-column width="80" label="单选" v-if="single">
          <template #default="scope,index" >
            <el-checkbox v-model="scope.row.checked" @change="e=>checkboxChange(e,scope.$index)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column v-for="(item,index) in columns" :prop="item.prop" :label="item.label">
          <template #default="scope" >
            <span v-if="item.dict">{{$filter.getDictLabel(item.dict,scope.row[item.prop])}}</span>
            <span v-else-if="item.api">{{getLabel(scope.row[item.prop],item)}}</span>
            <span v-else>{{scope.row[item.prop]}}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="page" size="small"
                     :currentPage="currentPage"
                     :page-size="pageSize"
                     :page-sizes="[5,10,20,30,40,50,100]"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange">
      </el-pagination>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visible = false">取消</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type: String
    },
    width:{
      type: String
    },
    api:{
      type: String
    },
    listApi:{
      type: String
    },
    columns:{
      default: [],
      type: Array
    },
    searchConfig:{
      default: [],
      type: Array,
    },
    props:{
      default: ()=>{
        return {
          label:'name',
          value:'id'
        }
      },
      type: Object
    },
    single:{
      default: false,
      type: Boolean
    },
  },
  data(){
    return {
      valData: '',
      visible: false,
      tableList: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
      searchData: {},
      selectedList: [],
      list: []
    }
  },
  mounted() {
    this.setData()
    this.getTableData()
  },
  methods:{
    cellClick(row, column, event){
      this.$refs.select_table.toggleRowSelection(row,true)
      this.tableList.forEach(item=>{
        if(item.id === row.id) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    checkboxChange(e,index){
      this.tableList.forEach(item=>{
        item.checked = false
      })
      this.tableList[index].checked = true
    },
    async getList(){
      await this.$axios.post(this.listApi,{}).then(res=>{
        this.list = res.data
      })
    },
    async setDefaultData(val){
      await this.getList();
      this.valData = val
      this.selectedList = []
      if(this.valData) {
        let idArr = this.valData.split(',')
        idArr.forEach(el=>{
          let arr = this.list.filter(obj => obj[this.props.value] === el)
          if(arr.length > 0) {
            this.selectedList.push(arr[0])
          }
        })
      } else {
        this.selectedList = []
      }
    },
    showSTable(){
      this.visible = true
      if(this.single) {
        this.tableList.forEach(item=>{
          if(item[this.props.value] == this.valData) {
            item.checked = true
          } else {
            item.checked = false
          }
        })
      } else {
        this.$nextTick(()=>{
          let arr = this.valData ? this.valData.split(',') : []
          this.tableList.forEach(item=>{
            if (arr.indexOf(item[this.props.value]) !== -1){
              this.$refs.select_table.toggleRowSelection(item,true)
            } else {
              this.$refs.select_table.toggleRowSelection(item,false)
            }
          })
        })
      }
    },
    handleClose(i) {
      this.selectedList.splice(i,1)
      this.valData = this.selectedList.map(item=>{
        return item[this.props.value]
      }).join(',')
      this.$emit('change',this.valData)
    },
    getTableData(){
      let param = {
        pageSize: this.pageSize,
        pageCurrent: this.currentPage
      }
      if (this.searchData) {
        Object.assign(param,this.searchData)
      }
      this.$axios.post(this.api,param).then(res=>{
        if (res.code === 200) {
          this.tableList = res.data.records
          this.total = parseInt(res.data.total)
        }
      })
    },
    setData(){
      this.columns.forEach(item=>{
        if (item.api) {
          this.$axios.post(item.api).then(res=>{
            if (item.pObj) {
              item.selectData = res.data.unshift(item.pObj)
            } else {
              item.selectData = res.data
            }
          })
        }
      })

    },
    getLabel(value,item){
        let arr = item.selectData.filter(obj => obj[item.props.value] === value)
        if(arr.length > 0) {
          return  arr[0][item.props.label]
        } else {
          return '未匹配'
        }
    },
    confirm(){
      if(this.single) {
        let arr = this.tableList.filter(item=>item.checked)
        this.valData = arr[0][this.props.value]
        this.selectedList = arr
      } else {
        this.selectedList = Array.from(new Set(this.selectedList.concat(this.$refs.select_table.getSelectionRows())))
        this.valData = this.selectedList.map(item=>{
          return item[this.props.value]
        }).join(',')
      }
      this.visible = false
      this.$emit('change',this.valData)
    },
    async handleSizeChange(e){
      this.pageSize = e
      await this.getTableData()
      this.showSTable()
    },
    async handleCurrentChange(e){
      this.currentPage = e
      await this.getTableData()
      this.showSTable()
    },
    search(data){
      this.searchData = Object.assign({},data)
      this.currentPage = 1
      this.getTableData()
    },
    clear(){
      this.currentPage = 1
      this.searchData = {}
      this.getTableData()
    }
  }
}
</script>

<style lang="less" scoped>
  .page {
    margin-top: 10px;
    display: flex;
    justify-content: right;
  }
  .table-select {
    width: 100%;
  }
  .value-box {
    width: 100%;
    min-height: 40px;
    line-height: 40px;
    border: 1px solid #dcdfe6;
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    border-radius: 4px;
    padding-left: 13px;
    cursor: pointer;
    &:hover {
      border: 1px solid #c0c4cc;
    }
    &:focus {
      border: 1px solid #409eff;
    }
  }
</style>