<template>
  <div class="town-map flex justify-center">
    <ex-town v-if="this.$store.getters.getData('vuex_townInfo').name === '鹅溪镇'" class="ex-town"></ex-town>
    <jl-town v-if="this.$store.getters.getData('vuex_townInfo').name === '巨龙镇'" class="jl-town"></jl-town>
    <fy-town v-if="this.$store.getters.getData('vuex_townInfo').name === '富驿镇'" class="fy-town"></fy-town>
    <gq-town v-if="this.$store.getters.getData('vuex_townInfo').name === '高渠镇'" class="gq-town"></gq-town>
    <jc-town v-if="this.$store.getters.getData('vuex_townInfo').name === '金孔镇'" class="jc-town"></jc-town>
  </div>
</template>

<script>
import ExTown from "./ex";
import JcTown from "./jc";
import GqTown from "./gq";
import JlTown from "./jl";
import FyTown from "./fuyiz";
export default {
  name: "index",
  components:{
    ExTown,JcTown,GqTown,JlTown,FyTown
  },
}
</script>

<style scoped>
.town-map {
  width: 600px;
  height: 522px;
  font-size: 100px;
  background-image: url("~@/assets/img/map-bottom.png");
  background-size: 600px,522px;
  position: relative;
}
.ex-town {
  transform: scale(0.7);
  position: relative;
  top: -100px;
}
.jc-town {
  transform: scale(0.3);
  position: relative;
  top: -190px;
}
.gq-town {
  transform: scale(0.43);
  position: relative;
  top: -140px;
}
.jl-town {
  transform: scale(0.64);
  position: relative;
  top: -130px;
}
.fy-town {
  transform: scale(0.8);
  position: relative;
  top: -30px;
}
</style>
